import axiosInstance from '../apiService';
import constants from '../../constants/constants';

const ENDPOINTORIGIN = 'Bid/';

const getAll = async () => axiosInstance.get(`${ENDPOINTORIGIN}GetAll`, constants.REQUIRE_INTERCEPTORS);

const getById = async (id) => axiosInstance.get(`${ENDPOINTORIGIN}GetById?id=${id}`, constants.REQUIRE_INTERCEPTORS);

const getAllByClientId = async (clientId) => axiosInstance.get(`${ENDPOINTORIGIN}GetAllByClientId?clientId=${clientId}`, constants.REQUIRE_INTERCEPTORS);

const create = async (model) => axiosInstance.post(`${ENDPOINTORIGIN}Create`, model, constants.REQUIRE_INTERCEPTORS);

const update = async (model) => axiosInstance.put(`${ENDPOINTORIGIN}Update`, model, constants.REQUIRE_INTERCEPTORS);

const remove = async (id) => axiosInstance.delete(`${ENDPOINTORIGIN}Delete?id=${id}`, constants.REQUIRE_INTERCEPTORS);

const getBidItems = async (id) => axiosInstance.get(`${ENDPOINTORIGIN}GetBidItems?id=${id}`, constants.REQUIRE_INTERCEPTORS);

const addBidItems = async (model) => axiosInstance.post(`${ENDPOINTORIGIN}AddBidItems`, model, constants.REQUIRE_INTERCEPTORS);

export {
  getAll,
  getById,
  getAllByClientId,
  create,
  update,
  remove,
  getBidItems,
  addBidItems
};