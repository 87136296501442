/* eslint-disable consistent-return */
// DEPENDENCIES
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
// ICONS
import {
  TbArrowBarBoth,
  TbSquareRoundedMinusFilled,
  TbSquareRoundedPlusFilled,
  TbBrandBootstrap
} from 'react-icons/tb';
// COMPONENTS
import {
  Container, Row, Col
} from 'react-bootstrap';
import CustomBlock from '../CustomBlock';
// SERVICES & HELPERS
import themeHelper from '../../helpers/themeHelper';

const TopHeaderDevReference = () => {
  // const { } = props;
  const collapseToggleContentOnScroll = true;
  const [isDevHeaderToggled, setIsDevHeaderToggled] = useState(false);
  // DATA
  const [breakpoint, setBreakpoint] = useState(null);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [themeColors, setThemeColors] = useState([]);

  useEffect(() => {
    const getCurrentBreakpoint = () => {
      const width = window.innerWidth;
      setScreenWidth(width);
      if (width < 576) return 'xs';
      if (width >= 576 && width < 768) return 'sm';
      if (width >= 768 && width < 992) return 'md';
      if (width >= 992 && width < 1200) return 'lg';
      if (width >= 1200 && width < 1400) return 'xl';
      return 'xxl';
    };
      
    const handleResize = () => setBreakpoint(getCurrentBreakpoint());
    setBreakpoint(getCurrentBreakpoint());
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (!themeColors.length > 0) {
      const themeColorsData = themeHelper.getThemeColors();
      setThemeColors(themeColorsData);
    }
  }, []);

  useEffect(() => {
    if (collapseToggleContentOnScroll) {
      const handleScroll = () => {
        if (isDevHeaderToggled) {
          setIsDevHeaderToggled(false);
        }
      };
  
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, [isDevHeaderToggled]);

  return (
    <Container
      fluid
      className="p-1"
      style={{
        backgroundImage: 'linear-gradient(45deg, #343a40 25%, #495057 25%, #495057 50%, #343a40 50%, #343a40 75%, #495057 75%, #495057 100%)',
        backgroundSize: '40px 40px',
      }}
    >
      {/* MAIN */}
      <Row className="align-items-center">
        <Col>
          <CustomBlock className="d-flex align-items-center gap-2 cursor--pointer" onClick={() => setIsDevHeaderToggled(!isDevHeaderToggled)}>
            <p
              className="d-flex align-items-center fit-content px-2 size-xxs gap-1 fw-600 rounder white--clr user-select-none lh-normal"
              style={{ border: '2px solid white', paddingTop: '2px', paddingBottom: '1px' }}
            >
              DEV ENV
            </p>
            <p className="d-flex align-items-center fit-content px-2 size-xxs gap-1 fw-700 rounder dark--clr light--sbg user-select-none">
              <TbArrowBarBoth className="size-md" />
              {screenWidth}
              {' '}
              px
            </p>
            <p className="d-flex align-items-center fit-content px-2 size-xxs gap-1 fw-700 rounder dark--clr light--sbg user-select-none">
              <TbBrandBootstrap className="size-md" />
              {breakpoint}
            </p>

            <CustomBlock className="ml-auto">
              {isDevHeaderToggled ? (
                <TbSquareRoundedPlusFilled className="size-mdlg white--clr" />
              ) : (
                <TbSquareRoundedMinusFilled className="size-mdlg white--clr" />
              )}
            </CustomBlock>
          </CustomBlock>
        </Col>
      </Row>

      {/* TOGGLED CONTENT */}
      {isDevHeaderToggled && (
        <CustomBlock className="d-flex flex-column gap-3 my-10">
          {/* THEME COLORS */}
          <Row>
            <p className="size-sm white--clr fw-600 user-select-none">Theme Colors</p>
            {themeColors && (
            <Col className="d-flex align-items-center flex-wrap gap-2">
              {themeColors.map((color) => (
                <CustomBlock
                  key={color.name} className="d-flex flex-column align-items-center"
                  style={{ minWidth: '80px' }}
                >
                  <span
                    style={{
                      height: '30px',
                      backgroundColor: color.value,
                      alignSelf: 'stretch',
                      borderRadius: '3px 3px 0 0'
                    }}
                  />
                  <p
                    className="size-tiny white--sbg px-1 fw-600 text-center"
                    style={{ alignSelf: 'stretch', borderRadius: '0 0 2px 2px' }}
                  >
                    {color.name}
                  </p>
                </CustomBlock>
              ))}
            </Col>
            )}
          </Row>

          {/* REFERENCE 2 */}
          <Row>
            <p className="size-sm white--clr fw-600 user-select-none">Dev Reference 2</p>
            {true && (
            <Col className="d-flex align-items-center flex-wrap gap-2">
              <span className="white--clr">...</span>
            </Col>
            )}
          </Row>

          {/* REFERENCE 3 */}
          <Row>
            <p className="size-sm white--clr fw-600 user-select-none">Dev Reference 3</p>
            {true && (
            <Col className="d-flex align-items-center flex-wrap gap-2">
              <span className="white--clr">...</span>
            </Col>
            )}
          </Row>
        </CustomBlock>
      )}
    </Container>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(mapStateFromProps, null)(TopHeaderDevReference);