/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-use-before-define */
/* eslint-disable no-param-reassign */
// DEPENDENCIES
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';
// COMPONENTS
import MUIDataTable from 'mui-datatables';
import {
  DragDropContext,
  Droppable,
  Draggable
} from 'react-beautiful-dnd';
// ICONS
// CUSTOM COMPONENTS
import Section from '../../../../components/Section';
import ContentBlock from '../../../../components/ContentBlock';
import CustomBlock from '../../../../components/CustomBlock';
import FormBlock from '../../../../components/FormBlock';
import ContentHeader from '../../../../components/ContentHeader';
import InputBlock from '../../../../components/InputBlock';
import SelectBlock from '../../../../components/SelectBlock';
import CheckboxBlock from '../../../../components/CheckboxBlock';
import Button from '../../../../components/Button';
import Overlay from '../../../../components/Overlay';
import ModalBlock from '../../../../components/ModalBlock';
import FixedActionsBar from '../../../../components/FixedActionsBar';
// ASSETS
// SERVICES AND HELPERS
import * as helper from '../../../../helpers/helper';
import * as menuItemService from '../../../../services/cms/menuItemService';
import * as pageService from '../../../../services/cms/pageService';
import MenuItemValidator from '../../../../helpers/validators/cms/menuItem/menuItemValidator';
// REDUX
import * as auth from '../../../../redux/authRedux';
import * as alert from '../../../../redux/alertToastRedux';
import * as confirmModalRedux from '../../../../redux/confirmModalRedux';

const intialMenuItemModel = {
  id: 0,
  name: '',
  pageId: null,
  parentMenuItemId: null,
  customLink: null,
  openInNewTab: false,
  isActive: true,
  assignedMenuItems: []
};

const MenuItemManagementPage = (props) => {
  const { showAlert, history, showConfirmModal, hideConfirmModal } = props;
  const { menuItemId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [menuItemModel, setMenuItemModel] = useState(intialMenuItemModel);
  const [selectedOptions, setSelectedOptions] = useState({
    page: null,
    parentMenuItem: null
  });
  const [pageOptions, setPageOptions] = useState();
  const [menuItemOptions, setMenuItemOptions] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [assignmentMenuItemOptions, setAssignmentMenuItemOptions] = useState([]);
  const [selectedAssignmentMenuItem, setSelectedAssignmentMenuItem] = useState(null);
  const [isSortModalOpen, setIsSortModalOpen] = useState(false);
  const [sortList, setSortList] = useState([]);

  // const iconSize = 22;
  // const iconColor = 'white--clr';

  const columnOptions = {
    filter: true,
    sort: true,
    print: false,
    download: false
  };

  const options = {
    selectableRows: 'none',
    download: false,
    print: false,
  };

  useEffect(() => {
    loadOptionsAndPopulateForm();
  }, [menuItemId]);

  useEffect(() => {
    setSortList(menuItemModel.assignedMenuItems.sort((a, b) => a - b));
  }, [menuItemModel]);

  const formik = useFormik({
    initialValues: menuItemModel,
    validationSchema: MenuItemValidator,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      if (menuItemId) {
        updateMenuItem(values);
      } else {
        createMenuItem(values);
      }
    },
  });

  const loadOptionsAndPopulateForm = async () => {
    const pages = await getPageOptions();
    const menuItems = await getMenuItemOptions();

    if (menuItemId > 0) {
      getMenuItem(pages, menuItems);
    }
  };

  const getMenuItem = async (pages, menuItems) => {
    setIsLoading(true);

    await getMenuItemForAssignments();
    menuItemService.getMenuItemsById(menuItemId).then((res) => {
      setMenuItemModel(res);
      let selectedOptionsFromApi = {
        page: null,
        parentMenuItem: null
      };
      if (res.pageId) {
        const selectedPage = pages.find((x) => x.value === res.pageId);
        if (selectedPage) {
          selectedOptionsFromApi = { ...selectedOptionsFromApi, page: selectedPage };
        }
      }

      if (res.parentMenuItemId) {
        const selectedMenuItem = menuItems.find((x) => x.value === res.parentMenuItemId);
        if (selectedMenuItem) {
          selectedOptionsFromApi = { ...selectedOptionsFromApi, parentMenuItem: selectedMenuItem };
        }
      }

      setSelectedOptions(selectedOptionsFromApi);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const getPageOptions = async () => pageService.getAllPages(true).then((res) => {
    const pageList = res.map((x) => helper.setToOptionModel(x.name, x.id));
    // console.log('Page Options are ', pageList);
    setPageOptions(pageList);

    return pageList;
  }).catch((ex) => {
    showAlert({ text: ex.message, state: 'error' });
  });

  const getMenuItemForAssignments = async () => menuItemService.getMenuItemsWithNoParentOrChild()
    .then((res) => {
      const assignmentlist = res.filter((x) => x.id !== Number(menuItemId))
        .map((x) => helper.setToOptionModel(x.name, x.id));
      setAssignmentMenuItemOptions(assignmentlist);

      return assignmentlist;
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    });

  const getMenuItemOptions = async () => menuItemService.getAllMenuItems(true).then((res) => {
    const menuItemList = res.filter((x) => x.parentMenuItem === '-' && x.id !== Number(menuItemId)).map((x) => helper.setToOptionModel(x.name, x.id));
    setMenuItemOptions(menuItemList);

    return menuItemList;
  }).catch((ex) => {
    showAlert({ text: ex.message, state: 'error' });
  });

  const createMenuItem = (model) => {
    setIsLoading(true);

    menuItemService.createMenuItem(model).then((res) => {
      showAlert({ text: res.message, state: 'success' });
      history.push('/cms/management/menu-items');
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'warning' });
    }).finally(() => setIsLoading(false));
  };

  const updateMenuItem = (model) => {
    setIsLoading(true);
    menuItemService.updateMenuItems(model).then((res) => {
      showAlert({ text: res.message, state: 'success' });
      history.push('/cms/management/menu-items');
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'warning' });
    }).finally(() => setIsLoading(false));
  };

  const removeAssignedMenuItem = (id) => {
    setIsLoading(true);

    menuItemService.removeParentMenuItem(id).then((res) => {
      showAlert({ text: res.message, state: 'success' });
      getMenuItem(pageOptions, menuItemOptions);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'warning' });
    }).finally(() => {
      setIsLoading(false);
      hideConfirmModal();
    });
  };

  const assignMenuItem = () => {
    setIsLoading(true);

    menuItemService.assignParentMenuItem({
      parentId: menuItemId,
      childMenuItemIds: selectedAssignmentMenuItem.map((x) => Number(x.value))
    }).then((res) => {
      showAlert({ text: res.message, state: 'success' });
      getMenuItem();
      setIsModalVisible(false);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'warning' });
    }).finally(() => {
      setIsLoading(false);
      setSelectedAssignmentMenuItem(null);
    });
  };

  const columns = [
    {
      name: 'id',
      label: 'Actions',
      options: {
        filter: false,
        sort: false,
        print: false,
        download: false,
        customBodyRenderLite: (dataIndex) => (
          <CustomBlock className="content-container--actions flex-start pl-0 mt-0">
            <Button
              text="View"
              className="primary--bg mr-5"
              size="xxs"
              onClick={() => {
                history.push(`/cms/management/menu-item/edit/${menuItemModel.assignedMenuItems[dataIndex].id}`);
              }}
            />

            <Button
              text="Remove"
              className="danger--bg"
              size="xxs"
              onClick={() => {
                showConfirmModal({
                  title: 'Remove Menu Item',
                  text: 'Are you sure you want to remove this assigned menu item?',
                  rightBtnText: 'Confirm',
                  btnAction: () => {
                    removeAssignedMenuItem(menuItemModel.assignedMenuItems[dataIndex].id);
                  }
                });
              }}
            />
          </CustomBlock>
        )
      }
    },
    {
      name: 'name',
      label: 'Menu Item Name',
      options: columnOptions,
    },
    {
      name: 'pageName',
      label: 'Assigned Page',
      options: columnOptions,
    },
    {
      name: 'isActive',
      label: 'Is Active?',
      options: {
        ...columnOptions,
        customBodyRenderLite: (dataIndex) => (
          <p>{menuItemModel.assignedMenuItems[dataIndex].isActive ? 'Yes' : 'No'}</p>
        )
      },
    },
  ];

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const tempArr = helper.reOrderDnd(
      sortList,
      result.source.index,
      result.destination.index
    );

    setSortList(tempArr);
  };

  const sortChildMenuItems = () => {
    setIsLoading(true);

    menuItemService.sortChildMenuItems(sortList).then((res) => {
      getMenuItem(pageOptions, menuItemOptions);
      showAlert({ text: res.message, state: 'success' });
      setIsSortModalOpen(false);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  return (
    <>
      {isLoading && <Overlay hasLoader />}
      <CustomBlock className="content-container--padded">
        <Section isFullWidth className="mb-140">
          <ContentBlock>
            <FormBlock className="content-container--card-style--with-shadow" onSubmit={formik.submitForm}>
              {/* BASIC INFORMATION */}
              <Section hasNoContainer>
                <ContentBlock>
                  <ContentHeader
                    title={(menuItemModel.id === 0) ? 'New Menu Item' : 'Menu Item Details'}
                    headerSize="lg"
                  />
                </ContentBlock>

                <ContentBlock>
                  <ContentHeader
                    title="Basic Information"
                    headerSize="md"
                    className="alt-font fw-600 secondary--clr pb-15"
                  />
                </ContentBlock>

                <ContentBlock cols={4}>
                  <InputBlock
                    label="Menu Item Name"
                    placeholder="e.g. Services"
                    isRequired
                    errorMessage={formik.errors.name}
                    inputState={`${helper.getInputClasses(formik, 'name')}`}
                    {...formik.getFieldProps('name')}
                  />
                </ContentBlock>

                <ContentBlock cols={4}>
                  <SelectBlock
                    label="Assigned Page"
                    placeholder="Select a page to assign"
                    isClearable
                    options={pageOptions}
                    value={selectedOptions.page}
                    isDisabled={formik.values.customLink}
                    onChange={(selectedOpt) => {
                      selectedOpt = selectedOpt === null ? [] : selectedOpt;
                      setSelectedOptions({ ...selectedOptions, page: selectedOpt });
                      formik.setFieldValue('pageId', selectedOpt.value ? selectedOpt.value : null);
                    }}
                  />
                </ContentBlock>

                <ContentBlock cols={4}>
                  <SelectBlock
                    label="Parent Menu Item (Optional)"
                    placeholder="Select a parent menu item"
                    isClearable
                    options={menuItemOptions}
                    inputState={formik.values.assignedMenuItems.length > 0 ? 'disabled' : ''}
                    // isDisabled={formik.values.assignedMenuItems.length > 0}
                    value={selectedOptions.parentMenuItem}
                    onChange={(selectedOpt) => {
                      selectedOpt = selectedOpt === null ? [] : selectedOpt;
                      setSelectedOptions({ ...selectedOptions, parentMenuItem: selectedOpt });
                      formik.setFieldValue('parentMenuItemId', selectedOpt.value ? selectedOpt.value : null);
                    }}
                  />
                </ContentBlock>

                {
                  (formik.values.pageId === null)
                  && (
                    <ContentBlock>
                      <InputBlock
                        label="Custom Link (Can be used in place of a page)"
                        placeholder="Paste or enter a url e.g. https://google.com/"
                        errorMessage={formik.errors.customLink}
                        inputState={`${helper.getInputClasses(formik, 'customLink')}`}
                        {...formik.getFieldProps('customLink')}
                      />
                    </ContentBlock>
                  )
                }

                <ContentBlock cols={3} className="mt-15">
                  <CheckboxBlock
                    label="Open In A New Tab?"
                    id="isTargetBlank"
                    {...formik.getFieldProps('openInNewTab')}
                    isChecked={formik.values.openInNewTab}
                  />
                </ContentBlock>

                <ContentBlock cols={3} className="mt-15">
                  <CheckboxBlock
                    label="Is Menu Item Active?"
                    id="isActive"
                    {...formik.getFieldProps('isActive')}
                    isChecked={formik.values.isActive}
                  />
                </ContentBlock>
              </Section>

              {/* ASSIGNED MENU ITEMS */}
              {
                (menuItemModel.id > 0 && formik.values.parentMenuItemId === null)
                && (
                  <Section hasNoContainer className="mt-60">
                    <ContentBlock>
                      <ContentHeader
                        title="Assigned Menu Items"
                        headerSize="lg"
                        primaryButtonText="Assign New"
                        primaryButtonOnClick={() => {
                          setIsModalVisible(true);
                        }}
                        secondaryButtonText="Sort Menu Items"
                        secondaryButtonOnClick={() => setIsSortModalOpen(true)}
                      />
                    </ContentBlock>

                    <ContentBlock>
                      <MUIDataTable
                        data={menuItemModel.assignedMenuItems}
                        columns={columns}
                        options={options}
                      />
                    </ContentBlock>
                  </Section>
                )
              }

              {/* PAGE ACTIONS */}
              <FixedActionsBar
                primaryActionText={menuItemModel.id === 0 ? 'Create Menu Item' : 'Save Changes'}
                primaryActionColor="primary--bg"
                primaryActionOnClick={formik.submitForm}
                secondaryActionText="Cancel"
                secondaryActionTo="/cms/management/menu-items"
                optionalActionText={menuItemModel.id > 0 ? 'Return to Listing' : ''}
                optionalActionTo="/cms/management/menu-items"
              />
            </FormBlock>
          </ContentBlock>
        </Section>

        {/* ADD IMAGES MODAL */}
        <ModalBlock
          hasCloseAction
          centered
          isVisible={isModalVisible}
          size="md"
          contentHeader="Add Menu Items"
          primaryModalActionText="Add"
          primaryModalActionColor="primary--bg"
          primaryModalActionOnClick={assignMenuItem}
          secondaryModalActionText="Cancel"
          secondaryModalActionColor="danger--bg"
          onHide={() => {
            setIsModalVisible(false);
          }}
        >
          <Section hasNoContainer>
            <ContentBlock>
              <SelectBlock
                label="Menu Items"
                placeholder="Select menu items"
                isMulti
                closeMenuOnSelect={false}
                isClearable
                options={assignmentMenuItemOptions}
                value={selectedAssignmentMenuItem}
                onChange={(selectedOpt) => {
                  selectedOpt = selectedOpt === null ? [] : selectedOpt;
                  setSelectedAssignmentMenuItem(selectedOpt);
                }}
              />
            </ContentBlock>
          </Section>
        </ModalBlock>

        {/* SORT MODAL */}
        <ModalBlock
          hasCloseAction
          isVisible={isSortModalOpen}
          size="md"
          contentHeader="Sort Menu Items"
          primaryModalActionText="Sort"
          primaryModalActionOnClick={sortChildMenuItems}
          onHide={() => {
            setIsSortModalOpen(false);
          }}
        >
          <Section hasNoContainer>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(droppableProvided) => (
                  <div
                    ref={droppableProvided.innerRef}
                  >
                    {sortList.map((item, index) => (
                      <Draggable
                        key={item.id} draggableId={`${index}`}
                        index={index}
                      >
                        {(draggableProvided) => (
                          <div
                            key={item.id}
                            ref={draggableProvided.innerRef}
                            {...draggableProvided.draggableProps}
                            {...draggableProvided.dragHandleProps}
                            className="content-container--card-style light-grey--sbg pt-15 pb-15 pl-20 pr-20 mb-15"
                          >
                            <p className="main-font fw-500">{item.name}</p>
                          </div>

                        )}
                      </Draggable>
                    ))}
                    {droppableProvided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </Section>
        </ModalBlock>
      </CustomBlock>
    </>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(mapStateFromProps, {
  ...auth.actions,
  ...alert.actions,
  ...confirmModalRedux.actions
})(MenuItemManagementPage);