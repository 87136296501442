/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable camelcase */
/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
// DEPENDENCIES
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import { useFormik } from 'formik';
// COMPONENTS
// ICONS
// CUSTOM COMPONENTS
import Section from '../../../../components/Section';
import ContentBlock from '../../../../components/ContentBlock';
import CustomBlock from '../../../../components/CustomBlock';
import FormBlock from '../../../../components/FormBlock';
import ContentHeader from '../../../../components/ContentHeader';
import InputBlock from '../../../../components/InputBlock';
import SelectBlock from '../../../../components/SelectBlock';
import Overlay from '../../../../components/Overlay';
import FixedActionsBar from '../../../../components/FixedActionsBar';
// HELPERS AND SERVICES
import * as helper from '../../../../helpers/helper';
import * as clientService from '../../../../services/management/clientService';
// REDUX
import * as alert from '../../../../redux/alertToastRedux';
import * as auth from '../../../../redux/authRedux';

const frequencyOptions = ['Monthly', 'Weekly', 'Bi-weekly', 'Every three weeks'];

const initialClientModel = {
  id: 0,
  isActive: true,
  name: '',
  contactName: '',
  contactPhone: '',
  contactEmail: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  zipCode: '',
  reportFrequency: 'Monthly',
};

const ClientManagementPage = (props) => {
  const { history, showAlert } = props;
  let { clientId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [client, setClient] = useState(initialClientModel);
  clientId = parseInt(clientId, 10);

  useEffect(() => {
    if (clientId) {
      fetchClient();
    }
  }, []);

  const fetchClient = () => {
    setIsLoading(true);
    clientService.getById(clientId).then((res) => {
      setClient(res);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const formik = useFormik({
    initialValues: client,
    validationSchema: Yup.object().shape({
      id: Yup.number(),
      isActive: Yup.bool(),
      name: Yup.string().required('Name is required').max(1000),
      contactName: Yup.string().nullable().max(1000),
      contactPhone: Yup.string().nullable().max(150),
      contactEmail: Yup.string().nullable().email().max(1000),
      address1: Yup.string().nullable().max(500),
      address2: Yup.string().nullable().max(500),
      city: Yup.string().nullable().max(500),
      state: Yup.string().nullable().max(500),
      zipCode: Yup.string().nullable().max(500),
      reportFrequency: Yup.string().nullable().max(500),

    }),
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      if (clientId) {
        updateClient(values);
      } else {
        createClient(values);
      }
    },
  });

  const updateClient = (model) => {
    setIsLoading(true);
    clientService.update(model).then((res) => {
      showAlert({ text: res.message, state: 'success' });
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'warning' });
    }).finally(() => setIsLoading(false));
  };

  const createClient = (model) => {
    setIsLoading(true);

    clientService.create(model).then((res) => {
      showAlert({ text: res.message, state: 'success' });
      history.push(`/admin/management/client/${res.id}`);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'warning' });
    }).finally(() => setIsLoading(false));
  };

  return (
    <>
      {isLoading && <Overlay hasLoader />}
      <CustomBlock className="content-container--padded">
        <Section isFullWidth>
          <ContentBlock>
            <FormBlock onSubmit={formik.submitForm}>

              {/* CONTENT DETAILS */}
              <Section
                hasNoContainer
                className="content-container--card-style--with-shadow"
              >
                <ContentBlock>
                  <ContentHeader
                    title={clientId ? 'Client Details' : 'New Client'}
                    headerSize="lg"
                    containerClassName="pb-20"
                    primaryButtonText={clientId ? 'View Benchmarks' : ''}
                    primaryButtonTo={`/admin/management/client/benchmark/${clientId}`}
                  />
                </ContentBlock>

                <ContentBlock>
                  <ContentHeader
                    title="Basic Information"
                    headerSize="md"
                    className="alt-font fw-600 secondary--clr pb-15"
                    containerClassName="pb-0"
                  />
                </ContentBlock>

                <ContentBlock cols={8} className="pb-20">
                  <InputBlock
                    isRequired
                    label="Name"
                    placeholder="e.g. Client Name"
                    errorMessage={formik.errors.name}
                    inputState={`${helper.getInputClasses(formik, 'name')}`}
                    {...formik.getFieldProps('name')}
                  />
                </ContentBlock>

                <ContentBlock>
                  <ContentHeader
                    title="Contact Details"
                    headerSize="md"
                    className="alt-font fw-600 secondary--clr pb-15"
                    containerClassName="pb-0"
                  />
                </ContentBlock>

                <ContentBlock cols={4}>
                  <InputBlock
                    label="Contact Name"
                    placeholder="e.g. John Doe"
                    errorMessage={formik.errors.contactName}
                    inputState={`${helper.getInputClasses(formik, 'contactName')}`}
                    {...formik.getFieldProps('contactName')}
                  />
                </ContentBlock>

                <ContentBlock cols={4}>
                  <InputBlock
                    label="Contact Email"
                    placeholder="e.g. john.doe@xyz.com"
                    errorMessage={formik.errors.contactEmail}
                    inputState={`${helper.getInputClasses(formik, 'contactEmail')}`}
                    {...formik.getFieldProps('contactEmail')}
                  />
                </ContentBlock>

                <ContentBlock cols={4}>
                  <InputBlock
                    mask="(999)-999-9999"
                    label="Contact Phone"
                    placeholder="e.g. (999)-99-9999"
                    errorMessage={formik.errors.contactPhone}
                    inputState={`${helper.getInputClasses(formik, 'contactPhone')}`}
                    {...formik.getFieldProps('contactPhone')}
                  />
                </ContentBlock>

                {/* LOCATION */}
                <ContentBlock className="mt-20">
                  <ContentHeader
                    title="Location"
                    headerSize="md"
                    className="alt-font fw-600 secondary--clr pb-15"
                    containerClassName="pb-0"
                  />
                </ContentBlock>

                <ContentBlock cols={8}>
                  <InputBlock
                    required
                    label="Street Address"
                    placeholder="e.g. 123 Main St"
                    errorMessage={formik.errors.address1}
                    inputState={`${helper.getInputClasses(formik, 'address1')}`}
                    {...formik.getFieldProps('address1')}
                  />
                </ContentBlock>

                <ContentBlock cols={4}>
                  <InputBlock
                    required
                    label="Apt/Unit/Suit"
                    placeholder="e.g. Apt. B"
                    errorMessage={formik.errors.address2}
                    inputState={`${helper.getInputClasses(formik, 'address2')}`}
                    {...formik.getFieldProps('address2')}
                  />
                </ContentBlock>

                <ContentBlock cols={4}>
                  <InputBlock
                    required
                    label="City"
                    placeholder="e.g. Baton Rouge"
                    errorMessage={formik.errors.city}
                    inputState={`${helper.getInputClasses(formik, 'city')}`}
                    {...formik.getFieldProps('city')}
                  />
                </ContentBlock>

                <ContentBlock cols={4}>
                  <InputBlock
                    required
                    label="State"
                    placeholder="e.g. Louisiana"
                    errorMessage={formik.errors.state}
                    inputState={`${helper.getInputClasses(formik, 'state')}`}
                    {...formik.getFieldProps('state')}
                  />
                </ContentBlock>

                <ContentBlock cols={4}>
                  <InputBlock
                    required
                    label="Zip Code"
                    placeholder="e.g. 12345"
                    errorMessage={formik.errors.zipCode}
                    inputState={`${helper.getInputClasses(formik, 'zipCode')}`}
                    {...formik.getFieldProps('zipCode')}
                  />
                </ContentBlock>

                {/* SCHEDULE */}
                <ContentBlock className="mt-20">
                  <ContentHeader
                    title="Report Frequency"
                    headerSize="md"
                    className="alt-font fw-600 secondary--clr pb-15"
                    containerClassName="pb-0"
                  />
                </ContentBlock>

                <ContentBlock cols={3}>
                  <SelectBlock
                    label="Report Frequency"
                    placeholder="Select an option"
                    options={frequencyOptions.map((x) => helper.setToOptionModel(x, x))}
                    isRequired
                    errorMessage={formik.errors.reportFrequency}
                    inputState={`${helper.getInputClasses(formik, 'reportFrequency')}`}
                    value={
                      formik.values.reportFrequency
                        ? helper.setToOptionModel(formik.values.reportFrequency, formik.values.reportFrequency)
                        : null
                    }
                    onChange={(opt) => {
                      formik.setFieldValue('reportFrequency', opt ? opt.label : '');
                    }}
                  />
                </ContentBlock>
              </Section>

              {/* PAGE ACTIONS */}
              <FixedActionsBar
                primaryActionText={clientId > 0 ? 'Save Changes' : 'Create Client'}
                primaryActionColor="primary--bg"
                primaryActionOnClick={formik.handleSubmit}
                secondaryActionText="Cancel"
                secondaryActionTo="/admin/management/clients"
                optionalActionText={clientId > 0 ? 'Return to Listing' : ''}
                optionalActionTo="/admin/management/clients"
              />
            </FormBlock>
          </ContentBlock>
        </Section>
      </CustomBlock>
    </>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(
  mapStateFromProps,
  {
    ...auth.actions,
    ...alert.actions
  }
)(ClientManagementPage);