// DEPENDENCIES
import React, { useState } from 'react';
import { useWizard } from 'react-use-wizard';
// CUSTOM COMPONENTS
import Overlay from '../../../../../components/Overlay';
import ContentBlock from '../../../../../components/ContentBlock';
import Button from '../../../../../components/Button';
import Section from '../../../../../components/Section';
import FileUploadBlock from '../../../../../components/FileUploadBlock';
import TextHeader from '../../../../../components/TextHeader';
// SERVICES AND HELPERS
import constants from '../../../../../constants/constants';

const Step1FileUploadWizard = (props) => {
  const { excelFileUpload, setExcelFileUpload } = props;
  const [isLoading] = useState(false);
  const { nextStep } = useWizard();

  return (
    <>
      {isLoading && <Overlay />}
      <Section hasNoContainer>
        <TextHeader
          title="Step 1: Excel Upload"
          size="md"
          subtitle="Choose a CSV (.csv) or Excel (.xls or .xlsx) file to upload."
          subtitleSize="sm"
          className="secondary--clr mb-5"
          subtitleClassName="mb-20"
        />

        <ContentBlock>
          <FileUploadBlock
            files={excelFileUpload}
            labelIdle="Upload Excel File to create report."
            onupdatefiles={setExcelFileUpload}
            acceptedFileTypes={constants.EXCEL_FILE_TYPES}
          />
        </ContentBlock>

        <ContentBlock className="content-container--actions flex-end">
          <Button
            type="button"
            text="Continue"
            className={excelFileUpload.length > 0 ? 'teal--bg' : 'light-grey--sbg'}
            onClick={nextStep}
            isDisabled={excelFileUpload.length === 0}
          />
        </ContentBlock>
      </Section>
    </>
  );
};

export default Step1FileUploadWizard;