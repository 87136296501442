/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-use-before-define */
/* eslint-disable react/no-array-index-key */
// DEPENDENCIES
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
// COMPONENTS
import MUIDataTable from 'mui-datatables';
// ICONS
// CUSTOM COMPONENTS
import Section from '../../../../components/Section';
import ContentBlock from '../../../../components/ContentBlock';
import CustomBlock from '../../../../components/CustomBlock';
import ContentHeader from '../../../../components/ContentHeader';
import Button from '../../../../components/Button';
import OverLay from '../../../../components/Overlay';
// HELPERS AND SERVICES
import * as bidService from '../../../../services/management/bidService';
// REDUX
import * as alert from '../../../../redux/alertToastRedux';
import * as authRedux from '../../../../redux/authRedux';
import * as confirmModal from '../../../../redux/confirmModalRedux';

const BidListingPage = (props) => {
  const {
    history,
    showAlert,
    auth,
    showConfirmModal,
    hideConfirmModal
  } = props;
  const [bids, setBids] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // const iconSize = 22;
  // const iconColor = 'white--clr';

  useEffect(() => {
    getAllBids();
  }, []);

  const getAllBids = () => {
    setIsLoading(true);
    bidService.getAll().then((res) => {
      setBids(res);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const columnOptions = {
    filter: true,
    sort: true,
    print: false,
    download: true,
  };

  const columns = [
    {
      name: 'id',
      label: 'Actions',
      options: {
        filter: false,
        sort: false,
        print: false,
        download: false,
        customBodyRenderLite: (dataIndex) => (
          <CustomBlock className="content-container--actions flex-start pl-0 mt-0">
            <Button
              text="View"
              className="primary--bg mr-5"
              size="xxs"
              onClick={() => {
                history.push(`/admin/management/bid/${bids[dataIndex].id}`);
              }}
            />

            {
              auth.isAdmin
              && (
                <Button
                  text="Delete"
                  className="danger--bg"
                  size="xxs"
                  onClick={() => {
                    showConfirmModal({
                      title: 'Remove Shipping Analysis',
                      text: 'Are you sure you want to remove this parcel shipping analysis?',
                      rightBtnText: 'Confirm',
                      btnAction: () => {
                        deleteBid(bids[dataIndex].id);
                      }
                    });
                  }}
                />
              )
            }
          </CustomBlock>
        )
      }
    },
    {
      name: 'id',
      label: 'Analysis#',
      options: columnOptions,
    },
    {
      name: 'client',
      label: 'Client',
      options: columnOptions,
    },
    {
      name: 'type',
      label: 'Carrier',
      options: columnOptions,
    },
    {
      name: 'dateCreated',
      label: 'Date Created',
      options: columnOptions,
    }
  ];

  const deleteBid = (bidId) => {
    setIsLoading(true);

    bidService.remove(bidId).then((res) => {
      showAlert({ text: res.message, state: 'success' });
      getAllBids();
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
      hideConfirmModal();
    });
  };

  return (
    <>
      {isLoading && <OverLay hasLoader />}
      <CustomBlock className="content-container--padded">
        <Section isFullWidth>
          <ContentBlock>
            <CustomBlock className="content-container--card-style--with-shadow">
              <ContentHeader
                title="Parcel Shipping Analysis"
                headerSize="lg"
                primaryButtonText={auth.isAdmin ? 'New Analysis' : ''}
                primaryButtonOnClick={() => {
                  history.push('/admin/management/bid/0');
                }}
              />

              <CustomBlock>
                <MUIDataTable
                  data={bids}
                  columns={columns}
                  options={{
                    selectableRows: 'none',
                    download: true,
                    print: false,
                    jumpToPage: true,
                    textLabels: { pagination: { jumpToPage: 'Page No:' } }
                  }}
                />
              </CustomBlock>
            </CustomBlock>
          </ContentBlock>
        </Section>
      </CustomBlock>
    </>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(
  mapStateFromProps,
  {
    ...authRedux.actions,
    ...alert.actions,
    ...confirmModal.actions
  }
)(BidListingPage);