// DEPENDENCIES
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useFormik } from 'formik';
// COMPONENTS
// ICONS
import { TbUserSquareRounded, TbLockSquareRounded } from 'react-icons/tb';
// CUSTOM COMPONENTS
import CustomBlock from '../../../components/CustomBlock';
import FormBlock from '../../../components/FormBlock';
import ContentHeader from '../../../components/ContentHeader';
import InputBlock from '../../../components/InputBlock';
import Image from '../../../components/Image';
import Button from '../../../components/Button';
// import TextBlock from '../../../components/TextBlock';
// PARTIALS
// import ForgotPasswordBlock from './_partials/ForgotPasswordBlock';
// ASSETS
import Logo from '../../../assets/client/logo.svg';
// SERVICES & HELPERS
import LoginValidator from '../../../helpers/validators/user/LoginValidator';
import * as helper from '../../../helpers/helper';
import * as userService from '../../../services/management/userService';
// REDUX
import * as auth from '../../../redux/authRedux';
import * as alertToast from '../../../redux/alertToastRedux';

const initialModel = {
  email: '',
  password: ''
};

const LoginPage = (props) => {
  const { showAlert, login, history } = props;
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const iconSize = 30;

  const loginUser = (model, setSubmitting) => {
    userService.login(model).then((res) => {
      login(res);
      history.push('/admin/management/parcel-shipping-analysis');
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setSubmitting(false);
    });
  };

  const formik = useFormik({
    initialValues: initialModel,
    validationSchema: LoginValidator,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);

      loginUser(values, setSubmitting);
    },
  });

  return (
    <CustomBlock className="auth-page-wrapper">
      <CustomBlock className="auth-page">
        <CustomBlock className="logo-block">
          <CustomBlock>
            <Image
              source={Logo}
            />
          </CustomBlock>

          <CustomBlock className="copyright-block">
            <p>
              {`Copyright © ${(new Date()).getFullYear()} Transport Logics. All rights reserved.`}
            </p>
          </CustomBlock>
        </CustomBlock>

        <CustomBlock className="form-block">
          <CustomBlock className="input-elements">
            <CustomBlock className="input-header">
              <ContentHeader
                title="Welcome Back!"
                subtitle="Enter your credentials below to access your portal"
                headerSize="lg"
              />
            </CustomBlock>

            <FormBlock>
              <InputBlock
                id="email"
                label="Email"
                placeholder="Enter Email"
                backgroundColor="white--sbg"
                iconLeft={<TbUserSquareRounded size={iconSize} className="" />}
                errorMessage={formik.errors.email}
                inputState={`${helper.getInputClasses(formik, 'email')}`}
                {...formik.getFieldProps('email')}
              />

              <InputBlock
                id="password"
                type={isPasswordVisible ? 'text' : 'password'}
                label="Password"
                placeholder="Enter Password"
                backgroundColor="white--sbg"
                iconLeft={<TbLockSquareRounded size={iconSize} className="" />}
                inputRightButtonText={isPasswordVisible ? 'Hide' : 'Show'}
                inputRightButtonOnClick={() => setIsPasswordVisible(!isPasswordVisible)}
                {...formik.getFieldProps('password')}
                errorMessage={formik.errors.password}
                inputState={`${helper.getInputClasses(formik, 'password')}`}
              />

              {/* <CustomBlock className="link-block">
              <TextBlock
                type="link"
                text="Are you nonprofit? Register here"
                link="/register"
                color="info--clr"
              />

              <TextBlock
                type="link"
                text="Forgot Password?"
              />
            </CustomBlock> */}

              <CustomBlock className="form-action-block">
                <Button
                  type="submit"
                  text="Sign In"
                  className="primary--bg"
                  isLoading={formik.isSubmitting}
                  isDisabled={formik.isSubmitting || !formik.isValid}
                  onClick={formik.handleSubmit}
                />
                {/*
                <CustomBlock className="flex-center mt-20">
                  <TextBlock
                    type="link"
                    text="No Account? Register Here"
                    link="/register"
                    color="blue--clr"
                    className="fw-500"
                  />
                </CustomBlock> */}
              </CustomBlock>
            </FormBlock>
          </CustomBlock>
        </CustomBlock>
      </CustomBlock>
    </CustomBlock>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(mapStateFromProps, { ...auth.actions, ...alertToast.actions })(LoginPage);