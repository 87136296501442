// DEPENDENCIES
import React from 'react';
import PropTypes from 'prop-types';
// CUSTOM COMPONENTS
import CustomBlock from './CustomBlock';
import Button from './Button';
import ContentHeader from './ContentHeader';

const FixedActionsBar = (props) => {
  const {
    buttonSize,
    position,
    children,
    primaryActionText,
    primaryActionColor,
    primaryActionTo,
    primaryActionOnClick,
    secondaryActionText,
    secondaryActionColor,
    secondaryActionTo,
    secondaryActionOnClick,
    optionalText,
    optionalActionText,
    optionalActionColor,
    optionalActionTo,
    optionalActionOnClick,
    isLeftBlockTextOnly
  } = props;

  const positions = {
    top: 'top',
    bottom: 'bottom'
  };

  return (
    <CustomBlock className={`fixed-actions-bar ${positions[position]}`}>
      <CustomBlock className="left-action-block small-bar">
        {
          children !== null
          && (
            <CustomBlock>
              {children}
            </CustomBlock>
          )
        }
        <CustomBlock className="input-item action">
          {
            (optionalActionText !== '' || null)
            && (
              <Button
                text={optionalActionText}
                className={optionalActionColor}
                to={optionalActionTo}
                onClick={optionalActionOnClick}
                size={buttonSize}
              />
            )
          }

          {
            ((optionalText !== '' || null) && isLeftBlockTextOnly)
            && (
              <ContentHeader
                title={optionalText}
                headerSize="sm"
                className="secondary--clr"
                containerClassName="pb-0"
              />
            )
          }
        </CustomBlock>
      </CustomBlock>

      <CustomBlock className="content-container--actions small-bar">
        {
          (secondaryActionText !== '' || null)
          && (
            <Button
              text={secondaryActionText}
              className={secondaryActionColor}
              to={secondaryActionTo}
              onClick={secondaryActionOnClick}
              size={buttonSize}
            />
          )
        }
        {
          primaryActionText && (
            <Button
              text={primaryActionText}
              className={primaryActionColor}
              to={primaryActionTo}
              onClick={primaryActionOnClick}
              size={buttonSize}
            />
          )
        }

      </CustomBlock>
    </CustomBlock>
  );
};

FixedActionsBar.propTypes = {
  // BUTTON PROPS
  buttonSize: PropTypes.string,

  // MAIN PROPS
  position: PropTypes.string,
  children: PropTypes.any,

  // ACTION PROPS
  primaryActionText: PropTypes.string.isRequired,
  primaryActionColor: PropTypes.string,
  primaryActionTo: PropTypes.string,
  primaryActionOnClick: PropTypes.func,

  secondaryActionText: PropTypes.string,
  secondaryActionColor: PropTypes.string,
  secondaryActionTo: PropTypes.string,
  secondaryActionOnClick: PropTypes.func,

  optionalText: PropTypes.string,
  optionalActionText: PropTypes.string,
  optionalActionColor: PropTypes.string,
  optionalActionTo: PropTypes.string,
  optionalActionOnClick: PropTypes.func,

  isLeftBlockTextOnly: PropTypes.bool
};

FixedActionsBar.defaultProps = {
  // BUTTON PROPS
  buttonSize: '',

  // POSITION PROPS
  position: 'bottom',
  children: null,

  // MAIN PROPS
  primaryActionColor: 'primary--bg',
  primaryActionTo: '',
  primaryActionOnClick: null,

  secondaryActionText: '',
  secondaryActionColor: 'danger--bg',
  secondaryActionTo: '',
  secondaryActionOnClick: null,

  optionalText: '',
  optionalActionText: '',
  optionalActionColor: 'secondary--bg',
  optionalActionTo: '',
  optionalActionOnClick: null,

  isLeftBlockTextOnly: false
};

export default FixedActionsBar;