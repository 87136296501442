/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable max-len */
// DEPENDENCIES
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
// ICONS
import { FiMenu } from 'react-icons/fi';
import {
  TbHome, TbNotes, TbLock, TbMessage,
} from 'react-icons/tb';
// COMPONENTS
import { Sling as Hamburger } from 'hamburger-react';
import CustomBlock from '../CustomBlock';
import Image from '../Image';
import SocialIconSet from '../SocialIcons/SocialIconSet';
import SocialIconButton from '../SocialIcons/SocialIconButton';
// HEADERS
import TopHeaderDevReference from './TopHeaderDevReference';
import TopHeaderSearchAndFilter from './TopHeaderSearchAndFilter';
import DrawerNavigation from './DrawerNavigation';
// SERVICES & HELPERS
import * as menuItemService from '../../services/cms/menuItemService';

const HeaderNavigation = (props) => {
  const {
    auth,
    cmsInfo,
    // layout,
    phoneNumber,
    emailAddress,
    backgroundColor,
    menuItemsBlockBackgroundColor,
    menuItemColor,
    menuToggleIconColor,
    menuToggleIconSize,
    mobileMenuItemColor,
    submenuBlockBackgroundColor,
    submenuItemColor,
    fixedNavigationBarBackgroundColor,
    logo,
    logoAlt,
    icon,
    iconSize,
    iconAppearance,
    socialIconColor,
    socialIconBackgroundColor,
    primarySocialMediaLink,
    secondarySocialMediaLink,
    hasSearchBlock,
    menuItems,
    hasChipFilter,
    hasRoundCorners,
    history,
    companyLogos,
    showAlert,
  } = props;
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [onScroll, setOnScroll] = useState(false);
  // TOP HEADER DEV REFERENCE
  const isDevEnv = process.env.NODE_ENV === 'development';
  const showTopHeaderDevReference = true;

  useEffect(() => {
    const handleScrollChange = () => {
      const scrollCheck = window.scrollY > 10;
      setOnScroll(scrollCheck);
    };

    document.addEventListener('scroll', handleScrollChange);
    return () => {
      document.removeEventListener('scroll', handleScrollChange);
    };
  }, [onScroll]);

  const openInNewTab = (link) => {
    window.open(link, '_blank');
  };

  const handleMenuItemClick = (menuItem) => {
    if (menuItem.pageUrl === '') {
      menuItemService.getMenuItemsById(menuItem.id).then((res) => {
        if (res.customLink && res.customLink.includes('https')) {
          openInNewTab(res.customLink);
        } else {
          history.push(res.customLink);
        }
      });
    }

    if (menuItem.pageUrl && menuItem.pageUrl !== '') {
      if (menuItem.openInNewTab) {
        openInNewTab(menuItem.pageUrl);
      } else {
        history.push(menuItem.pageUrl);
      }
    } else if (menuItem.customLink) {
      if (menuItem.openInNewTab) {
        openInNewTab(menuItem.customLink);
      } else {
        history.push(menuItem.customLink);
      }
    }

    setTimeout(() => {
      setIsDrawerOpen(false);
    }, 200);
  };

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  return (
    <>
      <CustomBlock className={`header-navigation-container ${onScroll ? 'on-scroll' : ''}`}>
        {/* TOP HEADER - DEVELOPMENT ENVIRONMENT */}
        {(isDevEnv && showTopHeaderDevReference) && (
          <TopHeaderDevReference />
        )}

        {/* TOP HEADER - SEARCH AND FILTER */}
        {(hasSearchBlock || hasChipFilter) && (
          <TopHeaderSearchAndFilter
            hasSearchBlock={hasSearchBlock}
            hasChipFilter={hasChipFilter}
            onScroll={onScroll}
            history={history}
            auth={auth}
          />
        )}
      
        {/* HEADER NAVIGATION */}
        <CustomBlock className="header-navigation">
          {/* LOGO */}
          <CustomBlock className="logo-block user-select-none">
            <Image
              source={onScroll ? companyLogos.default : companyLogos.default}
              to="/"
            />
          </CustomBlock>

          {/* MENU ITEMS */}
          <CustomBlock className="menu-items-block user-select-none">
            <CustomBlock className="menu-items">
              {menuItems.map((menuItem) => (
                <>
                  {/* MENU ITEM - NO CHILDREN */}
                  {menuItem.assignedMenuItems.length === 0 && (
                    <NavLink
                      to={menuItem.pageUrl || menuItem.customLink}
                      onClick={() => handleMenuItemClick(menuItem)}
                      className="size-md primary--clr fw-600 primary--hover-underline-3"
                    >
                      {menuItem.name}
                    </NavLink>
                  )}

                  {/* MENU ITEM - HAS CHILDREN */}
                  {menuItem.assignedMenuItems.length > 0 && (
                    <CustomBlock className="has-submenu">
                      <NavLink
                        to={menuItem.pageUrl || menuItem.customLink}
                        onClick={() => handleMenuItemClick(menuItem)}
                        className="size-md primary--clr fw-600 primary--hover-underline-3"
                      >
                        {menuItem.name}
                      </NavLink>
                      <CustomBlock
                        className={`submenu-block p-3 default ${submenuBlockBackgroundColor} ${hasRoundCorners ? 'round-corners' : ''}`}
                        style={{ border: '2px solid #a3a3a35e' }}
                      >
                        <CustomBlock className="d-flex flex-column gap-3">
                          {menuItem.assignedMenuItems.map((submenuItem) => (
                            <NavLink
                              to={submenuItem.pageUrl}
                              onClick={() => handleMenuItemClick(menuItem)}
                              target={submenuItem.openInNewTab ? '_blank' : null}
                              rel={submenuItem.openInNewTab ? 'noopener noreferrer' : null}
                              className="size-sm primary--clr fw-500 primary--hover-underline fit-content"
                            >
                              {submenuItem.name}
                            </NavLink>
                          ))}
                        </CustomBlock>
                      </CustomBlock>
                    </CustomBlock>
                  )}
                </>
              ))}
            </CustomBlock>
          </CustomBlock>

          {/* SOCIAL BLOCK */}
          <CustomBlock className="header-navigation-secondary">
            <CustomBlock className="icons-block">
              <SocialIconSet
                cmsInfo={cmsInfo}
                history={history}
                location="headerNav"
              />
              {/* MENU ICON */}
              <SocialIconButton
                displayIf
                onClickAction={toggleDrawer}
                className="social-icon mobile-menu-toggle d-none primary--clr"
                // socialIcon={<FiMenu className="primary--clr" size="37" />}
                socialIcon={(
                  <Hamburger
                    toggled={isDrawerOpen}
                    toggle={setIsDrawerOpen}
                    size={32}
                    height={45}
                    distance="md"
                    rounded
                  />
                )}
              />
            </CustomBlock>
          </CustomBlock>
        </CustomBlock>
      </CustomBlock>

      {/* DRAWER NAVIGATION */}
      <DrawerNavigation
        hasOverlay
        isOpen={isDrawerOpen}
        willCloseOnMaskPress
        onClick={() => {
          toggleDrawer();
        }}
        className="drawer-navigation"
        headerItems={(
          <CustomBlock className="logo-block d-flex align-items-center justify-content-center">
            <Image
              source={companyLogos.default}
              to="/"
              containerClassName="mobile-drawer-logo-container"
              style={{ width: '70%', maxWidth: '400px', opacity: '0.90' }}
            />
          </CustomBlock>
        )}
        footerItems={(
          <CustomBlock className="social-block scroll-shadows-y">
            <SocialIconSet
              cmsInfo={cmsInfo}
              history={history}
              // location="drawerNav"
              location="footer"
              containerClassName=""
            />
          </CustomBlock>
        )}
      >
        {/* DRAWER NAVIGATION - MENU ITEMS */}
        <CustomBlock className="menu-items d-flex flex-column gap-3 px-4 pt-10 pb-40">
          <NavLink
            to="/"
            className="size-md primary--clr fw-600 primary--hover-underline-3 fit-content"
          >
            <TbHome className="drawer-menu-icon" />
            Home
          </NavLink>

          <NavLink
            to="/admin/management/users"
            className="size-md primary--clr fw-600 primary--hover-underline-3 fit-content"
          >
            <TbLock className="drawer-menu-icon" />
            Admin
          </NavLink>
          
          <hr
            className="w-100 my-1 circle"
            style={{
              height: '2px',
              opacity: '0.10',
              background: 'linear-gradient(to right, rgba(255, 255, 255, 0) 0%, currentcolor 30%, currentcolor 70%, rgba(255, 255, 255, 0) 100%)',
              border: 'none'
            }}
          />

          {menuItems.map((menuItem) => (
            <CustomBlock className="">
              {/* PARENT */}
              <NavLink
                to={menuItem.pageUrl || menuItem.customLink}
                onClick={() => handleMenuItemClick(menuItem)}
                className="size-md primary--clr fw-600 primary--hover-underline-3 fit-content"
              >
                {{
                  // ICON MAPPING
                  // About: <TbAd2 className="drawer-menu-icon" />,
                  About: <TbNotes className="drawer-menu-icon" />,
                  Contact: <TbMessage className="drawer-menu-icon" />,
                }[menuItem.name] || <TbNotes className="drawer-menu-icon" />}
                {menuItem.name}
              </NavLink>

              {/* CHILDREN */}
              {menuItem.assignedMenuItems.length > 0 && (
              <CustomBlock className="has-submenu">
                <CustomBlock className="d-flex flex-column gap-2 mt-2">
                  {menuItem.assignedMenuItems.map((submenuItem) => (
                    <NavLink
                      key={submenuItem.id}
                      to={submenuItem.pageUrl}
                      onClick={() => handleMenuItemClick(submenuItem)}
                      target={submenuItem.openInNewTab ? '_blank' : null}
                      rel={submenuItem.openInNewTab ? 'noopener noreferrer' : null}
                      className="size-md primary--clr fw-500 primary--hover-underline fit-content fit-content"
                    >
                      {submenuItem.name}
                    </NavLink>
                  ))}
                </CustomBlock>
              </CustomBlock>
              )}
            </CustomBlock>
          ))}
        </CustomBlock>
      </DrawerNavigation>
    </>
  );
};

HeaderNavigation.propTypes = {
  // MAIN PROPS
  // layout: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string,
  menuItemsBlockBackgroundColor: PropTypes.string,
  // fixedNavigationBarBackgroundColor: PropTypes.string.isRequired,
  // to: PropTypes.string,

  // NAVIGATION PROPS
  menuItemColor: PropTypes.string,
  menuToggleIconColor: PropTypes.string,
  menuToggleIconSize: PropTypes.number,
  mobileMenuItemColor: PropTypes.string,

  // SUB MENU PROPS
  submenuBlockBackgroundColor: PropTypes.string,
  submenuItemColor: PropTypes.string,

  // SOCIAL MEDIA PROPS
  iconSize: PropTypes.number,
  iconAppearance: PropTypes.string.isRequired,
  socialIconBackgroundColor: PropTypes.string,
  socialIconColor: PropTypes.string,
  primarySocialMediaLink: PropTypes.string,
  secondarySocialMediaLink: PropTypes.string,

  // PRIMARY ICON PROPS
  // primaryActionIcon: PropTypes.any,
  // primaryActionIconText: PropTypes.string,

  // SECONDARY ICON PROPS
  // secondaryActionIcon: PropTypes.any,
  // secondaryActionIconText: PropTypes.string,

  // FUNCTION PROPS
  //   // primaryActionOnClick: PropTypes.func,
  // secondaryActionOnClick: PropTypes.func,

  // BOOLEANS PROPS
  hasSearchBlock: PropTypes.bool,

  // menuItems: PropTypes.array,
  hasChipFilter: PropTypes.bool,
  // hasFeaturedItems: PropTypes.bool,
  hasRoundCorners: PropTypes.bool,
};

HeaderNavigation.defaultProps = {
  // MAIN PROPS
  backgroundColor: 'white--sbg',
  menuItemsBlockBackgroundColor: null,
  // to: '',

  // NAVIGATION PROPS
  menuItemColor: 'primary--clr',
  menuToggleIconColor: 'primary--clr',
  menuToggleIconSize: 35,
  mobileMenuItemColor: 'dark--clr',

  // SUB MENU PROPS
  submenuBlockBackgroundColor: 'white--sbg',
  submenuItemColor: 'primary--clr',

  // SOCIAL MEDIA PROPS
  iconSize: 23,
  socialIconColor: 'white--clr',
  socialIconBackgroundColor: 'primary-lighter--sbg',
  primarySocialMediaLink: '',
  secondarySocialMediaLink: '',

  // // BOOLEANS PROPS
  hasSearchBlock: false,
  hasChipFilter: false,
  // hasFeaturedItems: false,
  hasRoundCorners: false,
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(mapStateFromProps, null)(HeaderNavigation);