import axiosInstance from '../apiService';
import constants from '../../constants/constants';

const ENDPOINTORIGIN = 'Benchmark/';

const getAll = async (clientId) => axiosInstance.get(`${ENDPOINTORIGIN}GetAll?clientId=${clientId}`, constants.REQUIRE_INTERCEPTORS);

const saveChanges = async (model) => axiosInstance.post(`${ENDPOINTORIGIN}Save`, model, constants.REQUIRE_INTERCEPTORS);

export {
  getAll,
  saveChanges
};