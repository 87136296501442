/* eslint-disable max-len */
/* eslint-disable no-use-before-define */
import React, {
  useState,
  useEffect,
  useRef
} from 'react';
// COMPONENTS
// ICONS
import { BiRefresh } from 'react-icons/bi';
// CUSTOM COMPONENTS
import CustomBlock from './CustomBlock';
import Overlay from './Overlay';
// ASSETS
import '../assets/custom/AnimatedLogoStyles.css';

const AnimatedLogo = (props) => {
  const { width, height } = props;

  const [isLoading] = useState(false);
  const [isAnimating, setIsAnimating] = useState(null);
  const [animationKey, setAnimationKey] = useState(0);
  const svgRef = useRef(null);

  useEffect(() => {
    if (isAnimating == null) {
      handleLogoAnimation();
    }
  }, []);

  const handleLogoAnimation = () => {
    setIsAnimating(true);
    setAnimationKey((prevKey) => prevKey + 1);
  };

  return (
    <>
      {(isLoading) && <Overlay hasLoader />}
      <CustomBlock className="d-flex flex-column justify-content-center align-items-center gap-40">
        <svg
          width={width || '100%'}
          height={height || 'auto'}
          // width="370"
          // height="286"
          viewBox="0 0 370 286"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          ref={svgRef}
          key={animationKey}
          style={{ opacity: '0.90' }}
        >
          {/* eslint-disable react/jsx-max-props-per-line, react/self-closing-comp, react/no-unknown-property */}
          <path d="M90.4511 86.8973C114.109 86.8973 133.287 67.719 133.287 44.0609C133.287 20.4029 114.109 1.22461 90.4511 1.22461C66.7931 1.22461 47.6147 20.4029 47.6147 44.0609C47.6147 67.719 66.7931 86.8973 90.4511 86.8973Z" fill="#0856A2" stroke="#0856A2" stroke-width="2" class="svg-elem-1"></path>
          <path d="M184.55 86.8973C208.208 86.8973 227.386 67.719 227.386 44.0609C227.386 20.4029 208.208 1.22461 184.55 1.22461C160.892 1.22461 141.713 20.4029 141.713 44.0609C141.713 67.719 160.892 86.8973 184.55 86.8973Z" fill="#47B494" stroke="#47B494" stroke-width="2" class="svg-elem-2"></path>
          <path d="M276.776 86.8973C300.434 86.8973 319.613 67.719 319.613 44.0609C319.613 20.4029 300.434 1.22461 276.776 1.22461C253.118 1.22461 233.94 20.4029 233.94 44.0609C233.94 67.719 253.118 86.8973 276.776 86.8973Z" fill="black" stroke="black" stroke-width="2" class="svg-elem-3"></path>
          <path d="M136.966 168.966C160.624 168.966 179.802 149.787 179.802 126.129C179.802 102.471 160.624 83.293 136.966 83.293C113.308 83.293 94.1294 102.471 94.1294 126.129C94.1294 149.787 113.308 168.966 136.966 168.966Z" fill="black" stroke="black" stroke-width="2" class="svg-elem-4"></path>
          <path d="M229.192 168.966C252.85 168.966 272.029 149.787 272.029 126.129C272.029 102.471 252.85 83.293 229.192 83.293C205.535 83.293 186.356 102.471 186.356 126.129C186.356 149.787 205.535 168.966 229.192 168.966Z" fill="#0856A2" stroke="#0856A2" stroke-width="2" class="svg-elem-5"></path>
          <path d="M6.3683 207.161L8.3121 207.198V223.414C8.3121 242.081 8.30912 242.036 9.72272 244.426C11.6974 247.765 14.8069 248.541 26.2155 248.541H32.7956V240.35H31.0504C29.1033 240.35 28.5415 240.204 28.1545 239.6L27.9155 239.227L27.8276 207.189H32.7956V198.998H27.8328V191.336H8.3121V198.998H1.54883V207.149L6.3683 207.161Z" fill="black" stroke="black" stroke-width="2" class="svg-elem-6"></path>
          <path fillRule="evenodd" clipRule="evenodd" d="M64.358 197.933C60.373 198.791 56.27 201.567 55.0384 204.238C54.8761 204.59 54.6812 204.934 54.6053 205.003C54.5293 205.072 54.4672 203.75 54.4672 202.064V198.999H36.1045V248.541H55.1289V220.985L55.3823 220.198C56.4081 217.01 58.9954 215.645 64.0141 215.645H66.3781V197.678L65.9231 197.686C65.673 197.69 64.9686 197.801 64.358 197.933Z" fill="black" stroke="black" stroke-width="2" class="svg-elem-7"></path>
          <path fillRule="evenodd" clipRule="evenodd" d="M85.2368 197.825C73.508 198.77 69.1364 203.136 69.1155 213.927L69.1074 218.089L86.5404 218.157L86.6363 207.843C86.6406 207.374 86.7872 206.918 87.0565 206.534C88.2992 204.763 90.9864 204.935 92.1225 206.859C92.3777 207.291 92.512 207.784 92.5113 208.287L92.5067 211.615C92.4981 217.92 92.4753 217.94 81.4319 221.326C69.5581 224.966 69.1074 225.482 69.1074 235.461V241.473L69.3986 242.378C71.9232 250.231 83.4968 252.47 91.9522 246.742C92.6228 246.287 92.9282 246.054 93.0664 246.128C93.1997 246.201 93.1774 246.56 93.1774 247.285V248.541H111.551L111.449 209.633L111.144 208.378C109.166 200.218 100.312 196.612 85.2368 197.825ZM92.433 238.718L92.4786 231.937C92.5038 228.208 92.4655 225.157 92.3938 225.157C91.1403 225.157 87.7961 228.224 87.04 230.067L87.0207 230.114C86.7713 230.723 86.6429 231.374 86.6429 232.031V237.846C86.6429 238.315 86.7874 238.772 87.0565 239.155C88.1528 240.718 90.7269 240.776 91.9779 239.267L92.433 238.718Z" fill="black" stroke="black" stroke-width="2" class="svg-elem-8"></path>
          <path d="M135.978 202.269C137.05 200.421 140.706 198.488 144.295 197.87C152.022 196.541 158.042 200.112 159.633 206.969L159.914 208.181L160.024 248.542H140.991L140.906 210.965C140.905 210.354 140.732 209.756 140.407 209.239C139.355 207.562 136.881 207.438 135.968 209.016C135.733 209.421 135.609 209.881 135.608 210.348L135.523 248.542H116.503V198.999H135.527V200.783C135.527 202.528 135.63 202.868 135.978 202.269Z" fill="black" stroke="black" stroke-width="2" class="svg-elem-9"></path>
          <path d="M164.743 212.804C164.771 203.621 170.066 198.818 181.268 197.814C193.396 196.727 202.971 200.401 205.19 206.991L205.546 208.048L205.708 214.984H188.176L188.031 209.094L187.628 208.516C186.496 206.898 183.706 206.933 182.676 208.579L182.264 209.237L182.263 211.02C182.26 214.248 183.097 214.926 191.36 218.385C202.807 223.177 205.356 225.48 206.181 231.769C207.854 244.53 200.502 250.221 182.86 249.821C168.871 249.503 164.15 245.606 164.148 234.371L164.146 230.705H181.66L181.765 238.434L182.046 238.844C184.214 242.005 188.062 240.131 188.04 235.924L188.03 233.81L187.669 233.173C186.784 231.61 184.883 230.468 179.399 228.205C167.86 223.442 164.721 220.143 164.743 212.804Z" fill="black" stroke="black" stroke-width="2" class="svg-elem-10"></path>
          <path fillRule="evenodd" clipRule="evenodd" d="M239.189 197.893C236.096 198.423 232.819 199.927 230.324 201.96C229.775 202.408 229.484 202.657 229.331 202.59C229.15 202.512 229.16 201.995 229.16 200.848V198.998H210.136V255.807H229.16V250.181C229.16 247.087 229.224 244.575 229.302 244.599C229.38 244.623 229.899 245.066 230.455 245.583C239.019 253.541 251.912 250.476 254.133 239.953C254.806 236.768 255.08 212.244 254.485 208.51C253.165 200.229 247.701 196.436 239.189 197.893ZM234.863 207.982C234.638 207.826 234.359 207.749 234.012 207.634C232.589 207.159 230.6 207.516 229.863 208.379C229.154 209.208 229.151 209.273 229.198 223.53L229.243 237.245L229.626 237.921C230.92 240.204 234.272 240.182 235.384 237.885L235.693 237.245L235.74 223.643C235.784 210.769 235.826 208.649 234.863 207.982Z" fill="black" stroke="black" stroke-width="2" class="svg-elem-11"></path>
          <path fillRule="evenodd" clipRule="evenodd" d="M277.135 197.867C266.707 199.062 260.531 203.813 259.44 211.482C259.207 213.123 259.212 234.404 259.446 236.081C260.678 244.909 268.576 249.796 281.601 249.788L285.489 249.786L287.421 249.494C296.543 248.114 302.008 243.863 303.563 236.935L303.85 235.659V211.879L303.556 210.564C301.498 201.375 290.914 196.288 277.135 197.867ZM284.659 208.813C284.323 207.889 283.428 207.156 282.381 206.945C280.705 206.608 279.358 207.175 278.633 208.522L278.287 209.164L278.375 237.773L278.829 238.389C280.155 240.19 283.193 240.08 284.462 238.185L284.827 237.641L284.917 209.522L284.659 208.813Z" fill="black" stroke="black" stroke-width="2" class="svg-elem-12"></path>
          <path d="M327.831 204.238C329.063 201.567 333.166 198.791 337.151 197.933C337.762 197.801 338.466 197.69 338.716 197.686L339.171 197.678V215.645H336.807C331.788 215.645 329.201 217.01 328.175 220.198L327.922 220.985V248.541H308.897V198.999H327.26V202.064C327.26 203.75 327.322 205.072 327.398 205.003C327.474 204.934 327.669 204.59 327.831 204.238Z" fill="black" stroke="black" stroke-width="2" class="svg-elem-13"></path>
          <path d="M343.968 223.414V207.198L340.081 207.123L339.993 198.998H343.968V191.336H363.489V198.998H368.452V207.189H363.484L363.572 239.227L363.811 239.6C364.198 240.204 364.759 240.35 366.706 240.35H368.452V248.541H361.872C350.463 248.541 347.354 247.765 345.379 244.426C343.965 242.036 343.968 242.081 343.968 223.414Z" fill="black" stroke="black" stroke-width="2" class="svg-elem-14"></path>
          <path fillRule="evenodd" clipRule="evenodd" d="M57.3248 259.512C57.3075 259.529 57.2935 264.268 57.2935 270.043V280.544H65.3698V259.48H61.3628C59.1591 259.48 57.3418 259.494 57.3248 259.512Z" fill="#0856A2" class="svg-elem-15"></path>
          <path fillRule="evenodd" clipRule="evenodd" d="M106.74 265.092C99.6185 265.565 96.0632 269.088 97.0401 274.705C97.7759 278.939 102.381 281.379 108.775 280.925C115.192 280.468 118.346 277.393 117.922 272.007C117.583 267.706 112.714 264.696 106.74 265.092ZM109.757 270.946C109.553 270.298 109.095 269.799 108.486 269.563C107.094 269.022 105.412 269.754 105.074 271.048C104.969 271.451 104.969 274.534 105.075 274.938C105.652 277.154 109.1 277.19 109.776 274.986L109.862 274.705V271.28L109.757 270.946Z" fill="#0856A2" class="svg-elem-16"></path>
          <path fillRule="evenodd" clipRule="evenodd" d="M156.137 265.076C151.032 265.434 148.555 268.636 149.273 273.949C149.752 277.496 152.555 279.608 156.786 279.609C158.817 279.609 161.118 278.687 161.414 277.755C161.429 277.707 161.472 277.667 161.508 277.667H161.575L161.546 280.381L161.419 280.656C160.788 282.022 157.29 282.022 156.925 280.656L156.869 280.447H150.105C150.105 280.447 150.016 280.768 150.105 281.473C150.418 283.946 154.744 285.783 159.912 285.638C164.766 285.502 168.115 283.769 168.925 280.976L169.008 280.69L169.033 265.447H161.71L161.687 265.546C161.674 265.6 161.619 265.907 161.564 266.228C161.494 266.639 161.469 266.825 161.399 266.844C161.338 266.861 161.242 266.755 161.054 266.562C160.042 265.532 158.084 264.94 156.137 265.076ZM161.347 272.404C161.348 270.283 161.007 269.583 159.836 269.307C158.405 268.97 157.241 269.539 156.902 270.741L156.821 271.027V273.763L156.902 274.047C157.195 275.081 157.872 275.55 159.073 275.55C160.813 275.55 161.345 274.814 161.347 272.404Z" fill="#0856A2" class="svg-elem-17"></path>
          <path d="M201.184 261.99C201.178 260.674 201.181 259.571 201.194 259.539C201.211 259.493 202.065 259.48 205.232 259.48H209.249V264.407L201.198 264.383L201.184 261.99Z" fill="#0856A2" class="svg-elem-18"></path>
          <path d="M201.174 273.046C201.174 268.922 201.188 265.534 201.204 265.517C201.221 265.5 203.038 265.485 205.242 265.485H209.249V280.544H201.174V273.046Z" fill="#0856A2" class="svg-elem-19"></path>
          <path d="M241.254 275.259C239.327 269.436 243.157 265.113 250.685 264.615C256.45 264.234 261.65 267.147 261.804 270.839L261.827 271.437L253.809 271.413L253.779 270.967C253.606 268.447 249.662 268.118 249.022 270.571C248.915 270.974 248.915 274.057 249.022 274.461C249.679 276.982 253.786 276.55 253.786 273.96V273.641H261.827L261.8 274.24C261.454 282.008 243.781 282.885 241.254 275.259Z" fill="#0856A2" class="svg-elem-20"></path>
          <path d="M292.139 270.517C291.449 267.232 294.78 264.958 300.764 264.628C307.532 264.255 312.146 266.181 312.146 269.382V269.715L304.064 269.69L304.028 269.436C304.008 269.295 303.944 269.094 303.884 268.988C303.481 268.259 301.137 268.165 300.607 268.857L300.481 269.024V269.342C300.481 270.075 300.434 270.059 304.298 270.56C310.926 271.419 312.799 272.489 312.703 275.356L312.682 275.953L312.539 276.362C311.622 278.98 307.735 280.545 302.154 280.544C295.886 280.543 291.866 278.698 291.729 275.763L291.716 275.482H300.004L300.094 275.781C300.504 277.177 304.154 277.097 304.021 275.694C303.971 275.156 303.978 275.159 300.574 274.707C294.59 273.914 292.653 272.951 292.139 270.517Z" fill="#0856A2" class="svg-elem-21"></path>
          {/* eslint-enable react/jsx-max-props-per-line, react/self-closing-comp, react/no-unknown-property */}
        </svg>

        <CustomBlock className="d-flex align-items-center justify-content-center flex-wrap gap-1">
          <p className="size-lg user-select-none fw-600" style={{ color: '#2967AB' }}>Shipment Savings, Delivered!</p>
          <BiRefresh
            size="25"
            className="grey--clr user-select-none"
            onClick={handleLogoAnimation}
            style={{ cursor: 'pointer' }}
          />
        </CustomBlock>
      </CustomBlock>
    </>
  );
};

export default AnimatedLogo;