/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-multi-spaces */
/* eslint-disable react/no-array-index-key */
// DEPENDENCIES
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
// COMPONENTS
import { Route, useHistory } from 'react-router-dom';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
// ICONS
// import { BiPencil } from 'react-icons/bi';
// import { FiLogOut } from 'react-icons/fi';
// import { FaFileInvoiceDollar } from 'react-icons/fa';
// import { CgCopy, CgWebsite } from 'react-icons/cg';
// import { RiLayout5Fill, RiListCheck2 } from 'react-icons/ri';
// import { HiChevronRight, HiMenuAlt4 } from 'react-icons/hi';
// import { BsPeopleFill, BsBuildingAdd } from 'react-icons/bs';
// import {
//   IoClose,
//   IoList,
//   IoLinkSharp
// } from 'react-icons/io5';
// ICONS
import {
  TbLayoutGrid,
  TbClipboardText,
  TbClockEdit,
  TbAlignBoxLeftBottom,
  TbFileText,
  // TbNotes,
  // TbCalendarEvent,
  // TbBuildingStore,
  // TbTools,
  TbHome,
  // TbFileInvoice,
  TbUsers,
  TbCategory,
  // TbIdBadge2,
  TbSettings,
  TbHomeShare,
  // TbGridDots,
  // TbMailForward,
  // TbCircleCheck,
  // TbBoxMultiple,
  // TbArrowRampLeft,
  // TbAlignJustified,
  // TbKey,
  // TbChartBar,
  // TbLayoutBoardSplit,
  // TbSettingsSearch,
  // TbFolders,
  TbMenu,
  TbX,
  TbAlignJustified,
  TbBoxAlignBottom,
  TbBoxAlignTopLeft,
  TbVocabulary,
  TbTextCaption,
  TbTableRow,
  TbLibrary,
  TbCalendarMonth,
  TbWorldCog
} from 'react-icons/tb';
// CUSTOM COMPONENTS;
// import MobileHeader from '../components/MobileHeader';
// import HeaderBlock from '../components/HeaderBlock';
// import Drawer from '../components/Drawer';
// import SideSheet from '../components/SideSheet';
// import ListBlock from '../components/ListBlock';
import CustomBlock from '../components/CustomBlock';
import SideNav from '../components/SideNav';
import NavBlock from '../components/NavBlock';
import TopNav from '../components/TopNav';
// ASSETS
import Icon from '../assets/client/icon.svg';
// REDUX
import * as auth from '../redux/authRedux';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { companyIconPath } = rest.cmsInfo;
  const [currentUser, setCurrentUser] = useState([]);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  // const [headerLogo, setHeaderLogo] = useState(Icon);
  // const [navigationTitleVisible, setNavigationTitleVisible] = useState(false);
  // const [subMenuVisible, setSubMenuVisible] = useState(false);
  // const [sideSheetOpen, setSideSheetOpen] = useState(false);
  const [isSideNavCollapsed, setIsSideNavCollapsed] = useState(false);
  const [isSideNavMenuTitleVisible, setIsSideNavMenuTitleVisible] = useState(true);

  const history = useHistory();
  const iconSize = 24;
  const iconSizeLarge = 30;
  const iconColor = 'primary--clr';
  const mainPage = '/users';

  useEffect(() => {
    if (!currentUser.length > 0) {
      setCurrentUser(rest.auth.user);
    }
  }, []);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const navigateToPath = (path) => {
    history.push(path);
    setTimeout(() => {
      setIsDrawerOpen(false);
    }, 250);
  };

  // const toggleDrawer = () => {
  //   setIsDrawerOpen(!isDrawerOpen);
  //   if (!isDrawerOpen) {
  //     setTimeout(() => {
  //       // setHeaderLogo(Logo);
  //     }, 500);

  //     setTimeout(() => {
  //       setNavigationTitleVisible(true);
  //     }, 300);
  //   } else {
  //     setSubMenuVisible(false);

  //     setTimeout(() => {
  //       setNavigationTitleVisible(false);
  //     }, 450);

  //     setTimeout(() => {
  //       // setHeaderLogo(Icon);
  //     }, 550);
  //   }
  // };

  // const navigateToPath = (path) => {
  //   history.push(path);
  //   setIsDrawerOpen(false);
  //   setSideSheetOpen(false);
  //   enableBodyScroll(body);
  // };

  // const toggleSideSheet = () => {
  //   setSideSheetOpen(!sideSheetOpen);
  //   if (!sideSheetOpen) {
  //     disableBodyScroll(body);
  //   } else {
  //     enableBodyScroll(body);
  //   }
  // };

  // const navigationItems = [
  //   {
  //     id: 1,
  //     iconLeft: <BsPeopleFill className={iconColor} size={iconSize} />,
  //     title: 'Users',
  //     to: '/admin/management/users',
  //     // badgeText: '',
  //     // badgeColor: '',
  //     isVisible: true,
  //     iconRightText: '',
  //     iconRightBackgroundColor: '',
  //     // buttonRightText: <></>,
  //   },
  //   {
  //     id: 3,
  //     iconLeft: <BsBuildingAdd className={iconColor} size={iconSize} />,
  //     title: 'Clients',
  //     to: '/admin/management/clients',
  //     // badgeText: '',
  //     // badgeColor: '',
  //     isVisible: true,
  //     iconRightText: '',
  //     iconRightBackgroundColor: '',
  //     // buttonRightText: <></>,
  //   },
  //   {
  //     id: 3,
  //     iconLeft: <FaFileInvoiceDollar className={iconColor} size={iconSize} />,
  //     title: 'Bids',
  //     to: '/admin/management/parcel-shipping-analysis',
  //     // badgeText: '',
  //     // badgeColor: '',
  //     isVisible: true,
  //     iconRightText: '',
  //     iconRightBackgroundColor: '',
  //     // buttonRightText: <></>,
  //   },
  //   {
  //     id: 2,
  //     iconLeft: <RiLayout5Fill className={iconColor} size={iconSize} />,
  //     title: 'Website Management',
  //     // to: '#',
  //     action: () => setSideSheetOpen(!sideSheetOpen),
  //     badgeText: '',
  //     badgeColor: '',
  //     isVisible: rest.auth.isAdmin,
  //     iconRightText: '',
  //     iconRightBackgroundColor: '',
  //     // buttonRightText: <></>,
  //   },
  // ];

  // const footerItems = [
  //   // {
  //   //   id: 0,
  //   //   iconLeft: <FiSettings className={iconColor} size={iconSize} />,
  //   //   title: 'Settings',
  //   //   iconRightText: '',
  //   //   iconRightBackgroundColor: '',
  //   //   action: () => setSideSheetOpen(!sideSheetOpen),
  //   // },
  //   {
  //     id: 1,
  //     iconLeft: <FiLogOut className={iconColor} size={iconSize} />,
  //     title: 'Logout',
  //     iconRightText: '',
  //     iconRightBackgroundColor: '',
  //     action: () => {
  //       rest.logout();
  //     },
  //   },
  // ];

  // const websiteManagementItems = [
  //   {
  //     id: 0,
  //     title: 'Menu Items',
  //     to: '/cms/management/menu-items',
  //     action: () => { },
  //     iconLeft: <IoList className={menuIconColor} size={iconSize} />,
  //   },
  //   {
  //     id: 1,
  //     title: 'Pages',
  //     to: '/cms/management/pages',
  //     action: () => { },
  //     iconLeft: <CgCopy className={menuIconColor} size={iconSize} />,
  //   },
  //   // {
  //   //   id: 2,
  //   //   title: 'Events',
  //   //   to: '/cms/management/events',
  //   //   action: () => { },
  //   //   iconLeft: <BsCalendarEvent className={menuIconColor} size={iconSize} />,
  //   // },
  //   {
  //     id: 3,
  //     title: 'Content Sections',
  //     to: '/cms/management/content-sections',
  //     action: () => { },
  //     iconLeft: <RiListCheck2 className={menuIconColor} size={iconSize} />,
  //   },
  //   {
  //     id: 4,
  //     title: 'Assets, Links and Resources',
  //     to: '/cms/management/assets-and-resources',
  //     action: () => { },
  //     iconLeft: <IoLinkSharp className={menuIconColor} size={iconSize} />,
  //   },
  //   {
  //     id: 5,
  //     title: 'Footer Items',
  //     to: '/cms/management/footer-items',
  //     action: () => { },
  //     iconLeft: <CgWebsite className={menuIconColor} size={iconSize} />,
  //   },
  // ];
  const topNavMenuItems = [
    {
      id: 0,
      name: 'Dashboard',
      to: '/admin/management/parcel-shipping-analysis',
      onClick: null,
      iconLeft: <TbHome className={iconColor} size={iconSize} />,
      submenuItems: null,
      // isVisible: rest.auth.accessLevel >= 40
    },
    {
      id: 1,
      name: 'Clients',
      to: '/admin/management/clients',
      onClick: null,
      iconLeft: <TbHome className={iconColor} size={iconSize} />,
      submenuItems: null,
      // isVisible: rest.auth.accessLevel >= 40
    },
    {
      id: 2,
      name: 'Website',
      to: null,
      onClick: null,
      iconLeft: <TbCategory className={iconColor} size={iconSize} />,
      submenuItems: [
        {
          id: 0,
          name: 'Go to Homepage',
          to: null,
          onClick: () => {
            window.open('/home', '_blank');
          },
          iconLeft: <TbHomeShare size={22} className={iconColor} />
        },
        {
          id: 1,
          name: 'Menu Items',
          to: '/cms/management/menu-items',
          onClick: null,
          iconLeft: <TbAlignJustified size={22} className={iconColor} />
        },
        {
          id: 2,
          name: 'Pages',
          to: '/cms/management/pages',
          onClick: null,
          iconLeft: <TbLibrary size={22} className={iconColor} />
        },
        // {
        //   id: 3,
        //   name: 'Events',
        //   to: '/cms/management/events',
        //   onClick: null,
        //   iconLeft: <TbCalendarMonth size={22} className={iconColor} />
        // },
        {
          id: 4,
          name: 'Content Sections',
          to: '/cms/management/content-sections',
          onClick: null,
          iconLeft: <TbTableRow size={22} className={iconColor} />
        },
        {
          id: 5,
          name: 'Resources',
          to: '/cms/management/assets-and-resources',
          onClick: null,
          iconLeft: <TbVocabulary size={22} className={iconColor} />
        },
        {
          id: 6,
          name: 'Footer Items',
          to: '/cms/management/footer-items',
          onClick: null,
          iconLeft: <TbBoxAlignBottom size={22} className={iconColor} />
        },
      ]
      // isVisible: rest.auth.accessLevel >= 40
    },
    {
      id: 3,
      name: 'Settings',
      to: '',
      onClick: null,
      iconLeft: <TbSettings className={iconColor} size={iconSize} />,
      // isVisible: rest.auth.accessLevel >= 40,
      submenuItems: [
        {
          id: 0,
          name: 'Users',
          to: '/admin/management/users',
          iconLeft: <TbUsers size={22} className={iconColor} />
        },
        ...((currentUser.id === 1 && currentUser.username === 'admin') ? [{
          id: 1,
          name: 'Website',
          to: '/admin/management/settings',
          iconLeft: <TbWorldCog size={22} className={iconColor} />,
        }] : []),
      ]
    },
  ];

  const sideNavMenuItems = [
    {
      id: 0,
      iconLeft: <TbLayoutGrid className={iconColor} size={iconSize} />,
      name: 'Dashboard',
      link: '/dashboard/',
      isVisible: true
    },
    {
      id: 0,
      iconLeft: <TbClipboardText className={iconColor} size={iconSize} />,
      name: 'Sign-In Sheet',
      link: '/sign-in-sheet/',
      isVisible: true
    },
    {
      id: 1,
      iconLeft: <TbClockEdit className={iconColor} size={iconSize} />,
      name: 'Timesheet',
      link: '/time-sheet/',
      isVisible: rest.auth.accessLevel >= 40
    },
    {
      id: 2,
      iconLeft: <TbAlignBoxLeftBottom className={iconColor} size={iconSize} />,
      name: 'Status Log',
      link: '/status-log/',
      isVisible: true
    },
    {
      id: 3,
      iconLeft: <TbFileText className={iconColor} size={iconSize} />,
      name: 'Force Report',
      link: '/force-report/',
      isVisible: true
    },
  ];

  return (
    <Route
      {...rest} render={(props) => (
        <>
          <TopNav
            hasReturnAction
            hasReturnActionBackground
            hasNavigation
            hasHeaderTitle
            icon={Icon}
            iconTo={mainPage}
            headerTitle={rest.title}
            routePath={rest.path}
            userInitials="JS"
            // userInitials={`${rest.auth.user.firstName[0]}${rest.auth.user.lastName[0]}`}
            // menuItems={topNavMenuItems.filter((x) => x.isVisible)}
            menuItems={topNavMenuItems}
            onClickIcon={() => {
              history.push('/dashboard');
            }}
            menuToggleIcon={
              isDrawerOpen
                ? (
                  <TbX
                    size={iconSizeLarge} className="danger--clr"
                    strokeWidth="3"
                  />
                ) : (
                  <TbMenu
                    size={iconSizeLarge} className="dark-blue--clr"
                    strokeWidth="3"
                  />
                )
            }
            secondaryIconActionOnClick={() => {
              rest.logout();
            }}
            onClickAvatar={() => {
              history.push('/my-profile');
            }}
            onClick={() => {
              toggleDrawer();
            }}
          />
          {/* <MobileHeader
            hasBottomShadow
            // leftItemImageSource={Icon}
            leftItemImageSource={companyIconPath}
            leftItemImageTo="/cms/management/pages"
            rightItem={<HiMenuAlt4 className="white--clr" size={35} />}
            rightItemOnClick={() => toggleDrawer()}
          /> */}

          {/* <Drawer
              hasShadow
              isOpen={isDrawerOpen}
              // headerLogo={headerLogo}
              headerLogo={companyIconPath}
              mobileLogo={companyIconPath}
              // mobileLogo={Logo}
              backgroundColor="white--sbg"
              hasOverlay
              willCloseOnMaskPress
              closeActionOnClick={() => {
                toggleDrawer();
              }}
              navigationItems={
                navigationItems.filter((x) => x.isVisible).map((item, index) => (
                  <ListBlock
                    key={index}
                    title={navigationTitleVisible ? item.title : ''}
                    tooltipText={!navigationTitleVisible ? item.title : ''}
                    badgeColor={item.badgeColor}
                    onClick={item.to ? () => navigateToPath(item.to) : item.action}
                    isCollapsed={!navigationTitleVisible}
                    iconLeft={item.iconLeft}
                    iconRight={navigationTitleVisible && item.iconRight}
                    iconRightText={navigationTitleVisible ? item.iconRightText : ''}
                    iconLeftBadgeText={!navigationTitleVisible ? item.iconRightText : ''}
                    iconRightBackgroundColor={item.iconRightBackgroundColor}
                    hasRoundIconRight
                    rotateIconRight={subMenuVisible}
                    backgroundColor={rest.path === item.to ? 'secondary-lighter--sbg' : 'lighter-grey--sbg'}
                    subMenuItems={subMenuVisible && item.hasSubMenu}
                  />
                ))
              }

              footerItems={
                footerItems.map((item, index) => (
                  <ListBlock
                    key={index}
                    title={navigationTitleVisible ? item.title : ''}
                    tooltipText={!navigationTitleVisible ? item.title : ''}
                    badgeText={item.badgeText}
                    badgeColor={item.badgeColor}
                    onClick={item.to ? () => navigateToPath(item.to) : item.action}
                    isCollapsed={!navigationTitleVisible}
                    iconLeft={item.iconLeft}
                    iconRight={navigationTitleVisible && item.iconRight}
                    iconRightText={navigationTitleVisible ? item.iconRightText : ''}
                    iconLeftBadgeText={!navigationTitleVisible ? item.iconRightText : ''}
                    iconRightBackgroundColor={item.iconRightBackgroundColor}
                    hasRoundIconRight
                  />
                ))
              }
            /> */}

          {/* MANAGEMENT PAGES SIDESHEET */}
          {/* <SideSheet
              isOpen={sideSheetOpen}
              closeActionOnClick={() => toggleSideSheet()}
            >
              <CustomBlock className="side-sheet-menu">
                <CustomBlock className="close-action-container">
                  <button className="close-action" onClick={() => toggleSideSheet()}>
                    <IoClose className={menuIconColor} size={22} />
                  </button>
                </CustomBlock>

                <CustomBlock className="header-items-container">
                  <p className="header-title">Website Management</p>
                </CustomBlock>
                <CustomBlock className="menu-items-container">
                  {
                    websiteManagementItems.map((item, index) => (
                      <ListBlock
                        key={index}
                        title={item.title}
                        titleColor="dark-grey--clr"
                        iconLeft={item.iconLeft}
                        backgroundColor={rest.path === item.to ? 'light-grey--sbg' : 'light--sbg'}
                        iconRight={<HiChevronRight className={menuIconColor} size={iconRightSize} />}
                        onClick={item.to ? () => navigateToPath(item.to) : item.action}
                      />
                    ))
                  }
                </CustomBlock>
              </CustomBlock>
            </SideSheet> */}

          {/* <CustomBlock className="container-wrapper">
              <HeaderBlock
                hasBottomShadow
                // hasReturnAction
                leftItemText={rest.title}
                primaryButtonText="Go to Homepage"
                primaryButtonOnClick={() => {
                  window.open('/home', '_blank');
                }}
                rightItemPrimaryButtonColor="secondary--bg"
                rightItemPrimaryButtonOnClick={() => { }}
                rightItemSecondaryButtonText=""
                rightItemSecondaryButtonColor="primary--bg"
              />

              <CustomBlock className="main-container">
                <Component {...props} />
              </CustomBlock>

            </CustomBlock> */}
          <CustomBlock className="content-wrapper">
            {/* <SideNav
              isCollapsible
              isExpanded={!isSideNavCollapsed}
              onClick={() => {
                setIsSideNavCollapsed(!isSideNavCollapsed);

                if (isSideNavCollapsed) {
                  setTimeout(() => {
                    setIsSideNavMenuTitleVisible(true);
                  }, 200);
                } else {
                  setIsSideNavMenuTitleVisible(false);
                }
              }}
            >
              {
                sideNavMenuItems.filter((x) => x.isVisible).map((item, index) => (
                  <NavBlock
                    isCollapsed={isSideNavCollapsed}
                    tooltipText={item.name}
                    key={index}
                    title={isSideNavMenuTitleVisible ? item.name : ''}
                    titleColor={item.link.includes(rest.path) ? 'info--clr' : 'dark-blue--clr'}
                    backgroundColor={item.link.includes(rest.path) ? 'lighter-blue--sbg' : 'white--sbg'}
                    iconLeft={item.iconLeft}
                    onClick={() => {
                      navigateToPath(`${item.link}`);
                    }}
                  />
                ))
              }
            </SideNav> */}

            <CustomBlock className="component-wrapper">
              <Component {...props} />
            </CustomBlock>
          </CustomBlock>
        </>
      )}
    />
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(mapStateFromProps, { ...auth.actions })(PrivateRoute);