/* eslint-disable max-len */
// DEPENDENCIES
import React from 'react';
import { useHistory } from 'react-router-dom';
// COMPONENTS
import PropTypes from 'prop-types';
// ICONS
import {
  TbSettings,
  TbBellRinging2,
  // TbInfoCircle,
  TbLogout,
  // TbMenu
  // TbChevronLeft
} from 'react-icons/tb';
import { HiChevronLeft, HiChevronDown } from 'react-icons/hi';
// CUSTOM COMPONENTS
import CustomBlock from './CustomBlock';
import Button from './Button';
import NavBlock from './NavBlock';
import Image from './Image';

const TopNav = (props) => {
  const {
    backgroundColor,
    icon,
    iconTo,
    headerTitle,
    headerTitleColor,
    returnIconColor,
    menuItems,
    menuItemTextColor,
    menuToggleIcon,
    activeMenuItemBackgroundColor,
    routePath,
    primaryButtonActionText,
    userInitials,
    userAvatarBackgroundColor,
    primaryIconActionOnClick,
    secondaryIconActionOnClick,
    optionalIconActionOnClick,
    hasNavigation,
    hasReturnAction,
    hasReturnActionText,
    hasReturnActionBackground,
    hasHeaderTitle,
    onClickAvatar,
    onClickIcon,
    onClick,
  } = props;

  const history = useHistory();
  const actionIconSize = 25;
  const iconSize = 30;

  const navigateToPath = (path) => {
    history.push(path);
  };

  return (
    <CustomBlock className="top-nav-container">
      {/* MAIN NAVIGATION */}
      <CustomBlock className={`top-nav ${backgroundColor}`}>
        <CustomBlock className="logo-block">
          {
            hasReturnAction
            && (
              <button
                className={`return-action-block ${hasReturnActionBackground && 'has-background light-grey--sbg'} ${!hasReturnActionText && 'icon-only'}`}
                onClick={() => {
                  history.goBack();
                }}
              >
                <HiChevronLeft className={returnIconColor} size={iconSize} />
                {hasReturnActionText && <p className={`return-action-text ${returnIconColor}`}>Back</p>}
              </button>
            )
          }
          {
            icon
            && (
              <CustomBlock
                className={`icon ${hasReturnAction ? 'ml-15' : ''} ${onClickIcon ? 'cursor--pointer' : ''}`}
                onClick={onClickIcon}
              >
                <Image
                  source={icon}
                />
              </CustomBlock>
            )
          }
          {(hasHeaderTitle) && <p className={`header-title ${headerTitleColor}`}>{headerTitle}</p>}
        </CustomBlock>

        {
          hasNavigation
          && (
            <CustomBlock className="menu-items-block">
              <ul className="menu-items">
                {
                  menuItems.map((menuItem) => (
                    menuItem.submenuItems
                      ? (
                        <li className="has-submenu">
                          <Button
                            text={menuItem.name}
                            onClick={menuItem.to ? () => navigateToPath(menuItem.to) : menuItem.onClick}
                            className={`menu-item ${menuItemTextColor}`}
                            iconRight={<HiChevronDown />}
                          />
                          <CustomBlock className="submenu-block white--sbg">
                            <ul className="submenu-items-block">
                              {
                                menuItem.submenuItems.map((submenuItem) => (
                                  <li>
                                    <NavBlock
                                      title={submenuItem.name}
                                      to={submenuItem.to}
                                      onClick={submenuItem.onClick}
                                      iconLeft={submenuItem.iconLeft}
                                      className="grey--bg"
                                    />
                                  </li>
                                ))
                              }
                            </ul>
                          </CustomBlock>
                        </li>
                      ) : (
                        <li>
                          <Button
                            text={menuItem.name}
                            className={`menu-item ${menuItem.to === routePath ? 'primary--clr' : menuItemTextColor} ${menuItem.to === routePath ? activeMenuItemBackgroundColor : ''}`}
                            onClick={menuItem.to ? () => navigateToPath(menuItem.to) : menuItem.onClick}
                          />
                        </li>
                      )
                  ))
                }
              </ul>
            </CustomBlock>
          )
        }

        <CustomBlock className="actions-block">
          {primaryButtonActionText}

          <CustomBlock className="action-icons">
            {
              optionalIconActionOnClick !== null
              && (
                <button
                  aria-label="action-icon"
                  onClick={optionalIconActionOnClick}
                  className="action-icon"
                >
                  <TbBellRinging2 className={menuItemTextColor} size={actionIconSize} />
                </button>
              )
            }

            {
              secondaryIconActionOnClick
              && (
                <button
                  aria-label="action-icon"
                  onClick={secondaryIconActionOnClick}
                  className="action-icon"
                >
                  <TbLogout className={menuItemTextColor} size={actionIconSize} />
                </button>
              )
            }

            {
              primaryButtonActionText
              && (
                <button
                  aria-label="action-icon"
                  onClick={primaryIconActionOnClick}
                  className="action-icon"
                >
                  <TbSettings className={menuItemTextColor} size={actionIconSize} />
                </button>
              )
            }
          </CustomBlock>

          <button
            onClick={onClickAvatar}
            className={`user-avatar ${userAvatarBackgroundColor}`}
          >
            <p className="user-initials">{userInitials}</p>
          </button>
        </CustomBlock>
      </CustomBlock>

      {/* MOBILE NAVIGATION */}
      <CustomBlock className="mobile-nav">
        <CustomBlock className="logo-block">
          {
            icon
            && (
              <CustomBlock className="icon">
                <Image
                  source={icon}
                  to={iconTo}
                />
              </CustomBlock>
            )
          }
        </CustomBlock>

        <CustomBlock className="actions-block">
          <CustomBlock className="action-icons">
            <button
              onClick={onClickAvatar}
              className={`user-avatar ${userAvatarBackgroundColor}`}
            >
              <p className="user-initials">{userInitials}</p>
            </button>

            <button
              onClick={onClick}
              className="action-icon"
            >
              {menuToggleIcon}
            </button>
          </CustomBlock>

        </CustomBlock>
      </CustomBlock>
    </CustomBlock>
  );
};

// PROPS
TopNav.propTypes = {
  // MAIN PROPS
  backgroundColor: PropTypes.string,
  icon: PropTypes.any,
  iconTo: PropTypes.string,
  headerTitle: PropTypes.string,
  headerTitleColor: PropTypes.string,
  returnIconColor: PropTypes.string,
  menuItems: PropTypes.any,
  menuItemTextColor: PropTypes.string,
  menuToggleIcon: PropTypes.any,
  activeMenuItemBackgroundColor: PropTypes.string,
  routePath: PropTypes.string,

  // ACTION PROPS
  primaryButtonActionText: PropTypes.string,
  userInitials: PropTypes.string,
  userAvatarBackgroundColor: PropTypes.string,

  // FUNCTION PROPS
  primaryIconActionOnClick: PropTypes.func,
  secondaryIconActionOnClick: PropTypes.func,
  optionalIconActionOnClick: PropTypes.func,
  onClickAvatar: PropTypes.func,
  onClickIcon: PropTypes.func,
  onClick: PropTypes.func,

  // BOOLEAN PROPS
  hasNavigation: PropTypes.bool,
  hasReturnAction: PropTypes.bool,
  hasReturnActionText: PropTypes.bool,
  hasReturnActionBackground: PropTypes.bool,
  hasHeaderTitle: PropTypes.bool,
};

// DEFAULT PROPS
TopNav.defaultProps = {
  // MAIN PROPS
  backgroundColor: 'white--sbg',
  icon: null,
  iconTo: '#',
  headerTitle: null,
  headerTitleColor: 'secondary--clr',
  returnIconColor: 'mid-grey--clr',
  menuItems: null,
  menuItemTextColor: 'dark-blue--clr',
  menuToggleIcon: null,
  // activeMenuItemBackgroundColor: 'lighter-blue--sbg info--clr',
  activeMenuItemBackgroundColor: 'primary-lighter--sbg primary--clr',
  routePath: '',

  // ACTION PROPS
  primaryButtonActionText: '',

  // USER PROPS
  userInitials: '',
  userAvatarBackgroundColor: 'secondary--sbg',

  // FUNCTION PROPS
  primaryIconActionOnClick: () => {},
  secondaryIconActionOnClick: () => {},
  optionalIconActionOnClick: null,
  onClickAvatar: null,
  onClickIcon: null,
  onClick: null,

  // BOOLEAN PROPS
  hasNavigation: false,
  hasReturnAction: false,
  hasReturnActionText: false,
  hasReturnActionBackground: false,
  hasHeaderTitle: false,
};

export default TopNav;