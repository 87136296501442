/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { Fade } from 'react-awesome-reveal';
import CustomBlock from './CustomBlock';
import TextBlock from './TextBlock';
import Button from './Button';

const ContentSection = (props) => {
  const {
    backgroundColor,
    title,
    titleColor,
    description,
    descriptionColor,
    cardBackgroundColor,
    imageSource,
    primaryActionText,
    primaryActionColor,
    primaryActionTo,
    primaryActionOnClick,
    secondaryActionText,
    secondaryActionColor,
    secondaryActionTo,
    secondaryActionOnClick,
    isPrimaryActionTargetBlank,
    isSecondaryActionTargetBlank,
    isReversed,
    isCentered,
    isTitleUppercase,
    hasRoundCorners,
    hasCardBackgroundImage,
    isTextOnly,
    isFloatingCard,
    isFullWidth,
    isFullWidthUnpadded,
  } = props;

  const openInNewTab = (link) => {
    window.open(link, '_blank');
  };

  const renderImage = () => {
    if (isTextOnly || isFloatingCard) return null;

    return (
      <CustomBlock className="image-block">
        <CustomBlock
          className={`content-image ${hasRoundCorners ? 'round-corners' : ''}`}
          style={{ backgroundImage: `url(${imageSource})` }}
        />
      </CustomBlock>
    );
  };

  const renderActions = () => {
    if (!primaryActionText && !secondaryActionText) return null;

    return (
      <CustomBlock className={`actions ${isCentered ? 'centered' : ''}`}>
        {primaryActionText && (
          <Fade direction="right" delay={500}>
            <Button
              text={primaryActionText}
              className={`${primaryActionColor} ${hasRoundCorners ? 'round-corners' : ''}`}
              to={!isPrimaryActionTargetBlank ? primaryActionTo : ''}
              onClick={isPrimaryActionTargetBlank ? () => openInNewTab(primaryActionTo) : primaryActionOnClick}
            />
          </Fade>
        )}
        {secondaryActionText && (
          <Fade direction="right" delay={800}>
            <Button
              text={secondaryActionText}
              className={`${secondaryActionColor} ${hasRoundCorners ? 'round-corners' : ''}`}
              to={!isSecondaryActionTargetBlank ? secondaryActionTo : ''}
              onClick={isSecondaryActionTargetBlank ? () => openInNewTab(secondaryActionTo) : secondaryActionOnClick}
            />
          </Fade>
        )}
      </CustomBlock>
    );
  };

  const getDetailBlockClassName = () => {
    let className = 'content-section flex-column align-items-start details-block';
    if (isTextOnly) className += ' text-only';
    if (isFloatingCard) {
      className += ' text-only floating-card';
      if (hasRoundCorners) className += ' round-corners';
    }
    if (isFloatingCard && cardBackgroundColor) className += ` ${cardBackgroundColor}`;
    if (hasCardBackgroundImage) className += ' card-background-image';
    return className;
  };

  const getDetailBlockStyle = () => {
    if (hasCardBackgroundImage && !cardBackgroundColor) {
      return { backgroundImage: `url(${imageSource})` };
    }
    return {};
  };

  const contentSectionClass = [
    'content-section',
    isFullWidth ? 'full-width' : '',
    isFullWidthUnpadded ? 'unpad' : '',
    isReversed ? 'reverse-content' : '',
    backgroundColor
  ].filter(Boolean).join(' ').trim();

  return (
    <CustomBlock className={contentSectionClass}>
      {renderImage()}
      <CustomBlock
        className={getDetailBlockClassName()}
        style={getDetailBlockStyle()}
      >
        <Fade>
          <TextBlock
            isPadded
            isUppercase={isTitleUppercase}
            type="header"
            text={title}
            color={titleColor}
            position={isReversed ? 'right' : (isCentered ? 'center' : 'left')}
          />
        </Fade>
        <Fade delay={300}>
          <TextBlock
            type="description"
            text={description}
            color={descriptionColor}
            position={isReversed ? 'right' : (isCentered ? 'center' : 'left')}
          />
        </Fade>
        {renderActions()}
      </CustomBlock>
    </CustomBlock>
  );
};

ContentSection.propTypes = {
  backgroundColor: PropTypes.string,
  cardBackgroundColor: PropTypes.string,
  imageSource: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  titleColor: PropTypes.string,
  description: PropTypes.string,
  descriptionColor: PropTypes.string,
  primaryActionText: PropTypes.string,
  primaryActionColor: PropTypes.string,
  primaryActionTo: PropTypes.string,
  primaryActionOnClick: PropTypes.func,
  secondaryActionText: PropTypes.string,
  secondaryActionColor: PropTypes.string,
  secondaryActionTo: PropTypes.string,
  secondaryActionOnClick: PropTypes.func,
  isPrimaryActionTargetBlank: PropTypes.bool,
  isSecondaryActionTargetBlank: PropTypes.bool,
  isReversed: PropTypes.bool,
  isCentered: PropTypes.bool,
  isTitleUppercase: PropTypes.bool,
  hasRoundCorners: PropTypes.bool,
  hasCardBackgroundImage: PropTypes.bool,
  isTextOnly: PropTypes.bool,
  isFloatingCard: PropTypes.bool,
  isFullWidth: PropTypes.bool,
  isFullWidthUnpadded: PropTypes.bool,
};

ContentSection.defaultProps = {
  backgroundColor: '',
  cardBackgroundColor: '',
  titleColor: 'primary--clr',
  description: '',
  descriptionColor: 'dark--clr',
  primaryActionText: '',
  primaryActionColor: 'primary--bg',
  primaryActionTo: '',
  primaryActionOnClick: null,
  secondaryActionText: '',
  secondaryActionColor: 'secondary--bg',
  secondaryActionTo: '',
  secondaryActionOnClick: null,
  isPrimaryActionTargetBlank: false,
  isSecondaryActionTargetBlank: false,
  isReversed: false,
  isCentered: false,
  isTitleUppercase: false,
  hasRoundCorners: false,
  hasCardBackgroundImage: false,
  isTextOnly: false,
  isFloatingCard: false,
  isFullWidth: false,
  isFullWidthUnpadded: false,
};

export default ContentSection;