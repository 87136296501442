/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
/* eslint-disable no-use-before-define */
// DEPENDENCIES
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
// COMPONENTS
import MUIDataTable from 'mui-datatables';
import {
  DragDropContext,
  Droppable,
  Draggable
} from 'react-beautiful-dnd';
// ICONS
// CUSTOM COMPONENTS
import Overlay from '../../../../components/Overlay';
import Section from '../../../../components/Section';
import ContentBlock from '../../../../components/ContentBlock';
import CustomBlock from '../../../../components/CustomBlock';
import ContentHeader from '../../../../components/ContentHeader';
import ModalBlock from '../../../../components/ModalBlock';
import Button from '../../../../components/Button';
// SERVICES AND HELPERS
import * as menuItemService from '../../../../services/cms/menuItemService';
import * as helper from '../../../../helpers/helper';
// REDUX
import * as alert from '../../../../redux/alertToastRedux';
import * as confirmModal from '../../../../redux/confirmModalRedux';

const MenuItemListingPage = (props) => {
  const { showAlert, showConfirmModal, hideConfirmModal } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [isSortModalOpen, setIsSortModalOpen] = useState(false);
  const [menuItems, setMenuItems] = useState([]);
  const [sortList, setSortList] = useState([]);
  // const iconSize = 22;
  // const iconColor = 'white--clr';

  useEffect(() => {
    getMenuItems();
  }, []);

  useEffect(() => {
    setSortList(menuItems.sort((a, b) => a - b));
  }, [menuItems]);

  const getMenuItems = () => {
    setIsLoading(true);
    menuItemService.getAllMenuItems('').then((res) => {
      setMenuItems(res);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const deleteMenuItem = (menuItemId) => {
    setIsLoading(true);

    menuItemService.deleteMenuItem(menuItemId).then((res) => {
      getMenuItems();
      showAlert({ text: res.message, state: 'success' });
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
      hideConfirmModal();
    });
  };

  const sortMenuItems = () => {
    setIsLoading(true);

    menuItemService.sortMenuItems(sortList).then((res) => {
      getMenuItems();
      showAlert({ text: res.message, state: 'success' });
      setIsSortModalOpen(false);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const tempArr = helper.reOrderDnd(
      sortList,
      result.source.index,
      result.destination.index
    );

    setSortList(tempArr);
  };

  const columnOptions = {
    filter: true,
    sort: true,
    print: false,
    download: true
  };

  const options = {
    selectableRows: 'none',
    download: true,
    print: false,
    jumpToPage: true,
    textLabels: { pagination: { jumpToPage: 'Page No:' } }
  };

  const columns = [
    {
      name: 'id',
      label: 'Actions',
      options: {
        filter: false,
        sort: false,
        print: false,
        download: false,
        customBodyRenderLite: (dataIndex) => (
          <CustomBlock className="content-container--actions flex-start pl-0 mt-0">
            <Button
              text="View"
              className="primary--bg mr-5"
              size="xxs"
              to={`/cms/management/menu-item/edit/${menuItems[dataIndex].id}`}
            />

            <Button
              text="Delete"
              className="danger--bg"
              size="xxs"
              onClick={() => {
                showConfirmModal({
                  title: 'Remove Menu Item',
                  text: 'Are you sure you want to remove this menu item?',
                  rightBtnText: 'Confirm',
                  btnAction: () => {
                    deleteMenuItem(menuItems[dataIndex].id);
                  }
                });
              }}
            />
          </CustomBlock>
        )
      }
    },
    {
      name: 'name',
      label: 'Menu Item Name',
      options: columnOptions,
    },
    {
      name: 'pageName',
      label: 'Assigned Page',
      options: columnOptions,
    },
    {
      name: 'parentMenuItem',
      label: 'Parent Menu Item',
      options: columnOptions,
    },
    {
      name: 'openInNewTab',
      label: 'Will Open In A New Tab?',
      options: {
        ...columnOptions,
        customBodyRenderLite: (dataIndex) => (
          <p>{menuItems[dataIndex].openInNewTab ? 'Yes' : 'No'}</p>
        )
      }
    },
    {
      name: 'isActive',
      label: 'Is Active?',
      options: {
        ...columnOptions,
        customBodyRenderLite: (dataIndex) => (
          <p>{menuItems[dataIndex].isActive ? 'Yes' : 'No'}</p>
        )
      }
    },
  ];

  return (
    <>
      {isLoading && <Overlay hasLoader />}
      <CustomBlock className="content-container--padded">
        <Section isFullWidth>
          <ContentBlock>
            <CustomBlock className="content-container--card-style--with-shadow">
              <ContentHeader
                title="Menu Items"
                headerSize="lg"
                primaryButtonText="New Menu Item"
                primaryButtonTo="/cms/management/menu-item"
                secondaryButtonText="Sort Items"
                secondaryButtonOnClick={() => setIsSortModalOpen(true)}
              />

              <CustomBlock>
                <MUIDataTable
                  data={menuItems}
                  columns={columns}
                  options={options}
                />
              </CustomBlock>
            </CustomBlock>
          </ContentBlock>
        </Section>
      </CustomBlock>

      {/* SORT MODAL */}
      <ModalBlock
        hasCloseAction
        isVisible={isSortModalOpen}
        size="md"
        contentHeader="Sort Sections"
        primaryModalActionText="Sort"
        primaryModalActionOnClick={sortMenuItems}
        onHide={() => {
          setIsSortModalOpen(false);
        }}
      >
        <Section hasNoContainer>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(droppableProvided) => (
                <div
                  ref={droppableProvided.innerRef}
                >
                  {sortList.map((item, index) => (
                    <Draggable
                      key={index} draggableId={`${index}`}
                      index={index}
                    >
                      {(draggableProvided) => (
                        <div
                          key={index}
                          ref={draggableProvided.innerRef}
                          {...draggableProvided.draggableProps}
                          {...draggableProvided.dragHandleProps}
                          className="content-container--card-style light-grey--sbg pt-15 pb-15 pl-20 pr-20 mb-15"
                        >
                          <p className="main-font fw-500">{item.name}</p>
                        </div>

                      )}
                    </Draggable>
                  ))}
                  {droppableProvided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Section>
      </ModalBlock>
    </>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });
export default connect(mapStateFromProps, { ...alert.actions, ...confirmModal.actions })(MenuItemListingPage);