/* eslint-disable no-use-before-define */
/* eslint-disable no-continue */
// DEPENDENCIES
import React, { useEffect, useState } from 'react';
import { useWizard } from 'react-use-wizard';
import readXlsxFile from 'read-excel-file';
import ReactTable from 'react-table';
import moment from 'moment';
import * as _ from 'lodash';
// CUSTOM COMPONENTS
import Overlay from '../../../../../components/Overlay';
import ContentBlock from '../../../../../components/ContentBlock';
import Button from '../../../../../components/Button';
import Section from '../../../../../components/Section';
import TextHeader from '../../../../../components/TextHeader';
// SERVICES AND HELPERS
import * as bidItemService from '../../../../../services/management/bidService';

const Step2LineItemListingWizard = (props) => {
  const {
    bidItemColumns,
    excelFileUpload,
    type,
    setIsLoading,
    handleModalClose,
    bidId,
    showAlert,
    fetchBid,
    isLoading
  } = props;
  const [tempExcelJson, setTempExcelJson] = useState([]);
  const { previousStep } = useWizard();

  useEffect(() => {
    readExcelFile();
  }, []);

  const readExcelFile = () => {
    setIsLoading(true);
    readXlsxFile(excelFileUpload[0].file).then((res) => {
      if (type === 'UPS') {
        fetchValuesForUps(res);
      } else {
        fetchValuesForFedEx(res);
      }
      setIsLoading(false);
    });
  };

  const fetchValuesForFedEx = (excelJson) => {
    const trackingNumberIndex = excelJson[0].indexOf('Express or Ground Tracking ID');
    const invoiceDateIndex = excelJson[0].indexOf('Invoice Date');
    const invoiceNumberIndex = excelJson[0].indexOf('Invoice Number');
    const serviceTypeIndex = excelJson[0].indexOf('Service Type');
    const actualWeightAmountIndex = excelJson[0].indexOf('Actual Weight Amount');
    const ratedWeightAmountIndex = excelJson[0].indexOf('Rated Weight Amount');
    const lengthIndex = excelJson[0].indexOf('Dim Length');
    const widthIndex = excelJson[0].indexOf('Dim Width');
    const heightIndex = excelJson[0].indexOf('Dim Height');
    const divisorIndex = excelJson[0].indexOf('Dim Divisor');
    const zoneCodeIndex = excelJson[0].indexOf('Zone Code');
    const multiWeightNumberIndex = excelJson[0].indexOf('Multiweight Number');
    const transportationChargeAmountIndex = excelJson[0].indexOf('Transportation Charge Amount');
    const netChargeAmountIndex = excelJson[0].indexOf('Net Charge Amount');
    const startIndexForTrackingID = excelJson[0].indexOf('Tracking ID Charge Description');
    let accesoryCharges = [];

    const tempArr = [];
    for (let i = 1; i < excelJson.length; i += 1) {
      if (String(excelJson[i][invoiceNumberIndex]).includes('Total')) {
        continue;
      }
      const invoiceDate = String(excelJson[i][invoiceDateIndex]);

      const fedExObj = {
        bidId,
        trackingNumber: excelJson[i][trackingNumberIndex],
        invoiceDate: invoiceDate.length >= 7 ? `${invoiceDate.substr(0, 4)}/${invoiceDate.substr(4, 2)}/${invoiceDate.substr(6, 2)} ` : '',
        invoiceNumber: excelJson[i][invoiceNumberIndex],
        serviceType: excelJson[i][serviceTypeIndex],
        actualWeightAmount: excelJson[i][actualWeightAmountIndex],
        ratedWeightAmount: excelJson[i][ratedWeightAmountIndex],
        length: excelJson[i][lengthIndex],
        width: excelJson[i][widthIndex],
        height: excelJson[i][heightIndex],
        divisor: excelJson[i][divisorIndex],
        zoneCode: excelJson[i][zoneCodeIndex],
        multiWeightNumber: excelJson[i][multiWeightNumberIndex],
        transportationChargeAmount: excelJson[i][transportationChargeAmountIndex],
        netChargeAmount: excelJson[i][netChargeAmountIndex],
      };

      if (startIndexForTrackingID > -1) {
        accesoryCharges = excelJson[i].splice(startIndexForTrackingID, excelJson[i].length);

        let arrIndex = 1;
        for (let j = 0; j <= accesoryCharges.length; j += 2) {
          if (accesoryCharges[j]) {
            fedExObj[`trackingIdChargeDescription${arrIndex}`] = accesoryCharges[j];
            fedExObj[`trackingIdChargeAmount${arrIndex}`] = accesoryCharges[j + 1];
            arrIndex += 1;
          }
        }
        // console.log(accesoryCharges, fedExObj);
      }
      tempArr.push(fedExObj);
    }

    setTempExcelJson(_.sortBy(tempArr, ['trackingNumber', 'invoiceDate', 'serviceType']));
  };

  const fetchValuesForUps = (excelJson) => {
    const trackingNumberIndex = excelJson[0].indexOf('Tracking Number');
    const invoiceDateIndex = excelJson[0].indexOf('Invoice Date');
    const invoiceNumberIndex = excelJson[0].indexOf('Invoice Number');
    const enteredWeightIndex = excelJson[0].indexOf('Entered Weight');
    const billedWeightIndex = excelJson[0].indexOf('Billed Weight');
    const zoneIndex = excelJson[0].indexOf('Zone');
    const chargeDescriptionIndex = excelJson[0].indexOf('Charge Description');
    const basisValueIndex = excelJson[0].indexOf('Basis Value');
    const incentiveAmountIndex = excelJson[0].indexOf('Incentive Amount');
    const netAmountIndex = excelJson[0].indexOf('Net Amount');
    const grossIndex = excelJson[0].indexOf('Gross');

    const tempArr = [];
    for (let i = 1; i < excelJson.length; i += 1) {
      if (invoiceNumberIndex >= 0) {
        // eslint-disable-next-line prefer-template
        if ((excelJson[i][invoiceNumberIndex] + '')?.includes('Total')) {
          continue;
        }
      }
      tempArr.push({
        bidId,
        trackingNumber: trackingNumberIndex >= 0 ? excelJson[i][trackingNumberIndex] : '',
        invoiceDate: invoiceDateIndex >= 0 ? moment(excelJson[i][invoiceDateIndex]).format('YYYY/MM/DD') : '',
        invoiceNumber: invoiceNumberIndex >= 0 ? excelJson[i][invoiceNumberIndex] : '',
        enteredWeight: enteredWeightIndex >= 0 ? excelJson[i][enteredWeightIndex] : '',
        billedWeight: billedWeightIndex >= 0 ? excelJson[i][billedWeightIndex] : '',
        Zone: zoneIndex >= 0 ? excelJson[i][zoneIndex] : '',
        chargeDescription: chargeDescriptionIndex >= 0 ? excelJson[i][chargeDescriptionIndex] : '',
        basisValue: basisValueIndex >= 0 ? excelJson[i][basisValueIndex] : '',
        incentiveAmount: incentiveAmountIndex >= 0 ? excelJson[i][incentiveAmountIndex] : '',
        netAmount: netAmountIndex >= 0 ? excelJson[i][netAmountIndex] : '',
        gross: grossIndex >= 0 ? excelJson[i][grossIndex] : '',
      });
    }

    setTempExcelJson(_.sortBy(tempArr, ['trackingNumber', 'invoiceDate', 'serviceType']));
  };

  const submitBidItems = () => {
    setIsLoading(true);

    bidItemService.addBidItems(tempExcelJson).then((res) => {
      showAlert({ text: res.message, state: 'success' });
      fetchBid().finally(() => setIsLoading(false));
      handleModalClose();
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'warning' });
      setIsLoading(false);
    });
  };

  return (
    <>
      {(isLoading) && <Overlay />}
      <Section hasNoContainer>
        <TextHeader
          title="Step 2: Parcel Shipping Analysis Items Extraction"
          size="md"
          subtitle="Line items extracted from excel sheet"
          subtitleSize="sm"
          className="secondary--clr mb-5"
          subtitleClassName="mb-20"
        />

        <ContentBlock>
          {
            (isLoading)
              ? <p>Loading...</p>
              : (
                <ReactTable
                  data={tempExcelJson}
                  columns={bidItemColumns}
                  className="react-table-minimal min mt-20"
                  minRows={10}
                  pageSizeOptions={[5, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 500]}
                  defaultPageSize={25}
                />
              )
          }

        </ContentBlock>

        <ContentBlock className="content-container--actions flex-end">
          <Button
            text="Previous Step"
            className="danger--bg"
            onClick={previousStep}
          />
          <Button
            type="button"
            text="Submit"
            className={bidItemColumns.length > 0 ? 'teal--bg' : 'light-grey--sbg'}
            onClick={submitBidItems}
            isDisabled={bidItemColumns.length === 0}
          />
        </ContentBlock>
      </Section>
    </>
  );
};

export default Step2LineItemListingWizard;