// DEPENDENCIES
import React from 'react';
import { Fade } from 'react-awesome-reveal';
// COMPONENTS
import CustomBlock from '../../../../../components/CustomBlock';
// ASSETS
import AnimatedLogo from '../../../../../components/AnimatedLogo';

const WebsiteIntroSection = () => (
  <CustomBlock className="content-section justify-content-center">
    <Fade>
      <CustomBlock className="website-intro pb-30">
        <CustomBlock className="svg-container mb-30">
          <AnimatedLogo
            width="350px"
          />
        </CustomBlock>
      </CustomBlock>
    </Fade>
  </CustomBlock>
);

export default WebsiteIntroSection;