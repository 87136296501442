/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable react/no-unknown-property */
/* eslint-disable max-len */
// DEPENDENCIES
import React from 'react';
import { Fade } from 'react-awesome-reveal';
// ICONS
import { FaCheck } from 'react-icons/fa6';
import { LiaShippingFastSolid } from 'react-icons/lia';
// CUSTOM COMPONENTS
import CustomBlock from '../../../../components/CustomBlock';
import Button from '../../../../components/Button';
import Banner from '../../../../components/Banner';
// ASSETS
import BannerImage from '../../../../assets/client/homepage-banner-image_default.png';

const CustomBannerInsert = (props) => {
  const { history, offsetValue } = props;

  return (
    <CustomBlock
      className="custom-banner"
      style={{
        backgroundImage: `url('${BannerImage}')`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPositionX: 'center',
        backgroundPositionY: offsetValue * 0.25,
      }}
    >
      <CustomBlock className="d-flex flex-column align-items-center justify-content-around flex-wrap gap-10 mt-100">
        <Fade delay={300}>
          <p className="size-xxl white--clr fw-600 text-uppercase text-center font-web">
            Ship Smarter,
          </p>
        </Fade>
        <Fade delay={900}>
          <p className="size-xxxxl white--clr fw-600 text-uppercase text-center font-web">
            Grow Faster
          </p>
        </Fade>
        
        <Fade>
          <p className="size-md white--clr text-center mt-20 mb-10">
            Save on shipping, expand your business.
            <br />
            Simple, effective logistics with Transport Logics.
          </p>
        </Fade>

        <CustomBlock className="actions-container">
          <Fade>
            <CustomBlock className="">
              <Button
                type="button"
                text={(
                  <>
                    <Fade delay={1200} direction="left">
                      <LiaShippingFastSolid size={32} />
                    </Fade>
                    <span className="">Start Saving Now</span>
                  </>
                )}
                to="/transport-logics-services"
                className="size-sm primary--bg light--clr circle"
              />
            </CustomBlock>
          </Fade>
        </CustomBlock>
      </CustomBlock>
      
    </CustomBlock>
  );
};

export default CustomBannerInsert;