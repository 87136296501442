/* eslint-disable quote-props */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable camelcase */
/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
/* eslint-disable indent */
/* eslint-disable function-paren-newline */
// DEPENDENCIES
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import ReactTable from 'react-table';
import { Wizard } from 'react-use-wizard';
import * as _ from 'lodash';
import * as XLSX from 'xlsx';
// COMPONENTS
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// ICONS
import Table from 'react-bootstrap/Table';
import {
  TbChecks, TbFileInvoice, TbChartInfographic, TbStack2
} from 'react-icons/tb';
// import { MdOutlineAttachMoney } from 'react-icons/md';
// CUSTOM COMPONENTS
import MUIDataTable from 'mui-datatables';
import Section from '../../../../components/Section';
import ContentBlock from '../../../../components/ContentBlock';
import CustomBlock from '../../../../components/CustomBlock';
import FormBlock from '../../../../components/FormBlock';
import ContentHeader from '../../../../components/ContentHeader';
import SelectBlock from '../../../../components/SelectBlock';
import Overlay from '../../../../components/Overlay';
import FixedActionsBar from '../../../../components/FixedActionsBar';
import Button from '../../../../components/Button';
// import InputBlock from '../../../../components/InputBlock';
import ModalBlock from '../../../../components/ModalBlock';
// HELPERS AND SERVICES
import * as helper from '../../../../helpers/helper';
import * as bidService from '../../../../services/management/bidService';
import * as clientService from '../../../../services/management/clientService';
import * as benchmarkService from '../../../../services/management/benchmarkService';
// REDUX
import * as alert from '../../../../redux/alertToastRedux';
import * as auth from '../../../../redux/authRedux';
// CONSTANTS
import Step1FileUploadWizard from './wizard/Step1FileUploadWizard';
import Step2LineItemListingWizard from './wizard/Step2LineItemListingWizard';

const carrierOptions = ['FedEX', 'UPS'];

const initialBidModel = {
  id: 0,
  isActive: true,
  clientId: 0,
  type: '',
  client: ''
};

const BidManagementPage = (props) => {
  const { history, showAlert } = props;
  let { bidId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [bid, setBid] = useState(initialBidModel);
  const [clients, setClients] = useState([]);
  const [bidItems, setBidItems] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [excelFileUpload, setExcelFileUpload] = useState([]);
  const [uniqueServiceTypes, setUniqueServiceTypes] = useState([]);
  const [benchmarks, setBenchmarks] = useState([]);
  const [invoiceData, setInvoiceData] = useState([]);

  bidId = parseInt(bidId, 10);

  useEffect(() => {
    fetchOptionsAndBid();
  }, [bidId]);

  const fetchOptionsAndBid = async () => {
    setIsLoading(true);

    try {
      if (bidId) {
        await Promise.all([
          fetchBid(),
          fetchBenchmarks()
        ]);
      } else {
        await Promise.all([
          fetchClients()
        ]);
      }
    } catch (error) {
      showAlert({ text: error.message, state: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  const fetchBenchmarks = async (clientId) => {
    if (clientId) {
      try {
        const res = await benchmarkService.getAll(clientId);
        setBenchmarks(res);

        return res;
      } catch (error) {
        showAlert({ text: error.message, state: 'error' });
      }
    }

    return [];
  };

  const fetchBid = async () => {
    try {
      const res = await bidService.getById(bidId);
      const benchms = await fetchBenchmarks(res.clientId);
      await fetchBidItems(res, benchms);
      setBid(res);
    } catch (error) {
      showAlert({ text: error.message, state: 'error' });
    }
  };

  // Helper to calculate gross, incentive amounts, and net charge
  const calculateGrossAndIncentives = (items, type) => {
    let gross = 0;
    let sumOfIncentiveAmount = 0;
    let netChargeAmount = 0;

    items.forEach((item) => {
      if (type === 'UPS') {
        const incentiveAmount = parseFloat(item.incentiveAmount || 0, 10);
        const netAmount = parseFloat(item.netAmount || 0, 10);
        gross += (incentiveAmount + netAmount);
        sumOfIncentiveAmount += incentiveAmount;
        netChargeAmount += netAmount;
      } else {
        gross += parseFloat(item.transportationChargeAmount || 0, 10);
        sumOfIncentiveAmount += [convertToFloat(item.trackingIdChargeAmount1), convertToFloat(item.trackingIdChargeAmount2), convertToFloat(item.trackingIdChargeAmount3),
        convertToFloat(item.trackingIdChargeAmount4), convertToFloat(item.trackingIdChargeAmount5), convertToFloat(item.trackingIdChargeAmount6),
        convertToFloat(item.trackingIdChargeAmount7), convertToFloat(item.trackingIdChargeAmount8), convertToFloat(item.trackingIdChargeAmount9), convertToFloat(item.trackingIdChargeAmount10),
        convertToFloat(item.trackingIdChargeAmount11), convertToFloat(item.trackingIdChargeAmount12), convertToFloat(item.trackingIdChargeAmount13), convertToFloat(item.trackingIdChargeAmount14), convertToFloat(item.trackingIdChargeAmount15),
        convertToFloat(item.trackingIdChargeAmount16), convertToFloat(item.trackingIdChargeAmount17), convertToFloat(item.trackingIdChargeAmount18), convertToFloat(item.trackingIdChargeAmount19), convertToFloat(item.trackingIdChargeAmount20)].filter((x) => x < 0).reduce((a, b) => a + b, 0);
        netChargeAmount += parseFloat(item.netChargeAmount || 0, 10);
      }
    });

    return { gross, sumOfIncentiveAmount, netChargeAmount };
  };

  // Helper to calculate savings and variance
  const calculateSavingsAndVariance = (gross, sumOfIncentiveAmount, bm, min = 0) => {
    let sumOfOldSavings = gross * (bm / 100);
    let sumOfVariance = sumOfIncentiveAmount - sumOfOldSavings;

    if (sumOfVariance < 0) {
      sumOfOldSavings = gross - min;
      sumOfVariance = sumOfIncentiveAmount - sumOfOldSavings;
    }

    return { sumOfOldSavings, sumOfVariance };
  };

  // Helper to push invoice data into the array
  const generateInvoiceData = (name, gross, sumOfIncentiveAmount, netChargeAmount, bmPercentage, sumOfOldSavings, sumOfVariance) => ({
    name,
    gross,
    sumOfIncentiveAmount: sumOfIncentiveAmount.toFixed(2),
    netChargeAmount: netChargeAmount.toFixed(2),
    bmPercentage,
    sumOfOldSavings: sumOfOldSavings.toFixed(2),
    sumOfVariance: sumOfVariance.toFixed(2),
  });

  // Helper to process each benchmark group
  const processBenchmarkGroup = (benchmarkGroup, groupedByServiceType, serviceType, idsEvaluated, type) => {
    const numberDashNumberFormat = /^(\d+)-(\d+)$/;
    const match = benchmarkGroup.group.match(numberDashNumberFormat);
    if (match) {
      const [_, min, max] = match;
      const matchedWeights = groupedByServiceType[serviceType].filter(
        (x) => parseInt(x.billedWeight, 10) >= min && parseInt(x.billedWeight, 10) <= max
      );
      const { gross, sumOfIncentiveAmount, netChargeAmount } = calculateGrossAndIncentives(matchedWeights, type);

      matchedWeights.forEach((item) => idsEvaluated.push(item.id));

      const { sumOfOldSavings, sumOfVariance } = calculateSavingsAndVariance(gross, sumOfIncentiveAmount, benchmarkGroup.bm, benchmarkGroup.min);

      return generateInvoiceData(`${serviceType} (${min}-${max})`, gross, sumOfIncentiveAmount, netChargeAmount, benchmarkGroup.bm, sumOfOldSavings, sumOfVariance);
    }
    return null;
  };

  // Main handler function
  const handleInvoice = (groupedByServiceType, benchms, type) => {
    const invoiceData = [];

    Object.keys(groupedByServiceType).forEach((serviceType) => {
      const name = serviceType || '-';
      const bmPercentage = 0;
      const sumOfOldSavings = 0;
      const sumOfVariance = 0;
      const matchedBenchMark = benchms.find((x) => x.name === serviceType);

      if (matchedBenchMark) {
        const idsEvaluated = [];

        if (matchedBenchMark.benchmarkGroups.length > 0) {
          matchedBenchMark.benchmarkGroups.forEach((benchmarkGroup) => {
            const invoiceEntry = processBenchmarkGroup(benchmarkGroup, groupedByServiceType, serviceType, idsEvaluated, type);
            if (invoiceEntry) invoiceData.push(invoiceEntry);
          });
        }

        // Process remaining items that weren't matched
        const unmatchedItems = groupedByServiceType[serviceType].filter((x) => !idsEvaluated.includes(x.id));
        if (unmatchedItems.length > 0) {
          const { gross, sumOfIncentiveAmount, netChargeAmount } = calculateGrossAndIncentives(unmatchedItems, type);
          const { sumOfOldSavings, sumOfVariance } = calculateSavingsAndVariance(gross, sumOfIncentiveAmount, matchedBenchMark.bm, matchedBenchMark.min);

          invoiceData.push(generateInvoiceData(name, gross, sumOfIncentiveAmount, netChargeAmount, matchedBenchMark.bm, sumOfOldSavings, sumOfVariance));
        }
      } else {
        // Process if no matching benchmark
        const { gross, sumOfIncentiveAmount, netChargeAmount } = calculateGrossAndIncentives(groupedByServiceType[serviceType], type);
        invoiceData.push(generateInvoiceData(name, gross, sumOfIncentiveAmount, netChargeAmount, bmPercentage, sumOfOldSavings, sumOfVariance));
      }
    });

    setInvoiceData(invoiceData);
  };

  const fetchBidItems = async (model, benchms) => {
    try {
      const res = await bidService.getBidItems(bidId);
      const groupedByServiceType = _.groupBy(res, model.type === 'UPS' ? 'chargeDescription' : 'serviceType');
      if (model.type === 'UPS') {
        handleInvoice(groupedByServiceType, benchms, 'UPS');
        setUniqueServiceTypes(_.map(groupedByServiceType, (group, serviceType) => ({
          name: serviceType || '-',
          count: group.length,
          totalCharge: 0,
          incentiveAmount: group.map((x) => parseFloat(x.incentiveAmount || 0, 10)).reduce((a, b) => a + b, 0).toFixed(2),
          gross: group.map((x) => parseFloat(x.gross || 0, 10)).reduce((a, b) => a + b, 0).toFixed(2),
          netChargeAmount: group.map((x) => parseFloat(x.netAmount || 0, 10)).reduce((a, b) => a + b, 0).toFixed(2),
          ratedWeightAmount: group.map((x) => parseFloat(x.billedWeight || 0, 10)).reduce((a, b) => a + b, 0).toFixed(2),
          totalDiscountAmount: group.map((x) => parseFloat(x.incentiveAmount || 0, 10)).reduce((a, b) => a + b, 0).toFixed(2),
          divisor: calculateDiscountPercentage(group)
        })));
        setBidItems(_.sortBy(res, ['trackingNumber', 'invoiceDate', 'serviceType']));
      } else {
        // transportation charge sum is -> Gross Amount
        // net charge sum is -> Net Amount
        // discount is the sum of the negative  numbers -> totalDiscountAmount
        // BM % comes from benchmark page for both UPS and FedEx
        // Sum of old savings and sum of variance
        handleInvoice(groupedByServiceType, benchms, 'FedEx');
        setUniqueServiceTypes(_.map(groupedByServiceType, (group, serviceType) => ({
          name: serviceType || '-',
          count: group.length,
          incentiveAmount: group.map((x) => parseFloat(x.incentiveAmount || 0, 10)).reduce((a, b) => a + b, 0).toFixed(2),
          gross: group.map((x) => parseFloat(x.gross || 0, 10)).reduce((a, b) => a + b, 0).toFixed(2),
          totalCharge: group.map((x) => parseFloat(x.transportationChargeAmount || 0, 10)).reduce((a, b) => a + b, 0).toFixed(2),
          netChargeAmount: group.map((x) => parseFloat(x.netChargeAmount || 0, 10)).reduce((a, b) => a + b, 0).toFixed(2),
          ratedWeightAmount: group.map((x) => parseFloat(x.ratedWeightAmount || 0, 10)).reduce((a, b) => a + b, 0).toFixed(2),
          totalDiscountAmount: _.flatten(group.map((x) => (
            [convertToFloat(x.trackingIdChargeAmount1), convertToFloat(x.trackingIdChargeAmount2), convertToFloat(x.trackingIdChargeAmount3), convertToFloat(x.trackingIdChargeAmount4), convertToFloat(x.trackingIdChargeAmount5),
            convertToFloat(x.trackingIdChargeAmount6), convertToFloat(x.trackingIdChargeAmount7), convertToFloat(x.trackingIdChargeAmount8), convertToFloat(x.trackingIdChargeAmount9), convertToFloat(x.trackingIdChargeAmount10),
            convertToFloat(x.trackingIdChargeAmount11), convertToFloat(x.trackingIdChargeAmount12), convertToFloat(x.trackingIdChargeAmount13), convertToFloat(x.trackingIdChargeAmount14), convertToFloat(x.trackingIdChargeAmount15),
            convertToFloat(x.trackingIdChargeAmount16), convertToFloat(x.trackingIdChargeAmount17), convertToFloat(x.trackingIdChargeAmount18), convertToFloat(x.trackingIdChargeAmount19), convertToFloat(x.trackingIdChargeAmount20)
            ]))).filter((x) => x < 0).reduce((a, b) => a + b, 0)
        })));
        setBidItems(_.sortBy(res, ['trackingNumber', 'invoiceDate', 'serviceType']));
      }
    } catch (error) {
      showAlert({ text: error.message, state: 'error' });
    }
  };

  const calculateDiscountPercentage = (group) => {
    const incentiveAmount_AZ = group.map((x) => parseFloat(x.incentiveAmount || 0, 10)).reduce((a, b) => a + b, 0);
    const netAmount_BA = group.map((x) => parseFloat(x.netAmount || 0, 10)).reduce((a, b) => a + b, 0);

    const divisor = (incentiveAmount_AZ + netAmount_BA);
    if (divisor === 0) return 0;
    const discount = incentiveAmount_AZ / divisor;
    const discountPercentage = (discount * 100).toFixed(2);

    return discountPercentage;
  };

  const convertToFloat = (amount) => parseFloat(amount || 0, 10);

  const fetchClients = async () => {
    try {
      const res = await clientService.getAll();
      setClients(res.map((option) => (helper.setToOptionModel(option.name, option.id))));
    } catch (error) {
      showAlert({ text: error.message, state: 'error' });
    }
  };

  const formik = useFormik({
    initialValues: bid,
    validationSchema: Yup.object().shape({
      id: Yup.number(),
      isActive: Yup.bool(),
      clientId: Yup.number().required('Name is required').nullable(),
      client: Yup.string().nullable(),
      type: Yup.string().required().nullable(),
    }),
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      if (bidId) {
        updateBid(values);
      } else {
        createBid(values);
      }
    },
  });

  const updateBid = (model) => {
    setIsLoading(true);
    bidService.update(model).then((res) => {
      showAlert({ text: res.message, state: 'success' });
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'warning' });
    }).finally(() => setIsLoading(false));
  };

  const createBid = (model) => {
    setIsLoading(true);

    bidService.create(model).then((res) => {
      showAlert({ text: res.message, state: 'success' });
      history.push(`/admin/management/bid/${res.id}`);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'warning' });
    }).finally(() => setIsLoading(false));
  };

  const bidItemColumns = [
    {
      label: 'Invoice Number',
      name: 'invoiceNumber',
      options: { display: false }
    },
    {
      label: 'Tracking #',
      name: 'trackingNumber',
      minWidth: 180
    },
    {
      label: 'Invoice Date',
      name: 'invoiceDate',
      minWidth: 140
    },
    {
      label: 'Service Type',
      name: 'serviceType',
      options: { display: bid.type === 'FedEX' },
      minWidth: 140
    },
    {
      label: 'Total Transportation Charge Amount',
      name: 'transportationChargeAmount',
      options: { display: bid.type === 'FedEX' },
      minWidth: 140
    },
    {
      label: 'Net Charge Amount',
      name: 'netChargeAmount',
      options: { display: bid.type === 'FedEX' },
      minWidth: 140
    },
    {
      label: 'Entered Weight',
      name: 'enteredWeight',
      options: { display: bid.type === 'UPS' },
      minWidth: 70
    },
    {
      label: 'Billed Weight',
      name: 'billedWeight',
      options: { display: bid.type === 'UPS' },
      minWidth: 70
    },
    {
      label: 'Zone',
      name: 'zone',
      options: { display: bid.type === 'UPS' },
      minWidth: 40
    },
    {
      label: 'Charge Description',
      name: 'chargeDescription',
      options: { display: bid.type === 'UPS' },
      minWidth: 240
    },
    {
      label: 'Basis Value',
      name: 'basisValue',
      options: { display: bid.type === 'UPS' },
      minWidth: 60
    },
    {
      label: 'Incentive Amount',
      name: 'incentiveAmount',
      options: { display: bid.type === 'UPS' },
      minWidth: 60
    },
    {
      label: 'Net Amount',
      name: 'netAmount',
      options: { display: bid.type === 'UPS' },
      minWidth: 60
    },
    {
      label: 'Gross',
      name: 'gross',
      options: { display: bid.type === 'UPS' },
      minWidth: 60
    },
    {
      label: 'Actual Weight Amount',
      name: 'actualWeightAmount',
      options: { display: bid.type === 'FedEX' },
      minWidth: 70
    },
    {
      label: 'Rated Weight Amount',
      name: 'ratedWeightAmount',
      options: { display: bid.type === 'FedEX' },
      minWidth: 70
    },
    {
      label: 'Length',
      name: 'length',
      options: { display: bid.type === 'FedEX' },
      minWidth: 70
    },
    {
      label: 'Width',
      name: 'width',
      options: { display: bid.type === 'FedEX' },
      minWidth: 70
    },
    {
      label: 'Height',
      name: 'height',
      options: { display: bid.type === 'FedEX' },
      minWidth: 70
    },
    {
      label: 'Divisor',
      name: 'divisor',
      options: { display: bid.type === 'FedEX' },
      minWidth: 70
    },
    {
      label: 'Zone Code',
      name: 'zoneCode',
      options: { display: bid.type === 'FedEX' },
      minWidth: 70
    },
    {
      label: 'Multiweight Number',
      name: 'multiWeightNumber',
      minWidth: 80,
      options: {
        display: bid.type === 'FedEX',
        customBodyRenderLite: (dataIndex, rowIndex, rowData) => {
          const multiWeightNumber = rowData?.original?.multiWeightNumber;
          if (multiWeightNumber && parseInt(multiWeightNumber.value, 10) > 0) {
            return <TbChecks size={25} color="primary--clr" />;
          }
          return null;
        }
      },
    },
    {
      label: 'Tracking ID Charge Description',
      name: 'trackingIdChargeDescription1',
      options: { display: bid.type === 'FedEX' },
      minWidth: 200,
    },
    {
      label: 'Tracking ID Charge Amount',
      name: 'trackingIdChargeAmount1',
      options: { display: bid.type === 'FedEX' },
      minWidth: 100,
    },
    {
      label: 'Tracking ID Charge Description',
      name: 'trackingIdChargeDescription2',
      options: { display: bid.type === 'FedEX' },
      minWidth: 200,
    },
    {
      label: 'Tracking ID Charge Amount',
      name: 'trackingIdChargeAmount2',
      options: { display: bid.type === 'FedEX' },
      minWidth: 100,
    },
    {
      label: 'Tracking ID Charge Description',
      name: 'trackingIdChargeDescription3',
      options: { display: bid.type === 'FedEX' },
      minWidth: 200,
    },
    {
      label: 'Tracking ID Charge Amount',
      name: 'trackingIdChargeAmount3',
      options: { display: bid.type === 'FedEX' },
      minWidth: 100,
    },
    {
      label: 'Tracking ID Charge Description',
      name: 'trackingIdChargeDescription4',
      options: { display: bid.type === 'FedEX' },
      minWidth: 200,
    },
    {
      label: 'Tracking ID Charge Amount',
      name: 'trackingIdChargeAmount4',
      options: { display: bid.type === 'FedEX' },
      minWidth: 100,
    },
    {
      label: 'Tracking ID Charge Description',
      name: 'trackingIdChargeDescription5',
      options: { display: bid.type === 'FedEX' },
      minWidth: 200,
    },
    {
      label: 'Tracking ID Charge Amount',
      name: 'trackingIdChargeAmount5',
      options: { display: bid.type === 'FedEX' },
      minWidth: 100,
    },
    {
      label: 'Tracking ID Charge Description',
      name: 'trackingIdChargeDescription6',
      options: { display: bid.type === 'FedEX' },
      minWidth: 200,
    },
    {
      label: 'Tracking ID Charge Amount',
      name: 'trackingIdChargeAmount6',
      options: { display: bid.type === 'FedEX' },
      minWidth: 100,
    },
    {
      label: 'Tracking ID Charge Description',
      name: 'trackingIdChargeDescription7',
      options: { display: bid.type === 'FedEX' },
      minWidth: 200,
    },
    {
      label: 'Tracking ID Charge Amount',
      name: 'trackingIdChargeAmount7',
      options: { display: bid.type === 'FedEX' },
      minWidth: 100,
    },
    {
      label: 'Tracking ID Charge Description',
      name: 'trackingIdChargeDescription8',
      options: { display: bid.type === 'FedEX' },
      minWidth: 200,
    },
    {
      label: 'Tracking ID Charge Amount',
      name: 'trackingIdChargeAmount8',
      options: { display: bid.type === 'FedEX' },
      minWidth: 100,
    },
    {
      label: 'Tracking ID Charge Description',
      name: 'trackingIdChargeDescription9',
      options: { display: bid.type === 'FedEX' },
      minWidth: 200,
    },
    {
      label: 'Tracking ID Charge Amount',
      name: 'trackingIdChargeAmount9',
      options: { display: bid.type === 'FedEX' },
      minWidth: 100,
    },
    {
      label: 'Tracking ID Charge Description',
      name: 'trackingIdChargeDescription9',
      options: { display: bid.type === 'FedEX' },
      minWidth: 200,
    },
    {
      label: 'Tracking ID Charge Amount',
      name: 'trackingIdChargeAmount9',
      options: { display: bid.type === 'FedEX' },
      minWidth: 100,
    },
    {
      label: 'Tracking ID Charge Description',
      name: 'trackingIdChargeDescription10',
      options: { display: bid.type === 'FedEX' },
      minWidth: 200,
    },
    {
      label: 'Tracking ID Charge Amount',
      name: 'trackingIdChargeAmount10',
      options: { display: bid.type === 'FedEX' },
      minWidth: 100,
    },
    {
      label: 'Tracking ID Charge Description',
      name: 'trackingIdChargeDescription11',
      options: { display: bid.type === 'FedEX' },
      minWidth: 200,
    },
    {
      label: 'Tracking ID Charge Amount',
      name: 'trackingIdChargeAmount11',
      options: { display: bid.type === 'FedEX' },
      minWidth: 100,
    },
    {
      label: 'Tracking ID Charge Description',
      name: 'trackingIdChargeDescription12',
      options: { display: bid.type === 'FedEX' },
      minWidth: 200,
    },
    {
      label: 'Tracking ID Charge Amount',
      name: 'trackingIdChargeAmount12',
      options: { display: bid.type === 'FedEX' },
      minWidth: 100,
    },
    {
      label: 'Tracking ID Charge Description',
      name: 'trackingIdChargeDescription13',
      options: { display: bid.type === 'FedEX' },
      minWidth: 200,
    },
    {
      label: 'Tracking ID Charge Amount',
      name: 'trackingIdChargeAmount13',
      options: { display: bid.type === 'FedEX' },
      minWidth: 100,
    },
    {
      label: 'Tracking ID Charge Description',
      name: 'trackingIdChargeDescription14',
      options: { display: bid.type === 'FedEX' },
      minWidth: 200,
    },
    {
      label: 'Tracking ID Charge Amount',
      name: 'trackingIdChargeAmount14',
      options: { display: bid.type === 'FedEX' },
      minWidth: 100,
    },
    {
      label: 'Tracking ID Charge Description',
      name: 'trackingIdChargeDescription15',
      options: { display: bid.type === 'FedEX' },
      minWidth: 200,
    },
    {
      label: 'Tracking ID Charge Amount',
      name: 'trackingIdChargeAmount15',
      options: { display: bid.type === 'FedEX' },
      minWidth: 100,
    },
    {
      label: 'Tracking ID Charge Description',
      name: 'trackingIdChargeDescription16',
      options: { display: bid.type === 'FedEX' },
      minWidth: 200,
    },
    {
      label: 'Tracking ID Charge Amount',
      name: 'trackingIdChargeAmount16',
      options: { display: bid.type === 'FedEX' },
      minWidth: 100,
    },
    {
      label: 'Tracking ID Charge Description',
      name: 'trackingIdChargeDescription17',
      options: { display: bid.type === 'FedEX' },
      minWidth: 200,
    },
    {
      label: 'Tracking ID Charge Amount',
      name: 'trackingIdChargeAmount17',
      options: { display: bid.type === 'FedEX' },
      minWidth: 100,
    },
    {
      label: 'Tracking ID Charge Description',
      name: 'trackingIdChargeDescription18',
      options: { display: bid.type === 'FedEX' },
      minWidth: 200,
    },
    {
      label: 'Tracking ID Charge Amount',
      name: 'trackingIdChargeAmount18',
      options: { display: bid.type === 'FedEX' },
      minWidth: 100,
    },
    {
      label: 'Tracking ID Charge Description',
      name: 'trackingIdChargeDescription19',
      options: { display: bid.type === 'FedEX' },
      minWidth: 200,
    },
    {
      label: 'Tracking ID Charge Amount',
      name: 'trackingIdChargeAmount19',
      options: { display: bid.type === 'FedEX' },
      minWidth: 100,
    },
    {
      label: 'Tracking ID Charge Description',
      name: 'trackingIdChargeDescription20',
      options: { display: bid.type === 'FedEX' },
      minWidth: 200,
    },
    {
      label: 'Tracking ID Charge Amount',
      name: 'trackingIdChargeAmount20',
      options: { display: bid.type === 'FedEX' },
      minWidth: 100,
    }
  ];

  const bidItemColumnsReactTable = [
    {
      Header: 'Invoice Number',
      accessor: 'invoiceNumber',
      show: false
    },
    {
      Header: 'Tracking #',
      accessor: 'trackingNumber',
      minWidth: 180
    },
    {
      Header: 'Invoice Date',
      accessor: 'invoiceDate',
      minWidth: 140
    },
    {
      Header: 'Service Type',
      accessor: 'serviceType',
      show: bid.type === 'FedEX',
      minWidth: 140
    },
    {
      Header: 'Total Transportation Charge Amount',
      accessor: 'transportationChargeAmount',
      show: bid.type === 'FedEX',
      minWidth: 140
    },
    {
      Header: 'Net Charge Amount',
      accessor: 'netChargeAmount',
      show: bid.type === 'FedEX',
      minWidth: 140
    },
    {
      Header: 'Entered Weight',
      accessor: 'enteredWeight',
      show: bid.type === 'UPS',
      minWidth: 70
    },
    {
      Header: 'Billed Weight',
      accessor: 'billedWeight',
      show: bid.type === 'UPS',
      minWidth: 70
    },
    {
      Header: 'Zone',
      accessor: 'zone',
      show: bid.type === 'UPS',
      minWidth: 40
    },
    {
      Header: 'Charge Description',
      accessor: 'chargeDescription',
      show: bid.type === 'UPS',
      minWidth: 240
    },
    {
      Header: 'Basis Value',
      accessor: 'basisValue',
      show: bid.type === 'UPS',
      minWidth: 60
    },
    {
      Header: 'Incentive Amount',
      accessor: 'incentiveAmount',
      show: bid.type === 'UPS',
      minWidth: 60
    },
    {
      Header: 'Net Amount',
      accessor: 'netAmount',
      show: bid.type === 'UPS',
      minWidth: 60
    },
    {
      Header: 'Gross',
      accessor: 'gross',
      show: bid.type === 'UPS',
      minWidth: 60
    },
    {
      Header: 'Actual Weight Amount',
      accessor: 'actualWeightAmount',
      show: bid.type === 'FedEX',
      minWidth: 70
    },
    {
      Header: 'Rated Weight Amount',
      accessor: 'ratedWeightAmount',
      show: bid.type === 'FedEX',
      minWidth: 70
    },
    {
      Header: 'Length',
      accessor: 'length',
      show: bid.type === 'FedEX',
      minWidth: 70
    },
    {
      Header: 'Width',
      accessor: 'width',
      show: bid.type === 'FedEX',
      minWidth: 70
    },
    {
      Header: 'Height',
      accessor: 'height',
      show: bid.type === 'FedEX',
      minWidth: 70
    },
    {
      Header: 'Divisor',
      accessor: 'divisor',
      show: bid.type === 'FedEX',
      minWidth: 70
    },
    {
      Header: 'Zone Code',
      accessor: 'zoneCode',
      show: bid.type === 'FedEX',
      minWidth: 70
    },
    {
      Header: 'Multiweight Number',
      accessor: 'multiWeightNumber',
      show: bid.type === 'FedEX',
      minWidth: 80,
      Cell: (cellProps) => {
        const { original } = cellProps;

        return (
          <p>{parseInt(original.multiWeightNumber, 10) > 0 ? <TbChecks size={25} color="primary--clr" /> : ''}</p>
        );
      }
    },
    {
      Header: 'Tracking ID Charge Description',
      accessor: 'trackingIdChargeDescription1',
      show: bid.type === 'FedEX',
      minWidth: 200,
    },
    {
      Header: 'Tracking ID Charge Amount',
      accessor: 'trackingIdChargeAmount1',
      show: bid.type === 'FedEX',
      minWidth: 100,
    },
    {
      Header: 'Tracking ID Charge Description',
      accessor: 'trackingIdChargeDescription2',
      show: bid.type === 'FedEX',
      minWidth: 200,
    },
    {
      Header: 'Tracking ID Charge Amount',
      accessor: 'trackingIdChargeAmount2',
      show: bid.type === 'FedEX',
      minWidth: 100,
    },
    {
      Header: 'Tracking ID Charge Description',
      accessor: 'trackingIdChargeDescription3',
      show: bid.type === 'FedEX',
      minWidth: 200,
    },
    {
      Header: 'Tracking ID Charge Amount',
      accessor: 'trackingIdChargeAmount3',
      show: bid.type === 'FedEX',
      minWidth: 100,
    },
    {
      Header: 'Tracking ID Charge Description',
      accessor: 'trackingIdChargeDescription4',
      show: bid.type === 'FedEX',
      minWidth: 200,
    },
    {
      Header: 'Tracking ID Charge Amount',
      accessor: 'trackingIdChargeAmount4',
      show: bid.type === 'FedEX',
      minWidth: 100,
    },
    {
      Header: 'Tracking ID Charge Description',
      accessor: 'trackingIdChargeDescription5',
      show: bid.type === 'FedEX',
      minWidth: 200,
    },
    {
      Header: 'Tracking ID Charge Amount',
      accessor: 'trackingIdChargeAmount5',
      show: bid.type === 'FedEX',
      minWidth: 100,
    },
    {
      Header: 'Tracking ID Charge Description',
      accessor: 'trackingIdChargeDescription6',
      show: bid.type === 'FedEX',
      minWidth: 200,
    },
    {
      Header: 'Tracking ID Charge Amount',
      accessor: 'trackingIdChargeAmount6',
      show: bid.type === 'FedEX',
      minWidth: 100,
    },
    {
      Header: 'Tracking ID Charge Description',
      accessor: 'trackingIdChargeDescription7',
      show: bid.type === 'FedEX',
      minWidth: 200,
    },
    {
      Header: 'Tracking ID Charge Amount',
      accessor: 'trackingIdChargeAmount7',
      show: bid.type === 'FedEX',
      minWidth: 100,
    },
    {
      Header: 'Tracking ID Charge Description',
      accessor: 'trackingIdChargeDescription8',
      show: bid.type === 'FedEX',
      minWidth: 200,
    },
    {
      Header: 'Tracking ID Charge Amount',
      accessor: 'trackingIdChargeAmount8',
      show: bid.type === 'FedEX',
      minWidth: 100,
    },
    {
      Header: 'Tracking ID Charge Description',
      accessor: 'trackingIdChargeDescription9',
      show: bid.type === 'FedEX',
      minWidth: 200,
    },
    {
      Header: 'Tracking ID Charge Amount',
      accessor: 'trackingIdChargeAmount9',
      show: bid.type === 'FedEX',
      minWidth: 100,
    },
    {
      Header: 'Tracking ID Charge Description',
      accessor: 'trackingIdChargeDescription9',
      show: bid.type === 'FedEX',
      minWidth: 200,
    },
    {
      Header: 'Tracking ID Charge Amount',
      accessor: 'trackingIdChargeAmount9',
      show: bid.type === 'FedEX',
      minWidth: 100,
    },
    {
      Header: 'Tracking ID Charge Description',
      accessor: 'trackingIdChargeDescription10',
      show: bid.type === 'FedEX',
      minWidth: 200,
    },
    {
      Header: 'Tracking ID Charge Amount',
      accessor: 'trackingIdChargeAmount10',
      show: bid.type === 'FedEX',
      minWidth: 100,
    },
    {
      Header: 'Tracking ID Charge Description',
      accessor: 'trackingIdChargeDescription11',
      show: bid.type === 'FedEX',
      minWidth: 200,
    },
    {
      Header: 'Tracking ID Charge Amount',
      accessor: 'trackingIdChargeAmount11',
      show: bid.type === 'FedEX',
      minWidth: 100,
    },
    {
      Header: 'Tracking ID Charge Description',
      accessor: 'trackingIdChargeDescription12',
      show: bid.type === 'FedEX',
      minWidth: 200,
    },
    {
      Header: 'Tracking ID Charge Amount',
      accessor: 'trackingIdChargeAmount12',
      show: bid.type === 'FedEX',
      minWidth: 100,
    },
    {
      Header: 'Tracking ID Charge Description',
      accessor: 'trackingIdChargeDescription13',
      show: bid.type === 'FedEX',
      minWidth: 200,
    },
    {
      Header: 'Tracking ID Charge Amount',
      accessor: 'trackingIdChargeAmount13',
      show: bid.type === 'FedEX',
      minWidth: 100,
    },
    {
      Header: 'Tracking ID Charge Description',
      accessor: 'trackingIdChargeDescription14',
      show: bid.type === 'FedEX',
      minWidth: 200,
    },
    {
      Header: 'Tracking ID Charge Amount',
      accessor: 'trackingIdChargeAmount14',
      show: bid.type === 'FedEX',
      minWidth: 100,
    },
    {
      Header: 'Tracking ID Charge Description',
      accessor: 'trackingIdChargeDescription15',
      show: bid.type === 'FedEX',
      minWidth: 200,
    },
    {
      Header: 'Tracking ID Charge Amount',
      accessor: 'trackingIdChargeAmount15',
      show: bid.type === 'FedEX',
      minWidth: 100,
    },
    {
      Header: 'Tracking ID Charge Description',
      accessor: 'trackingIdChargeDescription16',
      show: bid.type === 'FedEX',
      minWidth: 200,
    },
    {
      Header: 'Tracking ID Charge Amount',
      accessor: 'trackingIdChargeAmount16',
      show: bid.type === 'FedEX',
      minWidth: 100,
    },
    {
      Header: 'Tracking ID Charge Description',
      accessor: 'trackingIdChargeDescription17',
      show: bid.type === 'FedEX',
      minWidth: 200,
    },
    {
      Header: 'Tracking ID Charge Amount',
      accessor: 'trackingIdChargeAmount17',
      show: bid.type === 'FedEX',
      minWidth: 100,
    },
    {
      Header: 'Tracking ID Charge Description',
      accessor: 'trackingIdChargeDescription18',
      show: bid.type === 'FedEX',
      minWidth: 200,
    },
    {
      Header: 'Tracking ID Charge Amount',
      accessor: 'trackingIdChargeAmount18',
      show: bid.type === 'FedEX',
      minWidth: 100,
    },
    {
      Header: 'Tracking ID Charge Description',
      accessor: 'trackingIdChargeDescription19',
      show: bid.type === 'FedEX',
      minWidth: 200,
    },
    {
      Header: 'Tracking ID Charge Amount',
      accessor: 'trackingIdChargeAmount19',
      show: bid.type === 'FedEX',
      minWidth: 100,
    },
    {
      Header: 'Tracking ID Charge Description',
      accessor: 'trackingIdChargeDescription20',
      show: bid.type === 'FedEX',
      minWidth: 200,
    },
    {
      Header: 'Tracking ID Charge Amount',
      accessor: 'trackingIdChargeAmount20',
      show: bid.type === 'FedEX',
      minWidth: 100,
    }
  ];

  const handleModalClose = () => {
    setIsModalOpen(false);
    setExcelFileUpload([]);
  };

  const handleDownloadInvoice = () => {
    const formattedInvoice = invoiceData.map((item) => ({
      'Label': item.name,
      'Sum Of Gross': item.gross,
      'Sum Of Incentive Amount': item.sumOfIncentiveAmount,
      'Sum Of Net Amount': item.netChargeAmount,
      'BM %': item.bmPercentage,
      'Sum Of Old Savings': item.bmAmount,
      'Sum Of Variance': item.sumOfVariance,
    }));
    const workbook = XLSX.utils.book_new();

    const defaultColWidth = 20;
    const wscols = [];
    for (let i = 1; i < 10; i += 1) {
      wscols.push({ wch: defaultColWidth });
    }

    const worksheet = XLSX.utils.json_to_sheet(formattedInvoice);
    worksheet['!cols'] = wscols; // Set column widths
    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Invoice');

    // Generate a binary string representation of the workbook
    const workbookBinary = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });

    // Convert the binary string to a Blob
    const blob = new Blob([s2ab(workbookBinary)], { type: 'application/octet-stream' });

    // Create a link element and trigger a download
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `Invoice_${bid.id}.xlsx`;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i += 1) {
      // eslint-disable-next-line no-bitwise
      view[i] = s.charCodeAt(i) & 0xFF;
    }
    return buf;
  };

  return (
    <>
      {isLoading && <Overlay hasLoader />}
      <CustomBlock className="content-container--padded">
        <Section isFullWidth>
          <ContentBlock>
            <FormBlock onSubmit={formik.submitForm}>

              {/* CONTENT DETAILS */}
              <Section
                hasNoContainer
                className="content-container--card-style--with-shadow"
              >
                <ContentBlock>
                  <ContentHeader
                    title={bidId ? 'Details' : 'New Analysis'}
                    headerSize="lg"
                    containerClassName="pb-20"
                  />
                </ContentBlock>

                <ContentBlock cols={4} className="ml-5 mr-5">
                  <SelectBlock
                    label="Client"
                    placeholder="Select an option"
                    options={clients}
                    isRequired
                    errorMessage={formik.errors.clientId}
                    inputState={`${helper.getInputClasses(formik, 'clientId')}`}
                    value={
                      formik.values.clientId
                        ? helper.setToOptionModel(formik.values.client, formik.values.clientId)
                        : null
                    }
                    onChange={(opt) => {
                      formik.setFieldValue('client', opt ? opt.label : '');
                      formik.setFieldValue('clientId', opt ? opt.value : '');
                    }}
                  />
                </ContentBlock>

                <ContentBlock cols={4} className="ml-5 mr-5">
                  <SelectBlock
                    label="Carrier"
                    placeholder="Select an option"
                    options={carrierOptions.map((x) => helper.setToOptionModel(x, x))}
                    isRequired
                    errorMessage={formik.errors.type}
                    inputState={bidId ? 'disabled' : `${helper.getInputClasses(formik, 'type')}`}
                    value={
                      formik.values.type
                        ? helper.setToOptionModel(formik.values.type, formik.values.type)
                        : null
                    }
                    onChange={(opt) => {
                      formik.setFieldValue('type', opt ? opt.label : '');
                    }}
                  />
                </ContentBlock>
              </Section>

              {/* INVOICE SECTION */}
              <ContentBlock className="mt-20">
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <CustomBlock className="w-100">
                      <ContentHeader
                        title="Invoice"
                        headerSize="mdlg"
                        containerClassName="pt-10 pb-10"
                        className="fw-700 primary--clr"
                        primaryButtonText="Download Invoice"
                        iconLeft={<TbFileInvoice size={35} className="secondary--clr" />}
                        primaryButtonOnClick={() => handleDownloadInvoice()}
                      />
                    </CustomBlock>
                  </AccordionSummary>

                  <AccordionDetails>
                    <CustomBlock className="w-100">
                      <Section>
                        <ContentBlock>
                          <Table
                            striped
                            bordered
                            hover
                          >
                            <thead>
                              <tr>
                                <th>Label</th>
                                <th>Sum Of Gross</th>
                                <th>Sum Of Incentive Amount</th>
                                <th>Sum Of Net Amount</th>
                                <th>BM %</th>
                                <th>Sum Of Old Savings</th>
                                <th>Sum Of Variance</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                invoiceData.sort((a, b) => a.name.localeCompare(b.name)).map((data) => (
                                  <tr>
                                    <td>{data.name !== 'null' ? data.name : ' - '}</td>
                                    <td>{`$${parseFloat(data.gross || 0)?.toFixed(2)}`}</td>
                                    <td>{`$${parseFloat(data.sumOfIncentiveAmount || 0)?.toFixed(2)}`}</td>
                                    <td>{`$${parseFloat(data.netChargeAmount || 0)?.toFixed(2)}`}</td>
                                    <td>
                                      {`${(data.bmPercentage || 0).toFixed(2)}%`}
                                    </td>
                                    <td>{data.sumOfOldSavings}</td>
                                    <td>{data.sumOfVariance}</td>
                                  </tr>
                                ))
                              }
                            </tbody>
                          </Table>
                        </ContentBlock>
                      </Section>
                    </CustomBlock>
                  </AccordionDetails>
                </Accordion>
              </ContentBlock>

              {/* PARCEL ANALYSIS */}
              {bidItems?.length > 0 && (
                <ContentBlock className="mt-20">
                  <Accordion defaultExpanded>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <CustomBlock className="w-100">
                        <ContentHeader
                          title="Analysis Summary"
                          headerSize="mdlg"
                          containerClassName="pt-10 pb-10"
                          className="fw-700 primary--clr"
                          iconLeft={<TbChartInfographic size={35} className="secondary--clr" />}
                          primaryButtonText={bidItems.length > 0 ? 'View Report' : ''}
                          primaryButtonOnClick={() => {
                            history.push('/bid/report', { uniqueServiceTypes, bid, type: formik.values.type });
                          }}
                        />
                      </CustomBlock>
                    </AccordionSummary>

                    <AccordionDetails>
                      <CustomBlock className="w-100">
                        <Section>
                          <ContentBlock>
                            {
                              bid.type !== 'UPS' ? (
                                // FED EX Table
                                <ContentBlock>
                                  <Table
                                    striped
                                    bordered
                                    hover
                                  >
                                    <thead>
                                      <tr>
                                        <th>Service Type</th>
                                        <th>Occurance</th>
                                        <th>Rated Weight</th>
                                        <th>Transportation Charge Amount</th>
                                        <th>Net Charge Amount</th>
                                        <th>Discount Amount</th>
                                        <th>Discount %</th>
                                        <th>Total Accessorial Charges</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {
                                        uniqueServiceTypes.map((data) => (
                                          <tr>
                                            <td>{data.name !== 'null' ? data.name : ' - '}</td>
                                            <td>{data.count}</td>
                                            <td>{`${data.ratedWeightAmount}`}</td>
                                            <td>{`$${data.totalCharge}`}</td>
                                            <td>{`$${data.netChargeAmount}`}</td>
                                            <td>{`$${(Math.abs(data.totalDiscountAmount)).toFixed(2)}`}</td>
                                            <td>{`${(Math.abs(data.totalDiscountAmount) / Math.abs(data.totalCharge)).toFixed(3)}% (${((Math.abs(data.totalDiscountAmount) / Math.abs(data.totalCharge)).toFixed(3) * 100).toFixed(1)}%)`}</td>
                                            <td>{`$${(data.netChargeAmount - (data.totalCharge - Math.abs(data.totalDiscountAmount))).toFixed(2)}`}</td>
                                          </tr>
                                        ))
                                      }
                                      <tr>
                                        <th>Total</th>
                                        <th>{' '}</th>
                                        <th>
                                          {bid.totalRatedWeight}
                                        </th>
                                        <th>
                                          $
                                          {bid.totalTransportationCharge}
                                        </th>
                                        <th>
                                          $
                                          {bid.totalNetCharge}
                                        </th>
                                        <th>
                                          <p>
                                            $
                                            {Math.abs(uniqueServiceTypes.map((x) => x.totalDiscountAmount).reduce((a, b) => parseFloat(a) + parseFloat(b), 0).toFixed(2))}
                                          </p>
                                        </th>
                                        <th> </th>
                                        <th> </th>
                                      </tr>
                                    </tbody>
                                  </Table>
                                </ContentBlock>
                              ) : (
                                <>
                                  {/* UPS Transportation Charge Table */}
                                  <ContentBlock>
                                    <Table
                                      striped
                                      bordered
                                      hover
                                    >
                                      <thead>
                                        <tr>
                                          <th>Transportation Charge</th>
                                          <th>Occurance</th>
                                          <th>Rated Weight</th>
                                          <th>Net Charge Amount</th>
                                          <th>Discount Amount</th>
                                          <th>Discount %</th>
                                          <th>Gross Amount</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {
                                          uniqueServiceTypes.filter((x) => (x.ratedWeightAmount > 0)).map((data) => (
                                            <tr>
                                              <td>{data.name !== 'null' ? data.name : ' - '}</td>
                                              <td>{data.count}</td>
                                              <td>{`${data.ratedWeightAmount}`}</td>
                                              <td>{`$${data.netChargeAmount}`}</td>
                                              <td>{`$${(Math.abs(data.totalDiscountAmount)).toFixed(2)}`}</td>
                                              <td>
                                                {`${parseFloat(data.divisor).toFixed(2)}%`}
                                              </td>
                                              <td>
                                                {`$${(data.netChargeAmount - (data.totalCharge - Math.abs(data.totalDiscountAmount))).toFixed(2)}`}
                                              </td>
                                            </tr>
                                          ))
                                        }
                                        <tr>
                                          <th>Total</th>
                                          <th>{' '}</th>
                                          <th>
                                            {bid.totalRatedWeight}
                                          </th>
                                          <th>
                                            $
                                            {
                                              uniqueServiceTypes.filter((x) => x.ratedWeightAmount > 0).map((x) => parseFloat(x.netChargeAmount).toFixed(2)).reduce((a, b) => parseFloat(a) + parseFloat(b), 0).toFixed(2)
                                            }
                                          </th>
                                          <th>
                                            $
                                            {
                                              uniqueServiceTypes.filter((x) => x.ratedWeightAmount > 0).map((x) => Math.abs(parseFloat(x.totalDiscountAmount)).toFixed(2)).reduce((a, b) => parseFloat(a) + parseFloat(b), 0).toFixed(2)
                                            }
                                          </th>
                                          <th> </th>
                                          <th> </th>
                                        </tr>
                                      </tbody>
                                    </Table>
                                  </ContentBlock>

                                  {/* Accessorial Charge Table */}
                                  <ContentBlock className="mt-40">
                                    <Table
                                      striped
                                      bordered
                                      hover
                                    >
                                      <thead>
                                        <tr>
                                          <th>Accessorial Charge</th>
                                          <th>Occurance</th>
                                          <th>Net Charge Amount</th>
                                          <th>Discount Amount</th>
                                          <th>Discount %</th>
                                          <th>Gross Amount</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {
                                          uniqueServiceTypes.filter((x) => (x.ratedWeightAmount <= 0)).map((data) => (
                                            <tr>
                                              <td>{data.name !== 'null' ? data.name : ' - '}</td>
                                              <td>{data.count}</td>
                                              <td>{`$${data.netChargeAmount}`}</td>
                                              <td>{`$${(Math.abs(data.totalDiscountAmount)).toFixed(2)}`}</td>
                                              <td>
                                                {`${parseFloat(data.divisor).toFixed(2)}%`}
                                              </td>
                                              <td>
                                                {`$${(data.netChargeAmount - (data.totalCharge - Math.abs(data.totalDiscountAmount))).toFixed(2)}`}
                                              </td>
                                            </tr>
                                          ))
                                        }
                                        <tr>
                                          <th>Total</th>
                                          <th>{' '}</th>
                                          <th>
                                            $
                                            {
                                              uniqueServiceTypes.filter((x) => x.ratedWeightAmount <= 0).map((x) => parseFloat(x.netChargeAmount)).reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
                                                ?.toFixed(2)
                                            }
                                          </th>
                                          <th>
                                            $
                                            {
                                              uniqueServiceTypes.filter((x) => x.ratedWeightAmount <= 0).map((x) => Math.abs(parseFloat(x.totalDiscountAmount))).reduce((a, b) => parseFloat(a) + parseFloat(b), 0)?.toFixed(2)
                                            }
                                          </th>
                                          <th> </th>
                                          <th> </th>
                                        </tr>
                                      </tbody>
                                    </Table>
                                  </ContentBlock>
                                </>
                              )
                            }
                          </ContentBlock>
                        </Section>
                      </CustomBlock>
                    </AccordionDetails>
                  </Accordion>
                </ContentBlock>
              )}

              {/* PARCEL BREAKDOWN */}
              {bidId > 0 && (
                <ContentBlock className="mt-20">
                  <Accordion defaultExpanded>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <CustomBlock className="w-100">
                        <ContentHeader
                          title="Parcel Breakdown"
                          headerSize="mdlg"
                          containerClassName="pt-10 pb-10"
                          className="fw-700 primary--clr"
                          iconLeft={<TbStack2 size={35} className="secondary--clr" />}
                          primaryButtonText={bidItems.length > 0 ? '' : 'Add Analysis'}
                          primaryButtonOnClick={() => setIsModalOpen(true)}
                        />
                      </CustomBlock>
                    </AccordionSummary>

                    <AccordionDetails>
                      <CustomBlock className="w-100">
                        <Section>
                          <ContentBlock>
                            <MUIDataTable
                              columns={bidItemColumns}
                              data={bidItems}
                              options={{
                                selectableRows: 'none',
                                download: true,
                                print: false,
                                jumpToPage: true,
                                pagination: true,
                                rowsPerPageOptions: [5, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 500],
                                rowsPerPage: 50,
                              }}
                            />
                          </ContentBlock>
                        </Section>
                      </CustomBlock>
                    </AccordionDetails>
                  </Accordion>
                </ContentBlock>
              )}
            </FormBlock>
          </ContentBlock>
        </Section>
      </CustomBlock>

      <FixedActionsBar
        primaryActionText={bidId > 0 ? 'Save Changes' : 'Create Analysis'}
        primaryActionColor="primary--bg"
        primaryActionOnClick={formik.handleSubmit}
        secondaryActionText="Cancel"
        secondaryActionTo="/admin/management/parcel-shipping-analysis"
        optionalActionText={bidId > 0 ? 'Return to Listing' : ''}
        optionalActionTo="/admin/management/parcel-shipping-analysis"
      />

      <ModalBlock
        fullscreen
        size="xl"
        hasCloseAction
        centered
        isVisible={isModalOpen}
        contentHeader="Parcel Shipping Analysis"
        primaryModalActionText=""
        secondaryModalActionText=""
        onHide={handleModalClose}
      >
        <Section hasNoContainer>
          <Wizard>
            <Step1FileUploadWizard
              excelFileUpload={excelFileUpload}
              setExcelFileUpload={setExcelFileUpload}
            />
            <Step2LineItemListingWizard
              excelFileUpload={excelFileUpload}
              bidItemColumns={bidItemColumnsReactTable}
              type={bid.type}
              handleModalClose={handleModalClose}
              setIsLoading={setIsLoading}
              bidId={bid.id}
              showAlert={showAlert}
              isLoading={isLoading}
              fetchBid={fetchBid}
            />
          </Wizard>
        </Section>
      </ModalBlock>
    </>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(
  mapStateFromProps,
  {
    ...auth.actions,
    ...alert.actions
  }
)(BidManagementPage);