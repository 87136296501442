/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
// DEPENDENCIES
import React from 'react';
import { Fade } from 'react-awesome-reveal';
// COMPONENTS
import CustomBlock from '../../../../../components/CustomBlock';
import Image from '../../../../../components/Image';
// ASSETS
import BackgroundDecorationImage from '../../../../../assets/client/background-decoration_dark-light-waves.svg';
import CarrierContractNegotiation from '../../../../../assets/client/illustration_carrier-contract-negotiation.svg';
import ShippingOptimization from '../../../../../assets/client/illustration_shipping-optimization.svg';
import CarrierBidManagement from '../../../../../assets/client/illustration_carrier-bid-management.svg';

const servicesOverview = [
  {
    id: 1,
    title: 'Rate Negotiation',
    illustration: CarrierContractNegotiation,
    description: 'Unlock cost-saving shipping rates with our expert negotiators. We use deep industry knowledge to secure deals that cut costs and boost your savings.',
  },
  {
    id: 2,
    title: 'Streamlined Shipping',
    illustration: ShippingOptimization,
    description: 'Our customized shipping strategies enhance your operational efficiency, ensuring reliable delivery without overspending.',
  },
  {
    id: 3,
    title: 'Contract Monitoring',
    illustration: CarrierBidManagement,
    description: 'We keep a watchful eye on your shipping contracts, ensuring full compliance and persistent savings.',
  },
  // {
  //   title: 'Carrier Selection',
  //   illustration: CarrierSelection,
  //   description: '',
  // },
  // {
  //   title: 'Carrier Compliance and Monitoring',
  //   illustration: CarrierCompliance,
  //   description: '',
  // },
];

const ServicesIntroSection = (props) => {
  const { history } = props;
  
  return (
    <CustomBlock className="position-relative w-100">
      <img
        src={BackgroundDecorationImage}
        style={{
          position: 'absolute',
          top: '-4vw',
          left: '0',
          zIndex: '-1',
          width: 'clamp(120vw, 160%, 100vw)',
          overflow: 'hidden',
        }}
        alt=""
      />

      <CustomBlock className="content-section mb-30 mx-auto">
        <Fade>
          <CustomBlock className="mult-intro">
            <h3 className="size-xl primary--clr fw-700">How We Help You</h3>
            <p className="size-md mid-grey--clr pb-30">
              Experience the benefits of smarter shipping. Our approach focuses on finding real savings and making your shipping process smoother and more cost-effective.
            </p>

            <CustomBlock className="listing-block mt-30">
              {servicesOverview.map((item) => (
                <CustomBlock
                  key={item.id}
                  className="item p-4"
                  onClick={() => history.push('/transport-logics-services')}
                >
                  <CustomBlock className="svg-container mb-30">
                    <img src={item.illustration} alt={`${item.title} Illustration`} />
                  </CustomBlock>
                  <p className="header size-mdlg primary--clr fw-600 pb-10">{item.title}</p>
                  <p className="description mid-grey--clr size-sm">{item.description}</p>
                </CustomBlock>
              ))}
            </CustomBlock>
          </CustomBlock>
        </Fade>
      </CustomBlock>
    </CustomBlock>
  );
};

export default ServicesIntroSection;