/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-multi-spaces */
/* eslint-disable react/no-array-index-key */
// DEPENDENCIES
import React, { useState } from 'react';
// COMPONENTS
import { Route } from 'react-router-dom';
// CUSTOM COMPONENTS;
import Overlay from '../components/Overlay';
import CustomBlock from '../components/CustomBlock';
import HeaderNavigation from '../components/Headers/HeaderNavigation';
import FooterBlock from '../components/FooterBlock';
// ASSETS
import LogoDefault from '../assets/client/logo.svg';
import LogoLight from '../assets/client/logo-alt-2.svg';

const CMSRoute = (cmsRouteProps) => {
  const {
    auth,
    component: Component,
    cmsInfo, dynamicPageId,
    isBlog,
    ...rest
  } = cmsRouteProps;

  const companyLogos = {
    default: LogoDefault,
    light: LogoLight,
    dark: '',
  };

  const { companyLogoPath, companyIconPath } = cmsInfo;
  const [isLoading] = useState(false);

  return (
    <Route
      {...rest} render={(props) => (
        <>
          {isLoading && <Overlay hasLoader />}
          <CustomBlock className="page-wrapper lightest-grey--sbg">
            <HeaderNavigation
            // hasSearchBlock
              isSubmenuFullWidth
              cmsInfo={cmsInfo}
              icon={companyIconPath}
              logo={companyLogoPath}
              companyLogos={companyLogos}
              menuItems={cmsInfo.menuItems}
              hasSubmenu
              destination="/page"
              iconAppearance="circle"
              menuItemsBlockBackgroundColor="primary--sbg"
              menuItemColor="primary--clr"
              menuToggleIconColor="primary--clr"
              primarySocialMediaLink={cmsInfo.facebookLink}
              phoneNumber={cmsInfo.phoneNumber}
              emailAddress={cmsInfo.emailAddress}
              secondarySocialMediaLink={cmsInfo.instagramLink}
              stores={cmsInfo.stores}
              categories={cmsInfo.categories}
              featuredSections={cmsInfo.featuredSections}
              {...props}
            />

            <Component
              {...props}
              dynamicPageId={dynamicPageId}
              cmsInfo={cmsInfo}
              isBlog={isBlog}
            />

            <FooterBlock
            // hasEmailSignUp
              to="/"
              cmsInfo={cmsInfo}
              imageSource={companyLogoPath}
              companyName={cmsInfo.companyName}
              phoneNumber={cmsInfo.phoneNumber}
              emailAddress={cmsInfo.emailAddress}
              companyLogos={companyLogos}
              backgroundColor="primary--sbg"
              fixedNavigationBarBackgroundColor="primary--sbg"
              footerHeaderTextColor="white--clr"
              copyrightTextColor="white--clr"
              bottomFooterBackgroundColor="primary-lighter--sbg"
              iconAppearance="circle"
              socialIconBackgroundColor="primary-lighter--sbg"
              socialIconColor="white--clr"
              footerItems={cmsInfo.footerItems}
              appStoreLink={cmsInfo.appStoreLink}
              googlePlayLink={cmsInfo.googlePlayStoreLink}
              facebookLink={cmsInfo.facebookLink}
              instagramLink={cmsInfo.instagramLink}
              twitterLink={cmsInfo.twitterLink}
              linkedInLink={cmsInfo.linkedInLink}
              youtubeLink={cmsInfo.youtubeLink}
              tiktokLink={cmsInfo.tikTokLink}
              featuredSections={cmsInfo.featuredSections}
              {...props}
            />
          </CustomBlock>
        </>
      )}
    />
  );
};

export default CMSRoute;