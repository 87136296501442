/* eslint-disable max-len */
// DEPENDENCIES
import React from 'react';
import { Fade } from 'react-awesome-reveal';
// COMPONENTS
import CustomBlock from '../../../../../components/CustomBlock';
import Button from '../../../../../components/Button';
// ASSETS
import BackgroundTexture from '../../../../../assets/client/background-texture-1.svg';

const AboutIntroSection = () => (
  <CustomBlock className="content-section align-items-center mb-30">
    <Fade>
      <CustomBlock
        className="xintro-to-about roundest"
        style={{
          padding: '1.8rem',
          backgroundImage: `url(${BackgroundTexture})`,
        }}
      >
        <p className="size-xl fw-700 white--clr mb-20">More Than Parcel Consultants</p>
        <p className="white--clr content-section__description size-md fw-300">
          At Transport Logics, we&apos;re more than just consultants;
          {' '}
          <span className="fw-600">we&apos;re industry pioneers dedicated to optimizing your parcel shipping experience</span>
          .
        </p>
        <br />
        <p className="white--clr content-section__description size-md fw-300 pb-30">
          With over three decades of specialized insight,
          <span className="fw-600"> we empower your business to navigate the complexities of carrier contracts and logistics with ease</span>
          . Our strategic analysis and customized solutions ensure your shipping processes are not just efficient but also cost-effective.
        </p>
        <Fade direction="right">
          <Button
            text="Elevate Your Shipping Strategy"
            to="/about-parcel-consulting"
            className="secondary--bg circle px-4"
          />
        </Fade>
      </CustomBlock>
    </Fade>
  </CustomBlock>
);

export default AboutIntroSection;