/* eslint-disable max-len */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/style-prop-object */
// DEPENDENCIES
import React, { useState, useEffect } from 'react';
// COMPONENTS
import Slider from 'react-slick';
// CUSTOM COMPONENTS
import { TbPalette } from 'react-icons/tb';
import CustomBannerInsert from './CustomBannerInsert';
import CustomBlock from '../../../../components/CustomBlock';
import Overlay from '../../../../components/Overlay';
import Banner from '../../../../components/Banner';
import Image from '../../../../components/Image';
// SECTIONS
import WebsiteIntroSection from './sections/WebsiteIntroSection';
import AboutIntroSection from './sections/AboutIntroSection';
import ServicesIntroSection from './sections/ServicesIntroSection';
// ASSETS - APP LINKS
// import AppStoreBadge from '../../../assets/img/app-store-badge.svg';
// import GooglePlayBadge from '../../../assets/img/google-play-badge.svg';
import BackgroundDecorationImage from '../../../../assets/client/background-decoration_dark-light-waves.svg';
// HELPERS & SERVICES
import constants from '../../../../constants/constants';

const HomePage = (props) => {
  const { dynamicPageModel, buttonAction, history } = props;
  const [isLoading] = useState(false);
  const [banners, setBanners] = useState([]);
  const [offset, setOffset] = useState(0);
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  useEffect(() => {
    const rotatingBanners = dynamicPageModel.pageContentSectionBanners.find((x) => x.bannerId > 0);
    if (rotatingBanners) {
      setBanners(rotatingBanners.banner.bannerItems);
    }
  }, []);

  useEffect(() => {
    const handleScroll = () => setOffset(window.scrollY);

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const bannerSliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,

    responsive: [
      {
        breakpoint: 480,
        settings: { dots: false }
      }
    ]
  };

  return (
    <>
      {(isLoading) && <Overlay hasLoader />}
      <CustomBlock>
        {/* BANNER SECTION */}
        <CustomBlock className="banner-container">
          <Slider {...bannerSliderSettings}>
            {/* CUSTOM BANNER INSERT */}
            <CustomBannerInsert
              history={history}
              offsetValue={offset}
            />
            {banners.map((banner) => (
              <Banner
                isDynamicBanner
                isTitleUppercase
                isReversed={banner.alignment === constants.CONTENT_SECTION.RightAlignment}
                isCentered={banner.alignment === constants.CONTENT_SECTION.CenterAlignment}
                title={banner.title}
                imageSource={`${process.env.REACT_APP_API_URL}Attachments${banner.imagePath}`}
                description={banner.description}
                primaryActionText={banner.primaryButtonText}
                primaryActionOnClick={() => {
                  buttonAction(banner.primaryButtonActionType, banner.primaryButtonLink);
                }}
                secondaryActionText={banner.secondaryButtonText}
                secondaryActionOnClick={() => {
                  buttonAction(banner.secondaryButtonActionType, banner.secondaryButtonLink);
                }}
                offset={offset}
              />
            ))}
          </Slider>
        </CustomBlock>

        <CustomBlock className="main-content mt-120">
          {/* WEBSITE INTRO */}
          <WebsiteIntroSection />

          {/* INTRO TO ABOUT */}
          <AboutIntroSection />

          {/* INTROS TO SERVICES */}
          <ServicesIntroSection
            history={history}
          />
        </CustomBlock>

      </CustomBlock>
    </>
  );
};

export default HomePage;