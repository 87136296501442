/* eslint-disable max-len */
/* eslint-disable jsx-quotes */
/* eslint-disable quotes */
/* eslint-disable no-unused-vars */
// DEPENDENCIES
import React, { useState, useEffect } from 'react';
// COMPONENTS
import {
  Document,
  Page,
  StyleSheet,
  Text,
  View,
  PDFViewer,
  Image,
} from '@react-pdf/renderer';
// LOGO
import Logo from '../../../../assets/client/logo.png';

const columnHeaders = [
  'Service Type',
  'Occurrence',
  'Rated Weight',
  'Transportation Charge Amount',
  'Net Charge Amount',
  'Discount Amount',
  'Discount %',
  'Total Accessorial Charges',
];

const upsFirstTableColumnHeaders = [
  'Transportation Charge',
  'Occurance',
  'Rated Weight',
  'Net Charge Amount',
  'Discount Amount',
  'Discount %',
  'Gross Amount'
];

const upsSecondTableColumnHeaders = [
  'Accessorial Charge',
  'Occurance',
  'Net Charge Amount',
  'Discount Amount',
  'Discount %',
  'Gross Amount'
];

const headerStyles = StyleSheet.create({
  header: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 10,
  },
  logo: {
    width: 200,
    height: 'auto',
    marginBottom: 5,
    marginRight: 740
  },
  companyName: {
    fontSize: 24,
    marginRight: 100,
    marginBottom: 10,
    marginTop: -50,
    fontWeight: 'bolder',
  },
});

const Header = () => (
  <View style={headerStyles.header}>
    <Image src={`${Logo}`} style={headerStyles.logo} />
    <View>
      <Text style={headerStyles.companyName}>Transport Logics</Text>
      <Text style={headerStyles.reportText}>Parcel Shipping Analysis Report</Text>
    </View>
  </View>
);

const BidReportPage = (props) => {
  const { history } = props;
  // console.log(history.location.state);
  const { uniqueServiceTypes, bid, type } = history.location.state;

  const styles = StyleSheet.create({
    page: {
      flexDirection: 'row',
      backgroundColor: '#E4E4E4',
      padding: 10,
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1,
    },

    reportText: {
      fontSize: 16,
      marginLeft: 5,
      fontWeight: 800,
    },
    table: {
      marginTop: 15,
      display: 'table',
      width: type === 'UPS' ? 1100 : 975,
      borderStyle: 'solid',
      borderWidth: 'auto',
      borderLeft: 1,
      borderTop: 1,
      borderTopWidth: 0,
      borderRightWidth: 0,
      borderBottomWidth: 0,
    },
    tableRow: { flexDirection: 'row' },
    tableCell: {
      width: '10%', // 7 columns, each taking 14.28% width
      height: 60,
      borderStyle: 'solid',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 1,
      // padding: 5,
      textAlign: 'center', // Center align text in all cells
      fontWeight: 'bold', // Bold font for header cells
    },
    columnHeader: {
      backgroundColor: '#0162a8', // Background color of column headers
      color: '#FFFFFF', // Text color of column headers
      textDecoration: 'none',
    },
    cellText: { fontSize: 12 }, // Smaller font size
  });

  return (
    <PDFViewer
      style={{
        height: '80vh',
        width: '95vw',
      }}
    >
      <Document>
        <Page
          // size="A4"
          style={styles.page}
          orientation="landscape"
        >
          <View
            wrap={false}
            style={styles.section}
          >
            {/* HEADER */}
            <Header />
            {/* TABLE */}
            {
              type !== 'UPS' ? (
                <View style={styles.table}>
                  <View style={styles.tableRow}>
                    {columnHeaders.map((header, index) => (
                      <View
                        style={[styles.tableCell, styles.columnHeader]}
                      >
                        <Text style={styles.cellText}>{header}</Text>
                      </View>
                    ))}
                  </View>
                  {
                    uniqueServiceTypes.map((data) => (
                      <View style={styles.tableRow}>
                        <View style={styles.tableCell}>
                          <Text style={styles.cellText}>{data.name !== 'null' ? data.name : ' - '}</Text>
                        </View>
                        <View style={styles.tableCell}>
                          <Text style={styles.cellText}>{data.count}</Text>
                        </View>
                        <View style={styles.tableCell}>
                          <Text style={styles.cellText}>{data.ratedWeightAmount}</Text>
                        </View>
                        <View style={styles.tableCell}>
                          <Text style={styles.cellText}>{`$${data.totalCharge}`}</Text>
                        </View>
                        <View style={styles.tableCell}>
                          <Text style={styles.cellText}>{`$${data.netChargeAmount}`}</Text>
                        </View>
                        <View style={styles.tableCell}>
                          <Text style={styles.cellText}>{`$${(Math.abs(data.totalDiscountAmount)).toFixed(2)}`}</Text>
                        </View>
                        <View style={styles.tableCell}>
                          <Text style={styles.cellText}>{`${(Math.abs(data.totalDiscountAmount) / Math.abs(data.totalCharge)).toFixed(3)}% (${((Math.abs(data.totalDiscountAmount) / Math.abs(data.totalCharge)).toFixed(3) * 100).toFixed(1)}%)`}</Text>
                        </View>
                        <View style={styles.tableCell}>
                          <Text style={styles.cellText}>{`$${(data.netChargeAmount - (data.totalCharge - Math.abs(data.totalDiscountAmount))).toFixed(2)}`}</Text>
                        </View>
                      </View>
                    ))
                  }

                  <View style={styles.tableRow}>
                    <View style={styles.tableCell}>
                      <Text style={styles.cellText}>Total</Text>
                    </View>
                    <View style={styles.tableCell} />
                    <View style={styles.tableCell}>
                      <Text style={styles.cellText}>
                        {bid.totalRatedWeight}
                      </Text>
                    </View>
                    <View style={styles.tableCell}>
                      <Text style={styles.cellText}>
                        $
                        {bid.totalTransportationCharge}
                      </Text>
                    </View>
                    <View style={styles.tableCell}>
                      <Text style={styles.cellText}>
                        $
                        {bid.totalNetCharge}
                      </Text>
                    </View>
                    <View style={styles.tableCell}>
                      <Text style={styles.cellText}>
                        $
                        {(bid.totalTransportationCharge - bid.totalNetCharge).toFixed(2)}
                      </Text>
                    </View>
                    <View style={styles.tableCell} />
                    <View style={styles.tableCell} />
                  </View>
                </View>
              ) : (
                <>
                  <View style={styles.table}>
                    <View style={styles.tableRow}>
                      {upsFirstTableColumnHeaders.map((header, index) => (
                        <View
                          style={[styles.tableCell, styles.columnHeader]}
                        >
                          <Text style={styles.cellText}>{header}</Text>
                        </View>
                      ))}
                    </View>
                    {
                      uniqueServiceTypes.filter((x) => (x.ratedWeightAmount > 0)).map((data) => (
                        <View style={styles.tableRow}>
                          <View style={styles.tableCell}>
                            <Text style={styles.cellText}>{data.name !== 'null' ? data.name : ' - '}</Text>
                          </View>
                          <View style={styles.tableCell}>
                            <Text style={styles.cellText}>{data.count}</Text>
                          </View>
                          <View style={styles.tableCell}>
                            <Text style={styles.cellText}>{data.ratedWeightAmount}</Text>
                          </View>
                          <View style={styles.tableCell}>
                            <Text style={styles.cellText}>{`$${data.netChargeAmount}`}</Text>
                          </View>
                          <View style={styles.tableCell}>
                            <Text style={styles.cellText}>{`$${(Math.abs(data.totalDiscountAmount)).toFixed(2)}`}</Text>
                          </View>
                          <View style={styles.tableCell}>
                            <Text style={styles.cellText}>{`${parseFloat(data.divisor).toFixed(2)}%`}</Text>
                          </View>
                          <View style={styles.tableCell}>
                            <Text style={styles.cellText}>{`$${(data.netChargeAmount - (data.totalCharge - Math.abs(data.totalDiscountAmount))).toFixed(2)}`}</Text>
                          </View>
                        </View>
                      ))
                    }
                    <View style={styles.tableRow}>
                      <View style={styles.tableCell}>
                        <Text style={styles.cellText}>Total</Text>
                      </View>
                      <View style={styles.tableCell} />
                      <View style={styles.tableCell}>
                        <Text style={styles.cellText}>
                          {bid.totalRatedWeight}
                        </Text>
                      </View>
                      <View style={styles.tableCell}>
                        <Text style={styles.cellText}>
                          $
                          {
                            uniqueServiceTypes.filter((x) => x.ratedWeightAmount > 0).map((x) => parseFloat(x.netChargeAmount).toFixed(2)).reduce((a, b) => parseFloat(a) + parseFloat(b), 0).toFixed(2)
                          }
                        </Text>
                      </View>
                      <View style={styles.tableCell}>
                        <Text style={styles.cellText}>
                          $
                          {
                            uniqueServiceTypes.filter((x) => x.ratedWeightAmount > 0).map((x) => Math.abs(parseFloat(x.totalDiscountAmount)).toFixed(2)).reduce((a, b) => parseFloat(a) + parseFloat(b), 0).toFixed(2)
                          }
                        </Text>
                      </View>
                      <View style={styles.tableCell} />
                      <View style={styles.tableCell} />
                    </View>
                  </View>

                  <View style={{ marginTop: 25 }}>
                    <View style={styles.table}>
                      <View style={styles.tableRow}>
                        {upsSecondTableColumnHeaders.map((header, index) => (
                          <View
                            style={[styles.tableCell, styles.columnHeader]}
                          >
                            <Text style={styles.cellText}>{header}</Text>
                          </View>
                        ))}
                      </View>
                      {
                        uniqueServiceTypes.filter((x) => (x.ratedWeightAmount <= 0)).map((data) => (
                          <View style={styles.tableRow}>
                            <View style={styles.tableCell}>
                              <Text style={styles.cellText}>{data.name !== 'null' ? data.name : ' - '}</Text>
                            </View>
                            <View style={styles.tableCell}>
                              <Text style={styles.cellText}>{data.count}</Text>
                            </View>
                            <View style={styles.tableCell}>
                              <Text style={styles.cellText}>{`$${data.netChargeAmount}`}</Text>
                            </View>
                            <View style={styles.tableCell}>
                              <Text style={styles.cellText}>{`$${(Math.abs(data.totalDiscountAmount)).toFixed(2)}`}</Text>
                            </View>
                            <View style={styles.tableCell}>
                              <Text style={styles.cellText}>{`${parseFloat(data.divisor).toFixed(2)}%`}</Text>
                            </View>
                            <View style={styles.tableCell}>
                              <Text style={styles.cellText}>{`$${(data.netChargeAmount - (data.totalCharge - Math.abs(data.totalDiscountAmount))).toFixed(2)}`}</Text>
                            </View>
                          </View>
                        ))
                      }
                      <View style={styles.tableRow}>
                        <View style={styles.tableCell}>
                          <Text style={styles.cellText}>Total</Text>
                        </View>
                        <View style={styles.tableCell} />
                        <View style={styles.tableCell}>
                          <Text style={styles.cellText}>
                            $
                            {
                              uniqueServiceTypes.filter((x) => x.ratedWeightAmount <= 0).map((x) => parseFloat(x.netChargeAmount)).reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
                            }
                          </Text>
                        </View>
                        <View style={styles.tableCell}>
                          <Text style={styles.cellText}>
                            $
                            {
                              uniqueServiceTypes.filter((x) => x.ratedWeightAmount <= 0).map((x) => Math.abs(parseFloat(x.totalDiscountAmount))).reduce((a, b) => parseFloat(a) + parseFloat(b), 0)?.toFixed(2)
                            }
                          </Text>
                        </View>
                        <View style={styles.tableCell} />
                        <View style={styles.tableCell} />
                      </View>
                    </View>
                  </View>
                </>
              )
            }

          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
};

export default BidReportPage;