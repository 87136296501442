/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
/* eslint-disable no-use-before-define */
// DEPENDENCIES
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useFormik } from 'formik';
// COMPONENTS
import MUIDataTable from 'mui-datatables';
import {
  DragDropContext,
  Droppable,
  Draggable
} from 'react-beautiful-dnd';
// ICONS
// CUSTOM COMPONENTS
import Overlay from '../../../../components/Overlay';
import Section from '../../../../components/Section';
import ContentBlock from '../../../../components/ContentBlock';
import CustomBlock from '../../../../components/CustomBlock';
import ContentHeader from '../../../../components/ContentHeader';
import ModalBlock from '../../../../components/ModalBlock';
import Button from '../../../../components/Button';
import FormBlock from '../../../../components/FormBlock';
import InputBlock from '../../../../components/InputBlock';
// SERVICES AND HELPERS
import * as footerItemService from '../../../../services/cms/footerItemService';
import * as helper from '../../../../helpers/helper';
import FooterItemValidator from '../../../../helpers/validators/cms/footerItem/FooterItemValidator';
// REDUX
import * as alert from '../../../../redux/alertToastRedux';
import * as confirmModal from '../../../../redux/confirmModalRedux';

const initialFooterItemModel = {
  id: 0,
  label: '',
  to: '',
};

const FooterItemListingPage = (props) => {
  const { showAlert, showConfirmModal, hideConfirmModal } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [isSortModalOpen, setIsSortModalOpen] = useState(false);
  const [footerItems, setFooterItems] = useState([]);
  const [sortList, setSortList] = useState([]);
  const [footerItemModel, setFooterItemModel] = useState(initialFooterItemModel);
  const [isModalVisible, setIsModalVisible] = useState(false);
  // const iconSize = 22;
  // const iconColor = 'white--clr';

  useEffect(() => {
    getFooterItems();
  }, []);

  useEffect(() => {
    setSortList(footerItems.sort((a, b) => a - b));
  }, [footerItems]);

  const formik = useFormik({
    initialValues: footerItemModel,
    validationSchema: FooterItemValidator,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      if (values.id === 0) {
        createFooterItem(values);
      } else {
        updateFooterItem(values);
      }
    },
  });

  const createFooterItem = (model) => {
    setIsLoading(true);
    footerItemService.createFooterItem(model).then((res) => {
      showAlert({ text: res.message, state: 'success' });
      getFooterItems();
      closeFooterItemModal();
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const updateFooterItem = (model) => {
    setIsLoading(true);

    footerItemService.updateFooterItem(model).then((res) => {
      showAlert({ text: res.message, state: 'success' });
      getFooterItems();
      closeFooterItemModal();
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const getFooterItem = (id) => {
    setIsLoading(true);
    footerItemService.getFooterItemById(id).then((res) => {
      setFooterItemModel(res);
      setIsModalVisible(true);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const getFooterItems = () => {
    setIsLoading(true);
    footerItemService.getAllFooterItems().then((res) => {
      setFooterItems(res);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const closeFooterItemModal = () => {
    setIsModalVisible(false);
    setTimeout(() => {
      formik.resetForm();
      setFooterItemModel(initialFooterItemModel);
    }, 250);
  };

  const deleteFooterItem = (footerItemId) => {
    setIsLoading(true);

    footerItemService.deleteFooterItem(footerItemId).then((res) => {
      getFooterItems();
      showAlert({ text: res.message, state: 'success' });
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
      hideConfirmModal();
    });
  };

  const sortFooterItems = () => {
    setIsLoading(true);

    footerItemService.sortFooterItems(sortList).then((res) => {
      getFooterItems();
      showAlert({ text: res.message, state: 'success' });
      setIsSortModalOpen(false);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const tempArr = helper.reOrderDnd(
      sortList,
      result.source.index,
      result.destination.index
    );

    setSortList(tempArr);
  };

  const columnOptions = {
    filter: true,
    sort: true,
    print: false,
    download: true
  };

  const options = {
    selectableRows: 'none',
    download: true,
    print: false,
    jumpToPage: true,
    textLabels: { pagination: { jumpToPage: 'Page No:' } }
  };

  const columns = [
    {
      name: 'id',
      label: 'Actions',
      options: {
        filter: false,
        sort: false,
        print: false,
        download: false,
        customBodyRenderLite: (dataIndex) => (
          <CustomBlock className="content-container--actions flex-start pl-0 mt-0">
            <Button
              text="View"
              className="primary--bg mr-5"
              size="xxs"
              onClick={() => getFooterItem(footerItems[dataIndex].id)}
            />

            <Button
              text="Delete"
              className="danger--bg"
              size="xxs"
              onClick={() => {
                showConfirmModal({
                  title: 'Remove Footer Item',
                  text: 'Are you sure you want to remove this menu item?',
                  rightBtnText: 'Confirm',
                  btnAction: () => {
                    deleteFooterItem(footerItems[dataIndex].id);
                  }
                });
              }}
            />
          </CustomBlock>
        )
      }
    },
    {
      name: 'label',
      label: 'Label',
      options: columnOptions,
    },
    {
      name: 'to',
      label: 'Link To',
      options: columnOptions
    },
  ];

  return (
    <>
      {isLoading && <Overlay hasLoader />}
      <CustomBlock className="content-container--padded">
        <Section isFullWidth>
          <ContentBlock>
            <CustomBlock className="content-container--card-style--with-shadow">
              <ContentHeader
                title="Footer Items"
                headerSize="lg"
                primaryButtonText="New Footer Item"
                primaryButtonOnClick={() => setIsModalVisible(true)}
                secondaryButtonText="Sort Items"
                secondaryButtonOnClick={() => setIsSortModalOpen(true)}
              />

              <CustomBlock>
                <MUIDataTable
                  data={footerItems}
                  columns={columns}
                  options={options}
                />
              </CustomBlock>
            </CustomBlock>
          </ContentBlock>
        </Section>
      </CustomBlock>

      {/* SORT MODAL */}
      <ModalBlock
        hasCloseAction
        isVisible={isSortModalOpen}
        size="md"
        contentHeader="Sort Sections"
        primaryModalActionText="Sort"
        primaryModalActionOnClick={sortFooterItems}
        onHide={() => {
          setIsSortModalOpen(false);
        }}
      >
        <Section hasNoContainer>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(droppableProvided) => (
                <div
                  ref={droppableProvided.innerRef}
                >
                  {sortList.map((item, index) => (
                    <Draggable
                      key={item.id} draggableId={`${index}`}
                      index={index}
                    >
                      {(draggableProvided) => (
                        <div
                          key={`item_${index}`}
                          ref={draggableProvided.innerRef}
                          {...draggableProvided.draggableProps}
                          {...draggableProvided.dragHandleProps}
                          className="content-container--card-style light-grey--sbg pt-15 pb-15 pl-20 pr-20 mb-15"
                        >
                          <p className="main-font fw-500">{item.label}</p>
                        </div>

                      )}
                    </Draggable>
                  ))}
                  {droppableProvided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Section>
      </ModalBlock>

      {/* VIEW/UPDATE MODAL */}
      <ModalBlock
        hasCloseAction
        isVisible={isModalVisible}
        size="md"
        contentHeader={footerItemModel.id === 0 ? 'New Footer Item' : 'Update Details'}
        primaryModalActionText={footerItemModel.id === 0 ? 'Add' : 'Update'}
        primaryModalActionOnClick={formik.submitForm}
        onHide={closeFooterItemModal}
      >
        <FormBlock onSubmit={formik.submitForm}>
          <Section hasNoContainer>
            <ContentBlock>
              <InputBlock
                label="Footer Item Label"
                errorMessage={formik.errors.label}
                inputState={`${helper.getInputClasses(formik, 'label')}`}
                {...formik.getFieldProps('label')}
                isRequired
              />
            </ContentBlock>

            <ContentBlock>
              <InputBlock
                label="Footer Link"
                errorMessage={formik.errors.to}
                inputState={`${helper.getInputClasses(formik, 'to')}`}
                {...formik.getFieldProps('to')}
                isRequired
              />
            </ContentBlock>
          </Section>
        </FormBlock>
      </ModalBlock>
    </>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });
export default connect(mapStateFromProps, { ...alert.actions, ...confirmModal.actions })(FooterItemListingPage);